import React, { Component, Fragment } from "react";
import {
  Card,
  Table,
  Avatar,
  notification,
  Tag,
  Row,
  Divider
} from "antd";
import "./singleInvoice.css";
import appConfig from "../../helpers/appConfig";
import moment from "moment";
import Modal from "react-modal";
import Axios from "axios";
import {Button} from 'react-bootstrap'
const { Meta } = Card;

const bid = localStorage.getItem("bid");
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "rgb(7, 24, 44)",
    // padding: "20px", // Adds spacing inside the modal
    // borderRadius: "8px", // Smoothens corners
    // boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.5)", // Adds a subtle shadow for better focus
    width: "400px", // Sets a fixed width
    // height: "300px", // Sets a fixed height
  },
};



const columns = [
  {
    title: "Sr Nr.",
    dataIndex: "no"
  },
  {
    title: "Navn",
    dataIndex: "name"
  },
  {
    title: "QTY",
    dataIndex: "quantity"
  },
  {
    title: "Pris",
    dataIndex: "price"
  },
  {
    title: "Ekstra vare Pris",
    dataIndex: "extraPrice"
  },
  {
    title: "Beløp",
    dataIndex: "amount"
  }
];

const paymentStyle = {
  marginTop: 5,
  marginBottom: 5
};

const gridStyle = {
  flex: "1 0 50%",
  maxWidth: "50%",
  padding: "10px",
  boxShadow: "none",
  border: "none"
};

const fullWidth = {
  flex: "1 0 100%",
  boxShadow: "none",
  border: "none"
};

class SingleInvoice extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: false,
      onlineOrder: false,
      modalIsOpen: false,
      customerEmail: "",
      customerPhone: "",
      invoiceNumber: this.props.data.invoiceNumber, // Example invoice number
      branchId: bid, // Example branch ID
      totalPrice: this.props.data.totalPrice, // Example total price

    };
    //console.log("brbrbr", this.props.branch);
    this.orderType = "";
    console.log("view report bill data",this.props.data);
    
  }
 componentDidMount(){
    if (
      this.props.data.bank == 0 &&
      this.props.data.cash == 0 &&
      this.props.data.coupon == 0 &&
      this.props.data.isPaid == true &&
      this.props.data.isTableOnline == true
    ) {
      this.setState({ onlineOrder: true });
      this.orderType = "Online Order";
    console.log(this.state.onlineOrder);
    } else if (
      this.props.data.bank == 0 &&
      this.props.data.cash == 0 &&
      this.props.data.coupon == 0 &&
      this.props.data.isPaid == true &&
      this.props.data.isTableOnline == false
    ) {
      this.setState({ onlineOrder: true });
      this.orderType = "Table";
    } else if (
      this.props.data.bank == 0 &&
      this.props.data.cash == 0 &&
      this.props.data.coupon == 0 &&
      this.props.data.isPaid == false
    ) {
      this.orderType = "Paid By Faktura";
  }else{
  this.orderType="";
  }
 }
  markPaid = (id) => {
    const hdr = localStorage.token;
    fetch(`${appConfig.endpoint}/invoice/${id}`, {
      method: "PUT",
      headers: {
        "x-auth-token": hdr
      }
    })
      .then((res) => res.json())
      .then(
        (result) => {
          if (result.status === "failure") {
            this.openNotification("error", result.message);
          } else {
            this.openNotification("success", result.message);
          }
        },
        (error) => {
          console.log(error);
          this.setState({
            error
          });
        }
      );
  };

  openNotification = (type, message) => {
    notification[type]({
      message: message
    });
  };
  printbill = () => {
    window.print();
  };


  // Open modal
  openModal = () => {
    this.setState({ modalIsOpen: true });
  };

  // Close modal
  closeModal = () => {
    this.setState({ modalIsOpen: false });
  };

  // Handle input changes
  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSubmit = async () => {
    const { invoiceNumber, branchId, totalPrice, customerEmail, customerPhone } = this.state;
  
    // Check if customerEmail is empty
    if (!customerEmail) {
      notification.error({
        message: "Error",
        description: "Customer Email cannot be empty!",
      });
      return; // Stop the submission
    }
  
    // Check if customerEmail is in valid email format
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(customerEmail)) {
      notification.error({
        message: "Error",
        description: "Please enter a valid email address!",
      });
      return; // Stop the submission
    }
  
    const branchInfoIs = JSON.parse(localStorage.getItem("branchInfo"));
    const bEmailIs = branchInfoIs.bEmail;

    console.log("bEmailIs",bEmailIs)
    // Prepare payload
    const payload = {
      invoiceNumber,
      branchId,
      totalPrice,
      customerEmail,
      customerPhone,
      userEmail: bEmailIs ? bEmailIs : "",
    };
  
    console.log("API payload:", payload);
  
    try {
      // Uncomment to call the API
      const response = await Axios.post(`${appConfig.endpoint}/api/v1/employee/invoice/refundOrder`, payload);
      console.log("response", response)
  
      if (response.status === 200) {
        notification.success({
          message: "Success",
          description: "Refund processed successfully!",
        });
      } else if (response.status === 400) {
        notification.warning({
          message: "Warning",
          description: "Refund has already been processed for this order.",
        });
      } else {
        notification.error({
          message: "Error",
          description: "Refund has already been processed for this order.",
        });
      }
      console.log("API Response:", response.data);
    } catch (error) {
      console.error("Error processing refund:", error);
      notification.error({
        message: "Error",
        description: "Failed to process the refund.",
      });
    } finally {
      this.closeModal(); // Close modal after API call
    }
  };

  // handleSubmit = async () => {
  //   const { invoiceNumber, branchId, totalPrice, customerEmail, customerPhone } = this.state;
  
  //   // Check if customerEmail is empty
  //   if (!customerEmail) {
  //     alert("Customer Email cannot be empty!");
  //     return; // Stop the submission
  //   }
  
  //   // Check if customerEmail is in valid email format
  //   const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  //   if (!emailRegex.test(customerEmail)) {
  //     alert("Please enter a valid email address!");
  //     return; // Stop the submission
  //   }
  
    
  //   // Prepare payload
  //   const payload = {
  //     invoiceNumber,
  //     branchId,
  //     totalPrice,
  //     customerEmail,
  //     customerPhone,
  //     userEmail:bEmailIs ? bEmailIs : ""
  //   };
  
  //   console.log("API payload:", payload);
  
  //   try {
  //     // Uncomment to call the API
  //     const response = await Axios.post(`${appConfig.endpoint}/api/v1/employee/invoice/refundOrder`, payload);
  //     if(response.status == 200){
  //       console.log("done")
  //       // alert("Refund success of this order.");
  //       // this.openNotification("success", response.message);
  //     }else if(response.status == 400){
  //       alert("Refund has already been processed for this order.")
  //       // this.openNotification("error", response.message);
  //     }
  //     else{
  //       alert("Failed to process the refund.");
  //       // this.openNotification("error", response.message);
  //     }

  //     console.log("API Response:", response.data);
  //   } catch (error) {
  //     console.error("Error processing refund:", error);
  //     alert("Failed to process the refund.");
  //   } finally {
  //     this.closeModal(); // Close modal after API call
  //   }
  // };
  
  render() {
    const { modalIsOpen, customerEmail, customerPhone } = this.state;
    const branchInfo = JSON.parse(localStorage.getItem("branchInfo"));
    const branchLogo = localStorage.getItem("logo");
    const { data } = this.props;
    const { customerId, isPaid, _id } = this.props.data;
    const { name, address, taxNo, phoneNo, bEmail, accountNumber } = branchInfo;
    const table = data.items;
    let discounTd = data.tdDiscountAbs
      ? data.tdDiscountAbs
      : data.tdDiscountPickUpAbs
      ? data.tdDiscountPickUpAbs
      : 0;
   let tdTotal;
    let tableData = table.map((item, index) => {
      let ext = item.extraPrice ? item.extraPrice : 0;
     
      return {
        no: index + 1,
        name: item.productName,
        quantity: item.quantity,
        price:`Kr.${item.productPrice}`,
        extraPrice: `Kr.${ext}`,
        amount: `Kr. ${Number(item.quantity * item.productPrice + ext).toFixed(
          2
        )}`
      };
    });
    console.log("tdTotal======>",tdTotal);
    return (
      <div
        id="singleinvoice"
        style={{
          margin: "10px auto",
          borderWidth: 1,
          borderColor: "green"
        }}
        className="single-invoice"
      >
        <Card className="p-3">
          <Row>
            <div
              id="printbtn"
              className="d-flex align-items-center justify-content-between"
            >
              <Meta
                avatar={
                  <Avatar
                    src={
                      branchLogo === null
                        ? ""
                        : `${appConfig.s3url}/${branchLogo}`
                    }
                  />
                }
                title={name}
              />

            <Button
              onClick={this.printbill}
              style={{
                background: "transparent",
                color: "black",
                border: "1px solid grey",
                float: "right",
                marginLeft: "120px"
              }}
            >
              Skrive ut
            </Button>
              
              
          </div>
          </Row>
          <Divider />
          <Row style={{ width: "100%", display: "flex", flexWrap: "wrap" }}>
            <Card.Grid style={gridStyle}>
              {address && (
                <>
                  <p>
                    {address.line1 ? address.lin1 : ""}
                    {address.line2 ? " " + address.line2 : ""}
                  </p>
                  <p>
                    {address.city ? address.city : ""}
                    {address.pin ? "," + " " + address.pin : ""}
                    {address.state ? "," + " " + address.state : ""}
                  </p>
                  <p>
                    <b>Mva Nr.</b>: {taxNo}
                  </p>
                  {!isPaid ? (
                    <p>
                      <b>Regnskap Nr. </b>: {accountNumber}
                    </p>
                  ) : null}
                  <p>
                    <b>Telefon Nr.</b>: {phoneNo}
                  </p>
                  <p>
                    <b>E-post</b>: {bEmail}
                  </p>
                </>
              )}
            </Card.Grid>
            <Card.Grid style={{ ...gridStyle, textAlign: "right" }}>
              <p>
                <b>Faktura Nr.</b>: {data.invoiceNumber}
              </p>
              <p>
                <b>Dato</b>:{" "}
                {data.createdDate
                  ? moment(data.createdDate).format("DD/MM/YYYY")
                  : moment(new Date(data.created).format("DD/MM/YYYY"))}
              </p>
              <p>
                <b>Tid</b>:{" "}
                {data.createdTime
                  ? data.createdTime
                  : new Date(data.created).toLocaleTimeString()}
              </p>
              <p>
                <b>Bestillingstype</b>: {data.orderType}
              </p>
              {data.type !== "Refund" && bid === "5fb556a2949ee4ab9dcbc94a" && (
  <>
    <Button className="buttonOfReport" onClick={this.openModal}>
REFUSJONSORDRE
    </Button>

    <Modal
      isOpen={modalIsOpen}
      onRequestClose={this.closeModal}
      contentLabel="Refund Order"
      style={customStyles}
    >
      <div className="button-container">
        <button onClick={this.closeModal} className="close-button">
          X
        </button>
      </div>
      <h3 className="headingOfReport">REFUSJONSORDRE</h3>
      <Divider />
      <form>
        <div>
          <label className="labelOfReport">
          Kunde-e-post:
            <input
              type="email"
              name="customerEmail"
              value={customerEmail}
              onChange={this.handleChange}
              required
              className="inputNew2"
            />
          </label>
        </div>
        <div>
          <label className="labelOfReport">
          Kundetelefon:
            <input
              type="tel"
              name="customerPhone"
              value={customerPhone}
              onChange={this.handleChange}
              required
              className="inputNew2"
            />
          </label>
        </div>
        <div style={{ float: "right", margin: "20px" }}>
          <Button className="buttonOfReport" onClick={this.handleSubmit}>
          TILBAKEBETALING
          </Button>
        </div>
      </form>
    </Modal>
  </>
)}

            
            </Card.Grid>
            <hr />
            {console.log(
              "this.props.data.isAppOrder======>",
              this.props.data.isAppOrder
            )}

            {
              data.type == "Refund" ? 
              <Card.Grid
                style={{
                  ...gridStyle,
                  flex: "auto",
                  marginTop: 15,
                  textAlign: "left"
                }}
              >
                 <h4 style={{ marginLeft: 5, fontWeight: "bold", color: "grey" }}>Refusjonsdetaljer :</h4>
                <p>
                  <b>Customer Email:</b> &nbsp;
                  {data.customerEmail ? data.customerEmail : ""}
                </p>
                <p>
                  <b>Customer Phone:</b> &nbsp;
                  {data.customerPhone ? data.customerPhone : ""}
                </p>
                <p>
                  <b>Cancelled By :</b> &nbsp;
                  {data.userEmail ? data.userEmail : ""}
                </p>
              </Card.Grid>
             
              :""
            }
            {customerId ? (
              <Card.Grid style={gridStyle}>
                <h3>Kundedetaljer :</h3>
                <hr />
                <p>
                  <b>Name:</b> &nbsp;{customerId.firstName}{" "}
                  {customerId.lastName}
                </p>

                <p>
                  {" "}
                  <b>Address:</b> &nbsp;{customerId.address.line1} ,{" "}
                  {customerId.address.line2}
                </p>

                {customerId.address.line1 ? (
                  <>
                  <p>
                      <b>City:</b> &nbsp;{customerId.address.city}{" "}
                    </p>
                    <p>
                      <b>State:</b> &nbsp;
                    {customerId.address.state
                      ? "," + customerId.address.state
                      : ""}
                  </p>
                    <p>
                      <b>PIN:</b> &nbsp;
                      {customerId.address.pin
                        ? "," + customerId.address.pin
                        : ""}{" "}
                    </p>
                  </>
                ) : (
                  ""
                )}
              </Card.Grid>
            ) : !data.isAppOrder && data.type !="Refund" ? (
              <h4 style={{ marginLeft: 10, fontWeight: "bold", color: "grey" }}>
                No Customer Details
              </h4>
            ): data.isAppOrder && data.type !="Refund" ? (
              <h4 style={{ marginLeft: 10, fontWeight: "bold", color: "grey" }}>
              EzyGo Order
              </h4>
            ):""}

          
            
            {customerId? (
              <Card.Grid
                style={{
                  ...gridStyle,
                  flex: "auto",
                  marginTop: 15,
                  textAlign: "right"
                }}
              >
                <p>
                  <b>Telefon:</b> {customerId.phoneNo}
                </p>
                <p>
                  <b>e-post:</b> {customerId.email}
                </p>
                <p>
                  <b>Referanse:</b> {data.reference}
                </p>
              </Card.Grid>
            ) : (
              ""
            )}
            <hr />
            <Table
              bordered
              id="producttab"
              style={{ ...fullWidth, marginTop: 20 }}
              columns={columns}
              dataSource={tableData}
              size="middle"
              pagination={false}
            />
            <Card.Grid
              style={{
                ...fullWidth,
                textAlign: "right",
                paddingRight: "0 40px",
                lineHeight: 1.65
              }}
            >
              <p>
                <span style={{ fontWeight: 700 }}>Sub-Totalt: </span>
                <span style={{ minWidth: "100px", display: "inline-block" }}>
                  {console.log("this.props.data =======>",this.props.data)}
                  {/* {`Kr. ${(
                    data.taxPrice -
                    (
                      data.taxPrice -
                      (100 * data.taxPrice) / (100 + data.tax)
                    ).toFixed(3)
                  ).toFixed(2)}`} */}
                  {`Kr. ${
                    data.isAppOrder
                      ? ((data.taxPriceInc - discounTd) / 1.15).toFixed(2)
                      : (
                    data.taxPrice -
                    (
                      data.taxPrice -
                      (100 * data.taxPrice) / (100 + data.tax)
                    ).toFixed(3)
                        ).toFixed(2)
                  }`}
                </span>
              </p>
              <p>
                <span style={{ fontWeight: 700 }}>
                  Mva(
                  {`${data.tax.toFixed(2)}%`}
                  ):{" "}
                </span>
                <span style={{ minWidth: "100px", display: "inline-block" }}>
                  {`Kr. ${
                    data.isAppOrder
                      ? (
                          data.taxPriceInc -
                          discounTd -
                          ((data.taxPriceInc - discounTd) / 1.15).toFixed(2)
                        ).toFixed(2)
                      : (
                    data.taxPrice -
                    (100 * data.taxPrice) / (100 + data.tax)
                        ).toFixed(2)
                  }`}
                  {/* {`Kr. ${(
                    data.taxPrice -
                    (100 * data.taxPrice) / (100 + data.tax)
                  ).toFixed(2)}`} */}
                </span>
              </p>
              {data.tdDiscountPickUp ? (
                <>
                  <p>
                    <span style={{ fontWeight: 700 }}>
                      Rabatt ({`${data.tdDiscountPickUp}%`}) :
                    </span>
                    <span
                      style={{ minWidth: "100px", display: "inline-block" }}
                    >
                      {`- Kr. ${Number(
                        data.taxPrice * (data.tdDiscountPickUp / 100)
                      ).toFixed(2)}`}
                    </span>
                  </p>
                  {/* <p>
                    <span style={{ fontWeight: 700 }}>Service Charges Tax</span>
                    <span
                      style={{ minWidth: "100px", display: "inline-block" }}
                    >
                      {(data.orcFeesFromRestaurant - (data.orcFeesFromRestaurant
                        ? data.orcFeesFromRestaurant / 1.25
                        : 0)).toFixed(2)}
                    </span>
                  </p>
                  <p>
                    <span style={{ fontWeight: 700 }}>Service Charges</span>
                    <span
                      style={{ minWidth: "100px", display: "inline-block" }}
                    >
                      {(data.orcFeesFromRestaurant
                        ? data.orcFeesFromRestaurant / 1.25
                        : 0).toFixed(2)}
                    </span>
                  </p> */}
                </>
              ) : (
                <>
                  <p>
                <span style={{ fontWeight: 700 }}>
                      {/* Rabatt (
                  {data.discountType === "percent" ? `${data.discount}%` : "0%"}
                  ) : */}
                  Rabatt (
                      {data.isAppOrder
                        ? `${data.tdDiscount}%`
                        : `${data.discount}`}
                  ) :
                </span>
                    <span
                      style={{ minWidth: "100px", display: "inline-block" }}
                    >
                      {/* {`- Kr. ${(
                    (100 * Number(data.taxPrice)) / (100 - data.discount) -
                    data.taxPrice
                  ).toFixed(2)}`} */}
                      {`- Kr. ${
                        data.isAppOrder
                          ? `${data.tdDiscountAbs}`
                          : `${
                              (100 * Number(data.taxPrice)) /
                                (100 - data.discount) -
                    data.taxPrice
                            }`
                      }`}
                    </span>
                  </p>
                  {/* {data.isAppOrder ? (
                   <>
                   <p>
                      <span style={{ fontWeight: 700 }}>Service Charges Tax :</span>
                      <span
                        style={{ minWidth: "100px", display: "inline-block" }}
                      >
                        {`Kr. ${(data.tdServiceCharge - (data.tdServiceCharge / 1.25)).toFixed(2) }`}
                      </span>
                    </p>
                    <p>
                      <span style={{ fontWeight: 700 }}>Service Charges :</span>
                      <span
                        style={{ minWidth: "100px", display: "inline-block" }}
                      >
                        {`Kr. ${(data.tdServiceCharge / 1.25).toFixed(2)}`}
                      </span>
                    </p>
                  </>
                  ) : (
                    <></>
                  )} */}
                </>
              )}
              {data.flatDiscount && data.flatDiscount !== 0 ? (
                <p>
                  <span style={{ fontWeight: 700 }}>
                    Flat Rabatt (kr.{data.flatDiscount}) :
                  </span>
                  <span style={{ minWidth: "100px", display: "inline-block" }}>
                    {`-
                    Kr. ${Number(data.flatDiscount).toFixed(3)}`}
                  </span>
                </p>
              ) : null}
             

              {data.orderType == "Drive Out" ? (
                
                <p>
                  <p>
                    {" "}
                    {/* <span style={{ fontWeight: 700 }}>Drive Out Tax (25%)</span> */}
                    <span style={{ fontWeight: 700 }}>Drive Out Tax</span>
                    <span
                      style={{ minWidth: "100px", display: "inline-block" }}
                    >
                      {data.isAppOrder ? (
                        <>+ Kr. {( data.tdDriveOutCharge - (data.tdDriveOutCharge / 1.25)).toFixed(2)}</>
                      ) : (
                        <>
                          + Kr.{" "}
                          {(
                            (data.driveoutCharge + (data.extraDriveOut ? data.extraDriveOut : 0)) - 
                            ( (100 * (data.driveoutCharge + data.extraDriveOut)) / 125)  
                          ).toFixed(2)}
                        </>
                      )}
                    </span>
                  </p>
                  <p>
                    {" "}
                    <span style={{ fontWeight: 700 }}>Drive Out Charges</span>
                    <span
                      style={{ minWidth: "100px", display: "inline-block" }}
                    >
                      {data.isAppOrder ? (
                        <>+ Kr. {(data.tdDriveOutCharge / 1.25).toFixed(2)}</>
                      ) : (
                        <>
                          + Kr.{" "}
                          {(
                        (100 * (data.driveoutCharge + data.extraDriveOut)) /
                        125
                          ).toFixed(2)}
                        </>
                      )}
                    </span>
                  </p>
                  <span style={{ fontWeight: 700 }}>Totalt:</span>
                  <span style={{ minWidth: "100px", display: "inline-block" }}>
                    {`Kr.${parseFloat(data.totalPrice).toFixed(2)}`}
                  </span>
                </p>
              ) : (
                <span>
                  <span style={{ fontWeight: 700 }}>Totalt:</span>
                  <span style={{ minWidth: "100px", display: "inline-block" }}>
                    {/* {`Kr. ${parseFloat(data.taxPrice).toFixed(2)}`} */}
                    {Number(data.totalPrice).toFixed(2)}
                  </span>
                </span>
              )}

              <br />
              {!this.state.onlineOrder ? (
                <>
                  <span style={paymentStyle}>
                <span style={{ fontWeight: 700 }}>CASH:</span>

                    <span
                      style={{ minWidth: "100px", display: "inline-block" }}
                    >
                  <Tag
                    color={data.cash !== 0 ? "#87d068" : "grey"}
                  >{`Kr.${parseFloat(data.cash).toFixed(2)}`}</Tag>
                </span>
              </span>
              <br />
              <span style={paymentStyle}>
                <span style={{ fontWeight: 700 }}>BANK:</span>
                    <span
                      style={{ minWidth: "100px", display: "inline-block" }}
                    >
                  <Tag
                    color={data.bank !== 0 ? "#87d068" : "grey"}
                  >{`Kr.${parseFloat(data.bank).toFixed(2)}`}</Tag>
                </span>
                  </span>
                 
                </>
                
              ) : (
                ""
                // <strong>{this.orderType}</strong>
              )}
              {
                    data.type == "Refund" ? 
                    <h4 className="pt-2">
                    <span style={{ color:"red", fontWeight: 700 }}>Refund Amount:</span>
                  <span style={{ color:"red",minWidth: "100px", display: "inline-block" }}>
                    {`Kr.${parseFloat(data.refundAmt).toFixed(2)}`}
                  </span>
                    </h4>: ""
                  }
              <br />
              {data.coupon>0 && (
                <span style={paymentStyle}>
                  <span style={{ fontWeight: 700 }}>COUPON:</span>
                  <span style={{ minWidth: "100px", display: "inline-block" }}>
                    <Tag
                      color={data.coupon !== 0 ? "#87d068" : "grey"}
                    >{`Kr.${parseFloat(data.coupon).toFixed(2)}`}</Tag>
                  </span>
                </span>
              )}
              <br />
              <span>
                {data.transaction && data.transaction.vendorName=="Vipps"? (
                  <>
                    <br />
                    <h4>
                      <b>Paid With Vipps</b>
                    </h4>
                    
                  </>
                ) : (
                  data.transaction.card && (
                  <>
                    <br />
                    <h4>
                      <b>Card Details</b>
                    </h4>
                    <p>
                      <span style={{ fontWeight: 700 }}>Card:</span>
                      <span
                        style={{ minWidth: "100px", display: "inline-block" }}
                      >
                        {data.transaction.card.masked_pan}
                      </span>
                      <br />
                      <span style={{ fontWeight: 700 }}>Card Type:</span>
                      <span
                        style={{ minWidth: "100px", display: "inline-block" }}
                      >
                        {data.transaction.card.brand}
                      </span>
                      <br />
                      <span style={{ fontWeight: 700 }}>Expiry Date:</span>
                      <span
                        style={{ minWidth: "100px", display: "inline-block" }}
                      >
                        {data.transaction.card.expiry_date}
                      </span>
                        <br />
                        {data.transaction.card.approvedCode?  <>
                          <span style={{ fontWeight: 700 }}>Approved code : </span>
                        <span
                          style={{ minWidth: "100px", display: "inline-block" }}
                        >
                          {data.transaction.card.approvedCode?data.transaction.card.approvedCode:""}
                        </span>
                        </>:""}
                    </p>
                  </>
                  )
                )}
              </span>
              <span>
                {isPaid ? (
                  ""
                ) : (
                  <Button
                    id="markpaidbtn"
                    style={{ margin: "20px 0" ,background: "green"}}
                    type="danger"
                    onClick={() => this.markPaid(_id)}
                  >
                    Ubetalt
                  </Button>
                )}
                {/* <Button
                  id="markrevertbtn"
                  style={{ margin: "20px 10px" }}
                  type="danger"
                  title="REVERT INVOICE"
                  onClick={() => this.markPaid(_id)}
                /> */}
              </span>
              <br />
              <span></span>
            </Card.Grid>
          </Row>
        </Card>
      </div>
    );
  }
}

export default SingleInvoice;
