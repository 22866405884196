import React, { useEffect, useState } from "react";
import { Input, Form, Button, Select, Card, Row, Col } from "antd";
import { addCategory } from "./../../action/menuActions";
import { connect } from "react-redux";
import IconsModel from "./IconsModel";
import "./style.css";
const Option = Select.Option;
const FormItem = Form.Item;
const CategoryForm = (props) => {
  const { superCategory } = props;
  let [name, setName] = useState("");
  let [superCat, setSuperCat] = useState("");
  let [taxType, setTaxType] = useState([]);
  let [taxes, setTaxes] = useState([]);
  let [nCatId, setNCatId] = useState("");
  let [isModel, setIsModel] = useState(false);
  // const supercathandleChange = (value) => {
  //   const cat =
  //     superCategory.length > 0 && superCategory.find((ele) => value == ele._id);
  //   const tTax = cat && cat.taxType ? cat.taxType : [];
  //   let taxId = (tTax.length > 0 && tTax.map((o) => o.tax)) || [];
  //   const sTaxes = JSON.parse(localStorage.getItem("taxes"));
  //   let isTax = [];
  //   if (taxId.length > 0) {
  //     isTax = sTaxes.map((tax) => {
  //       if (taxId.includes(tax._id)) {
  //         return { key: tax._id, val: tax.taxName };
  //       }
  //     });
  //   }
  //   setSuperCat(value);
  //   setTaxType(isTax);
  // };
  const supercathandleChange = (value) => {
    const cat =
      superCategory.length > 0 && superCategory.find((ele) => value == ele._id);
    const tTax = cat && cat.taxType ? cat.taxType : [];
    console.log(tTax, "tTax");
    let taxId = (tTax.length > 0 && tTax.map((o) => o.tax)) || [];
    console.log(taxId, "taxId");

    const sTaxes = JSON.parse(localStorage.getItem("taxes"));
    console.log(sTaxes, "sTaxes");
    let isTax = [];
    if (taxId.length > 0) {
      sTaxes.forEach((tax) => {
        if (taxId.includes(tax._id)) {
          isTax.push({ key: tax._id, val: tax.taxName });
        }
      });
    }
    console.log(isTax, "istaxxx");
    setSuperCat(value);
    setTaxType(isTax);
  };
  const errorsObj = {
    name: {
      show: false,
      message: "Produkt Navn is required"
    },
    cat: {
      show: false,
      message: "Velg G. Kode is required"
    },
    taxes: {
      show: false,
      message: "Velg Type is required"
    }
  };
  let [errors, setErrors] = useState(errorsObj);
  const handleSubmit = (e) => {
    e.preventDefault();
    let errorsObj = { ...errors };
    let flag = false;
    if (!name) {
      errorsObj.name.show = true;
      flag = true;
    }
    if (!superCat) {
      errorsObj.cat.show = true;
      flag = true;
    }
    if (!taxes.length) {
      errorsObj.taxes.show = true;
      flag = true;
    }
    flag ? setErrors(errorsObj) : saveCategory({ name, taxes, superCat });
  };
  const saveCategory = (data) => {
    props.addCategory(data);
  };
  useEffect(() => {
    if (
      props.successData &&
      Object.keys(props.successData).length !== 0 &&
      props.successData.actionType === "addCategory"
    ) {
      setName("");
      setSuperCat("");
      setTaxes([]);
      setNCatId(props.successData._id);
      props.getIcons().then(() => setIsModel(true));
    }
  }, [props.successData]);
  const createForm = () => {
    return (
      <>
        <Card bordered={false} style={{ marginBottom: "1rem" }}>
          {/* <Form layout="inline" onSubmit={(e) => handleSubmit(e)}>
            <Row className="catForm" gutter={{ xs: 8, sm: 16, md: 24 }}>
              <Col className="gutter-row" span={4}>
                <FormItem style={{ width: "100%" }}>
                  <Input
                    style={{ width: "100%" }}
                    onChange={(e) => setName(e.target.value)}
                    placeholder="Produkt Navn"
                    value={name}
                  />
                  <p style={{ color: "red" }}>
                    {errors.name.show ? errors.name.message : ""}
                  </p>
                </FormItem>
              </Col>
              <Col className="gutter-row" span={4}>
                <FormItem style={{ width: "100%" }}>
                  <Select
                    style={{ width: "100%" }}
                    placeholder="Velg G. Kode"
                    onChange={(e) => supercathandleChange(e)}
                    required={true}
                    value={superCat}
                  >
                    {superCategory.length > 0 &&
                      superCategory.map((data, index) => {
                        return (
                          <Option key={data._id} value={data._id}>
                            {data.name}
                          </Option>
                        );
                      })}
                  </Select>
                  <p style={{ color: "red" }}>
                    {errors.cat.show ? errors.cat.message : ""}
                  </p>
                </FormItem>
              </Col>
              <Col className="gutter-row" span={4}>
                <FormItem style={{ width: "100%" }}>
                  <Select
                    mode="tags"
                    style={{ width: "100%" }}
                    placeholder="Velg Type"
                    onChange={(e) => setTaxes(e)}
                    required={true}
                    value={taxes}
                  >
                    {taxType.map((itm) => {
                      return (
                        <Option value={itm.key} key={itm.key}>
                          {itm.val}
                        </Option>
                      );
                    })}
                  </Select>
                  <p style={{ color: "red" }}>
                    {errors.taxes.show ? errors.taxes.message : ""}
                  </p>
                </FormItem>
              </Col>
              <Col className="gutter-row" span={2}>
                <FormItem>
                  <Button
                    className="btn-ab"
                    style={{ width: 114, padding: 0 }}
                    htmlType="submit"
                  >
                    Oprett ny Produkt
                  </Button>
                </FormItem>
              </Col>
            </Row>
          </Form> */}
          <Form layout="inline" onSubmit={(e) => handleSubmit(e)}>
            <div className="catForm d-flex justify-content-between" gutter={{ xs: 8, sm: 16, md: 24 }}>
                <FormItem style={{ width: "100%" }}>
                  <Input
                    style={{ width: "100%" }}
                    onChange={(e) => setName(e.target.value)}
                    placeholder="Produkt Navn"
                    value={name}
                  />
                  <p style={{ color: "red" }}>
                    {errors.name.show ? errors.name.message : ""}
                  </p>
                </FormItem>
                <FormItem style={{ width: "100%" }}>
                  <Select
                    style={{ width: "100%" }}
                    placeholder="Velg G. Kode"
                    onChange={(e) => supercathandleChange(e)}
                    required={true}
                    value={superCat}
                  >
                    {superCategory.length > 0 &&
                      superCategory.map((data, index) => {
                        return (
                          <Option key={data._id} value={data._id}>
                            {data.name}
                          </Option>
                        );
                      })}
                  </Select>
                  <p style={{ color: "red" }}>
                    {errors.cat.show ? errors.cat.message : ""}
                  </p>
                </FormItem>
                <FormItem style={{ width: "100%" }}>
                  <Select
                    mode="tags"
                    style={{ width: "100%" }}
                    placeholder="Velg Type"
                    onChange={(e) => setTaxes(e)}
                    required={true}
                    value={taxes}
                  >
                    {taxType.map((itm) => {
                      return (
                        <Option value={itm.key} key={itm.key}>
                          {itm.val=="PLU1"?"Tax 25":"Tax 15"}
                        </Option>
                      );
                    })}
                  </Select>
                  <p style={{ color: "red" }}>
                    {errors.taxes.show ? errors.taxes.message : ""}
                  </p>
                </FormItem>
              <FormItem style={{ margin: "0" }}>
                  <Button
                    className="btn-ab"
                    style={{ width: 160, padding: 0 }}
                    htmlType="submit"
                  >
                    Oprett ny Produkt
                  </Button>
                </FormItem>
            </div>
          </Form>
        </Card>
        <IconsModel
          isModel={isModel}
          setIsModel={setIsModel}
          icons={props.icons}
          catId={nCatId}
          updateIcon={props.updateIcon}
          getCategory={props.getCategory}
        />
      </>
    );
  };
  return createForm();
};
const mapStateToProps = ({ successMassage, icons }) => {
  return {
    successData: successMassage.data,
    icons
  };
};
export default connect(mapStateToProps, {
  addCategory
})(CategoryForm);
