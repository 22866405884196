export default {
  publicURL: "https://web.a-board.world/restaurant",
  // publicURL: "http://web.a-board.tech/restaurant",
  // publicURL: "http://localhost:3006/restaurant",//Localhost
  s3url: "https://pos-stockholm-bucket.s3.eu-north-1.amazonaws.com",
 endpoint: "https://api.a-board.world" // main server/
  // endpoint: "https://api.a-board.tech" //locla server/,
  
  // endpoint: "http://localhost:8080"
};
