import { GET_GROUP_REPORT } from "../../action/types";
const initialState = {
  driveOutCharges: {},
  dueUnpaid: [],
  onlineTotal: 0,
  report: [],
  tips: [],
  totalBank: 0,
  totalBoxOpen: 0,
  totalCash: 0,
  onlineVippsTotal:0,
  totalCredit: 0,
  totalTip: 0,
  unPaid: [],
  couponData:{},
  reserveTableData:{},
  treeDriveVippsTotal:0,
  OrcTotal:0,
  totalRefund:0,
  refundData:[]
};
const groupReportReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_GROUP_REPORT:
      return action.payload.data;
    default:
      return state;
  }
};
export default groupReportReducer;
