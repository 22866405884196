import React, { useEffect, useState } from "react";

import { NavLink } from "react-router-dom";
import { Card, Row, Col } from "react-bootstrap";

import Home from "../Home";
import LeggTilFiler from "./../Arkiv/LeggTilFiler";

import "./style.css";
const Arkiv = (props) => {

  let [isModalVisible, setIsModalVisible] = useState(false);
  const handleModalVisible = (state) => {
    setIsModalVisible(state);
  };
  
  return (
    <Home>
      <div className="report-container text-center">
        <span className="item ">Arkiv</span>
      </div>
      <Card>
        <Card.Body>
          <Row className="m-b-1">
            <Col className=" text-center">
              <NavLink to="/arkiv/all-faktura" className="btn btn-link" 
                    style={{ width: "120px", padding: "20px 30px" }}>
                ALL FAKTURA
              </NavLink>
            </Col>
            <Col className=" text-center">
              <NavLink to="/arkiv/group-report" className="btn btn-link" 
                    style={{ width: "120px", padding: "20px 30px" }}>
                Z - Repport 
              </NavLink>
            </Col>
            <Col className=" text-center">
              <NavLink to="/arkiv/lager-report" className="btn btn-link" 
                    style={{ width: "120px", padding: "20px 30px" }}>
                LAGER RAPPORT
              </NavLink>
            </Col>
            {/* <Col className=" text-center">
              <NavLink to="#" className="btn btn-link" 
                    style={{ width: "120px", padding: "20px 30px" }}>
                ANSATTE TIP RAPPORT
              </NavLink>
            </Col> */}
            {/* <Col className=" text-center">
              <NavLink to="#" className="btn btn-link" 
                    style={{ width: "120px", padding: "20px 30px" }}>
                TILPASS
              </NavLink>
            </Col> */}
          </Row>
          <Row className=" m-b-1">
{/*             
            <Col className=" text-center">
              <NavLink to="#" className="btn btn-link" 
                    style={{ width: "120px", padding: "20px 30px" }}>VARSLER
              </NavLink>
            </Col> */}
            <Col className=" text-center">
              <NavLink
                onClick={() => setIsModalVisible(true)}
                to="#"
                className="btn btn-link" 
                    style={{ width: "120px", padding: "20px 30px" }}
              >
                LEGG TIL FILER
              </NavLink>
              <LeggTilFiler
                isModalVisible={isModalVisible}
                handleModalVisible={handleModalVisible}
              />
            </Col>
            <Col className=" text-center">
              <NavLink to="/arkiv/progress-kart" className="btn btn-link" 
                    style={{ width: "120px", padding: "20px 30px" }}>
                PROGRESS KART
              </NavLink>
            </Col>
            <Col className=" text-center">
              <NavLink to="/arkiv/tip-rapport" className="btn btn-link" 
                    style={{ width: "120px", padding: "20px 30px" }}>
                TIP RAPPORT
              </NavLink>
            </Col>
            {/* <Col className=" text-center">
              <NavLink to="#" className="btn btn-link" 
                    style={{ width: "120px", padding: "20px 30px" }}>
                BANK RAPPORT
              </NavLink>
            </Col> */}
          </Row>


          <Row className=" m-b-1">
           
            
            {/* <Col className=" text-center">
              <NavLink to="#" className="btn btn-link" 
                    style={{ width: "120px", padding: "20px 30px" }}>
                KONTANT RAPPORT
              </NavLink>
            </Col> */}
            {/* <Col className=" text-center">
              <NavLink to="#" className="btn btn-link" 
                    style={{ width: "120px", padding: "20px 30px" }}>
                WEB RAPPORT
              </NavLink>
            </Col> */}
          </Row>
          <Row className=" m-b-1">
            <Col className=" text-center">
              <NavLink to="/arkiv/week-report" className="btn btn-link" 
                    style={{ width: "120px", padding: "20px 30px" }}>
                UKENTLIG RAPPORT
              </NavLink>
            </Col>
            <Col className=" text-center">
              <NavLink to="/arkiv/month-report" className="btn btn-link" 
                    style={{ width: "120px", padding: "20px 30px" }}>
                MÅNEDLIG RAPPORT
              </NavLink>
            </Col>
            <Col className=" text-center">
              <NavLink to="/arkiv/year-report" className="btn btn-link" 
                    style={{ width: "120px", padding: "20px 30px" }}>
                ÅRLIG RAPPORT
              </NavLink>
            </Col>
          </Row>
          <Row className=" m-b-1">
            <Col className=" text-center">
              <NavLink to="/arkiv/tfl-rapport" className="btn btn-link" 
                    style={{ width: "120px", padding: "20px 30px" }}>
                TFL RAPPORT
              </NavLink>
            </Col>
            <Col className=" text-center">
              <NavLink to="/arkiv/pos-report" className="btn btn-link" 
                    style={{ width: "120px", padding: "20px 30px" }}>
                POS RAPPORT
              </NavLink>
            </Col>
            <Col className=" text-center">
              <NavLink to="/arkiv/top-selling-prd" className="btn btn-link" 
                    style={{ width: "120px", padding: "20px 30px" }}>
                TOP SELLINGS
              </NavLink>
            </Col>
          </Row>
          <Row className="m-b-1">
            {/* <Col className=" text-center">
              <NavLink to="#" className="btn btn-link" 
                    style={{ width: "120px", padding: "20px 30px" }}>
                IK SYSTEM RAPPORT
              </NavLink>
            </Col> */}
            <Col className=" text-center">
              <NavLink to="/arkiv/refundReport" className="btn btn-link" 
                    style={{ width: "120px", padding: "20px 30px" }}>
                REFUSJON RAPPORT
              </NavLink>
            </Col>
            <Col className=" text-center">
             
            </Col>
            <Col className=" text-center">
             
             </Col>
          </Row>
        </Card.Body>
      </Card>
    </Home>
  );
};

export default Arkiv;
