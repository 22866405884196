import React, { useState, useEffect } from "react";
import {  Row, Col, DatePicker, Button } from "antd";
import { Card } from "react-bootstrap";
import { POSTAPI } from "../../Vendor/AllApi";
import appConfig from "../../../helpers/appConfig";
import { Chart } from "react-google-charts";
import moment from "moment";
import { Typography } from 'antd';
import "./indec.css";
import { Empty } from "antd";
const bid = localStorage.getItem("bid");
const token = localStorage.getItem("token");
const { MonthPicker, WeekPicker } = DatePicker;
const { Title } = Typography;



const GroupRepport = () => {
    // Example data structure

        const [data, setData] = useState({});
  const [sDate, setSDate] = useState("");
  const [eDate, setEDate] = useState("");
  const [currDate, setCurrDate] = useState("");
  const [graphDate, setGraphDate] = useState("");

  let data2 = {};
  useEffect(()=>{
    let date = new Date();
    let startDate = moment(date).startOf("month").add(1, "days")._d;
    let endDate =moment(date).endOf("month").add(1, "days")._d;
    let branchId = bid;
    data2.startDate = startDate;
    data2.endDate = endDate;
    data2.branchId = bid;

    paymentModeData(data2);

    console.log("intial data =====", startDate , endDate , branchId);

  },[])

  const paymentModeData = async (postdata) => {
    console.log("payload=====", postdata)
   
    const headers = {
      "x-auth-token": token,
    };
    try {
      let resData = await POSTAPI(
        `${appConfig.endpoint}/api/v1/employee/arkiv/group-report`,
        postdata,
        {
          headers: headers,
        }
      );
      if (Object.keys(resData.data).length > 0) {
        setData(resData.data);
        setGraphDate(resData.BeDate);
        console.log(Object.keys(resData.data).length);
      }else{
        setData({});
        setGraphDate("");
      }
    } catch (error) {
      setData({});
      console.log(error);
      setGraphDate('');
    }
  };


  const filterData = (date, type) => {
    let postdata = {};
    let day = "";
    day = type;
    // console.log("type==========" , day )

    switch (type) {
      case "m":
        postdata = {
          startDate: moment(date).startOf("month").add(1, "days"),
          endDate: moment(date).endOf("month").add(1, "days"),
          branchId: bid,
        };
        break;
      case "w":
        postdata = {
          startDate: moment(date).startOf("week").add(2, "days"),
          endDate: moment(date).endOf("week").add(2, "days"),
          branchId: bid,
        };
        break;
      case "d":
        postdata = {
          startDate: moment(date),
          endDate: moment(date).add(1, "days"),
          branchId: bid,
        };
    }
    paymentModeData(postdata); 
  
  };



    let coupan = (data.couponData)? data.couponData :  {};

    let restCouponAmt = coupan.hasOwnProperty('totalCouponValue') ? coupan.totalCouponValue : 0

  let kontant =  Number(data.totalCash);
  let tdCard = data.OrcTotal + Number(data.onlineTotal);
  tdCard = Number(tdCard);
  const card = Number(Number(data.totalBank) + Number(data.treeDriveTotal));
  const vipps = Number(Number(data.onlineVippsTotal) + data.treeDriveVippsTotal);
  const coupanVal = Number(data.totalCoupan);
  const kredit = Number(data.totalCredit);
  const kontantBoxRapport = Number(data.totalBoxOpen);
  const totalTip = Number(data.totalTip);
  const totalRefund= Number(data.totalRefund)


  const finalTotal =  ((kontant + card + tdCard + vipps + kredit + coupanVal - totalTip) - restCouponAmt) - totalRefund


  // console.log("---finalTotal---", finalTotal)

  // Format data for pie chart
  const pieChartData = [
    ['Category', 'Value'],
    [`Kontant - Kr. ${kontant}`, kontant],
    [`EZYGO Card - Kr. ${tdCard}`, tdCard],
    [`Card - Kr. ${card}`, card],
    [`Vipps - Kr. ${vipps}`, vipps],
    [`Coupan - Kr. ${coupanVal}`, coupanVal],
    [`Kredit - Kr. ${kredit}`, kredit],
    [`KontantBoxRapport - Kr. ${kontantBoxRapport}`, kontantBoxRapport],
  ];

    const options = {
    is3D: false,
    height: 400,
    width: 500,
    chartArea: { left: 0, top: 0, width: "100%", height: "100%" },
    animation: {
      startup: true, // Enable animation when the chart is first rendered
      duration: 2000, // Animation duration in milliseconds
      easing: 'out', // Animation easing function
    },
  };

  let BeDate = (data.BeDate)? data.BeDate :  {};
  let startDate = BeDate.hasOwnProperty('startDate') ? BeDate.startDate :moment(new Date()).startOf("month")
  let endDate = BeDate.hasOwnProperty('endDate') ? BeDate.endDate :moment(new Date()).endOf("month").add(1, "days")

  return (
    <>
    <Card className='mb-4'>
      <Card.Header className="h4">Prosentvis fordeling av mottatt inntekt (grupperapport)</Card.Header>
      </Card>
      <div className="paymentgraph d-flex m-3 justify-content-between">
        <DatePicker onChange={(e) => filterData(e, "d")} />
        <WeekPicker
          onChange={(e) => filterData(e, "w")}
          placeholder="Select week"
        />
        <MonthPicker
          placeholder="Select Month"
          onChange={(e) => filterData(e, "m")}
        />
        <div
          className="justify-content-end
            "
        ></div>
      </div>
      <Row className="graph-display" gutter={16}>
        <Col style={{ paddingTop:"30px"}}>
          { data.OrcTotal == 0 ? (
                <div className="d-flex justify-content-center pt-5">
                  <Empty />
                </div>
              ) : (
              <div >
              <div className="d-flex">
          
              <span className="heading2"> {moment(startDate).format('MMM DD, YYYY')} 04:00 am to {moment(endDate).format('MMM DD, YYYY')}  03:59 am</span>
              
              {
                finalTotal ?
                <span className="heading2 pl-5">Total : Kr.{Number(finalTotal).toFixed(2)} </span>
                : ""
              }
              </div>
                <div style={{paddingLeft:"200px"}}>
                    <Chart
                        chartType="PieChart"
                        data={pieChartData}
                        options={options}
                        width={"100%"}
                        height={"300px"}
                    /> 
                </div>
             
              </div>
              )}
        </Col>
      </Row>
    </>
  )
}

export default GroupRepport

