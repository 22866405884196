import React, { useState, useEffect } from 'react'
import { Button, DatePicker } from 'antd';
import { Chart } from "react-google-charts";
import { POSTAPI } from '../../Vendor/AllApi';
import appConfig from '../../../helpers/appConfig';
import { Empty } from 'antd';
import { Card } from "react-bootstrap";
import moment from 'moment';
const bid = localStorage.getItem("bid");
const token = localStorage.getItem("token");
const { MonthPicker, RangePicker, WeekPicker } = DatePicker;

const IncomeGraph = () => {
  const [data, setData] = useState([]);
  const [sDate , setSDate ] = useState("");
  const [eDate , setEDate ] = useState("");
  const [currDate , setCurrDate ] = useState("");
  const [graphDate, setGraphDate] = useState("");


  const [lineChartData, setLineChartData] = useState([]);

  useEffect(() => {
    //setLoading(true);
    // filterData(moment(new Date()), "d");
    console.log('----data array-----', lineChartData);
  }, [lineChartData]);

  useEffect(()=>{
    let date = new Date();
    let startDate = moment(date).startOf("month").add(1, "days")._d;
    let endDate =moment(date).endOf("month").add(1, "days")._d;
    let branchId = bid;
    let data = {};
    data.startDate = startDate;
    data.endDate = endDate;
    data.branchId = bid;
    data.type = "day";

    incomeByDaysData(data);

    console.log("intial data =====", startDate , endDate , branchId);

  },[]);


  const incomeByDaysData = async (postdata) => {
    const headers = {
      "x-auth-token": token,
    };
    try {
      let resData = await POSTAPI(
        `${appConfig.endpoint}/api/v1/employee/invoice/IncomeAmountDashboard`,
        postdata,
        {
          headers: headers,
        }
        
      );
      if(resData.data.length > 0 ){
        // setData(resData.data);
        // console.log(Object.keys(resData.data).length);

        if(resData.data.length > 0){
          let d = resData.data;

          let dataArray = [["Periode", "Salg (NOK)"]];
          
          d.map((i)=>{

            let t = [];
            let thisDate = moment(i._id.date).format("DD/MM");
            let totalAmount = i.total;

            t = [thisDate, totalAmount];
            dataArray.push(t);

          })

          setLineChartData(dataArray);
          setGraphDate(resData.BeDate);

        }else{
          setLineChartData([]);
          setGraphDate("");
        }

      }else{
        console.log("no data")
        setData([]);
        setLineChartData([]);
      }

    } catch (error) {
      setData([]);
      setLineChartData([]);
      setGraphDate("");
      console.log(error);
    }
  };


  function filterData2(date, dateString) {
    console.log("dateis", date)
    setSDate(moment(date));
  }

  function filterData3(date, dateString) {
    console.log("dateis", date)
    setEDate(moment(date).add(1, "days"));
   
  }

  function handleDone(){
     let payload = {};
    payload.startDate = sDate;
    payload.endDate = eDate;
    payload.branchId = bid;
    payload.type = "day";
  
  
    incomeByDaysData(payload)
  

  }

// function filterData(date, dateString) {
//   console.log("dateis", date)
// //  let startDate = dateString[0]
// //  let endDate = dateString[1]

//  let startDate = moment(date[0])._d;
//  let endDate =  moment(date[1])._d;
//   console.log( startDate,endDate);

//   let payload = {};
//   payload.startDate = startDate;
//   payload.endDate = endDate;
//   payload.branchId = bid;
//   payload.type = "day";


//   incomeByDaysData(payload)

// }

console.log("data", data)


 const dd = [
  ["Date", "Sales"],
  ["2004", 1000],
  ["2005", 1170],
  ["2006", 660],
  ["2007", 1030]
];

const options = {
//  title: "Days Wise Income",
  curveType: "function",
  legend: { position: "top right" },
  hAxis: {
    title: "Periode",
    
    
  },
  vAxis: {
    title: "Salg (NOK)",
  },
  animation: {
    startup: true, // Enable animation when the chart is first rendered
    duration: 2000, // Animation duration in milliseconds
    easing: 'out', // Animation easing function
  },
  // colors: ['#07182c']


};

  return (
   <>
     <div>
     <Card className='mb-4'>
      <Card.Header className="h4">Sammenligningsdiagram for salgsresultater : Dagsmessig</Card.Header>
      </Card>
     <div className="d-flex m-3 justify-content-center">
      {/* <RangePicker onChange={filterData} />  */}
     <DatePicker className="mr-3" placeholder='Start Date' onChange={(e) => filterData2(e)} />
     <DatePicker className="mr-3" placeholder= 'End Date' onChange={(e) => filterData3(e)} />
     <Button
                key="back"
                className="btn btn-ab"
                style={{
                    height:"32px",
                    width:"120px"
                  }}
                onClick={() => handleDone()}
              >
                Done
      </Button>
     </div>
    <br />
    </div>
    <div>
    {
      lineChartData.length == 0 ?
      <div className="d-flex justify-content-center pt-5">
      <Empty /> 
      </div>
      :
     <>
    
      <Chart
      chartType="LineChart"
      width="100%"
      height="400px"
      data={lineChartData}
      options={options}
    />
     <div className="d-flex justify-content-center">
              <span className="heading2"> {moment(graphDate.startDate).format('MMMM DD, YYYY')} 04:00 am to   {moment(graphDate.endDate).format('MMMM DD, YYYY')} 03:59 am</span>
              </div>
     </>

    }
 
    </div>
   </>
  )
}

export default IncomeGraph
