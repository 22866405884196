// import React from "react";
// import { useState,useEffect } from "react";
// import { NavLink } from "react-router-dom";
// import history from "../../history";
// import {
//   Input,
//   Button,
//   Modal,
//   Row,
//   Col,
//   Select,
//   Divider,
//   DatePicker,
// } from "antd";
// import Axios from "axios";
// import endpoint from "../../helpers/endpoint";
// import BootstrapSwitchButton from "bootstrap-switch-button-react";
// import moment from "moment";

// import appConfig from "../../helpers/appConfig";

// const Offer = (props) => {
//   let {  prodData,setIsofferSet,isofferSet, offerBackgrounds } = props;
//   let id= prodData._id
//   let offPrice=prodData.offer.offerPrice?prodData.offer.offerPrice:prodData.offer.price;
//   const [endDate, setEndDate] = useState("");
//   const [startDate, setStartDate] = useState("");
//   const [offerPrice, setOfferPrice] = useState(0);
//   const[offerEnable,setOfferEnable]=useState(false)
//   const [offerModal,setOfferModal]=useState(false)
//   const [availableOn, setAvailableOn] = useState('all');
//   const [adBgColor, setAdBgColor] = useState('#333333');

//   const [adBg, setAdBg] = useState('');

//   let hdr = localStorage.token;

//   useEffect(()=>{
//     console.log('---ad bg selected---', adBg)
//   }, [adBg])

//   useEffect(()=>{
//     console.log("-----useEffect-------------",prodData)
//     setOfferPrice(offPrice)
//     setStartDate(prodData.offer.startDate)
//     setEndDate(prodData.offer.endDate)
//     let isOffer=prodData.offerEnable?prodData.offerEnable:false
//     setOfferEnable(isOffer)
//     // console.log("---------prodaat",prodData)
// },[])

// // useEffect(()=>{
// //   console.log("------modal open",prodData)
// //   // setOfferPrice(prodData.offer.offerPrice)
// //   // setStartDate(prodData.offer.startDate)
// //   // setEndDate(prodData.offer.endDate)
// //   // let isOffer=prodData.offerEnable?prodData.offerEnable:false
// //   // setOfferEnable(isOffer)

// // },[])




  
//   // console.log("offer moda", offerModal);
  

  
//   const offerPriceApi = async () => {
//     console.log("postDate", {
//       startDate,
//       endDate,
//       offerPrice,
//       offerEnable
     
//     });

   
    
//     let data = {
//       startDate,
//       endDate,
//       offerPrice,
//       id,
//       offerEnable,
//       availableOn,
//       adBgColor,
//       adBg
//     };

//     let resData = await Axios.post(
//       `${endpoint}/api/v1/employee/products/addOffer`,
//       data,
//       {
//         headers: {
//           token: hdr,
//         },
//       }
//     );

//     // console.log("--------resdata",resData)

//     if(resData.data.status==200){
//         setOfferModal(false)
//       //  history.push("/menu")
//       setIsofferSet(!isofferSet)
        
//     }
//   };


//   const offerUi=()=>{

//     let offerSDate=prodData.offer.startDate
//     offerSDate=moment(new Date(offerSDate)).format("DD-MM-YYYY")
//     let offerEDate=prodData.offer.endDate
//     offerEDate=moment(new Date(offerEDate)).format("DD-MM-YYYY")
//     return(
//       <Modal
//       visible={offerModal}
//       closable={false}
//       footer={[
//         <Button
//           key="back"
//           className="btn-ab text-color"
//           onClick={() => setOfferModal(false)}
//         >
//           Cancel
//         </Button>,
//         <Button
//           key="submit"
//           type="primary"
//           className="btn-ab text-color"
//           onClick={() => offerPriceApi()}
//         >
//           Save
//         </Button>,
//       ]}
//     >



//       <Row>
//           <Col span={24}>
//             <h5>New Offer</h5>
//           </Col>
//           <Divider />
//           <div className="d-flex justify-content-between align-items-center">
//             <p>Product Name :</p>
//             <p className="text-right">{prodData.name}</p>
//           </div>
//           <div className="d-flex justify-content-between align-items-center">
//             <p>Offer Price :</p>
//             <p className="text-right">
//               {" "}
//               <input
//                 className="text-right"
//                 type="number"
//                 placeholder="Set Offer Price"
//                 onChange={(e) => setOfferPrice(e.target.value)}
//                 value={offerPrice}
//               ></input>
//             </p>
//           </div>
//           <div className="d-flex justify-content-between align-items-center">
//             <p>Start Date :</p>
//         <DatePicker
//         // value={startDate}
//           onChange={(date, dateString) => {
//             // setDateFilter("byDay", date);

//             let setDate = new Date(date);
//             console.log("date", { setDate, dateString });
//             setStartDate(setDate);
//           }}
//           format="YYYY-MM-DD"
//           placeholder="Select Day"
//         />
//           </div>
//           <div className="d-flex justify-content-between align-items-center">
//             <p>End Date :</p>
//         <DatePicker
//           onChange={(date, dateString) => {
//             // setDateFilter("byDay", date);

//             let setDate = new Date(date);
//             console.log("date", { setDate, dateString });
//             setEndDate(setDate);
//           }}
//           format="YYYY-MM-DD"
//           placeholder="Select Day"
//         />
//           </div>
//           <div className="d-flex justify-content-between align-items-center">
//             <p>Activate Offer :</p>
//             <div className="d-flex">
//               <span style={{marginRight:'5px'}} >{offerEnable ? "Disable" : "Enable"}</span>
//               <BootstrapSwitchButton
//                 size="xs"
//                 style="btn-ab"
//                 onstyle="btn-ab"
//                 checkedChildren="Yes"
//                 unCheckedChildren="No"

//                 // checked={isShowWebValues[record._id]}
//                 checked={offerEnable}
//                 onChange={() => {
//                   setOfferEnable(!offerEnable);
//                 }}
//               />
//             </div>
//           </div>
//           <div className="d-flex justify-content-between align-items-center">
//             <p>Available On (All/Online):</p>
//             <select onChange={(e) => setAvailableOn(e.target.value)}>
//               <option value="all">All</option>
//               <option value="online">Online</option>
//             </select>
//           </div>

//           <div className="d-flex justify-content-between align-items-center">
//             <p>Advertisement background color:</p>
//             <input
//                 className="text-right"
//                 type="color"
//                 value={adBgColor}
//                 onChange={(e)=> setAdBgColor(e.target.value)}
//               ></input>
//           </div>

//           <div className="mt-3">
//               <p><strong>Select Advertisment Background: </strong></p>
//               {offerBackgrounds.map((item, index)=>{
//                 return (
//                   <>
//                     <img
//                       key={index}
//                       src={`${appConfig.s3url}/${item.url}`}
//                       style={{ 
//                         maxWidth: 100, 
//                         margin: '5px', 
//                         cursor: 'pointer',
//                         border: adBg == item._id ? '3px solid blue' : 'none'
//                       }}
//                       onClick={()=>setAdBg(item._id)}
//                     />
//                   </>
//                 )
//               })}
//           </div>


//           <Col span={24} className="mt-3">
//             <h5>Current Offer ({offerEnable ? "Active" : "Closed"})</h5>
//           </Col>
//       <Divider></Divider>
//           <div className="d-flex justify-content-between align-items-center">
//             <p>Date :</p>
//             <p>
//               {offerSDate} to {offerEDate}
//             </p>
//           </div>
//           <div className="d-flex justify-content-between align-items-center">
//             <p>Price :</p>
//             <p> {offPrice}</p>
//           </div>
//         </Row>

     
//     </Modal>
//     )
//   }










//   return (
//     <div>
//       <Button 
//               onClick={()=>setOfferModal(!offerModal)}
//             >Add Offer </Button>
//   { offerUi()}
//     </div>
//   );
// };

// export default Offer;
import React, { useState, useEffect } from "react";
import {
  Button,
  Modal,
  Row,
  Col,
  Divider,
  DatePicker,
  notification,
  Spin,
} from "antd";
import Axios from "axios";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import moment from "moment";
import appConfig from "../../helpers/appConfig";
import endpoint from "../../helpers/endpoint";

const Offer = (props) => {
  const { prodData, setIsofferSet, isofferSet, offerBackgrounds } = props;
  const id = prodData._id;
  const offPrice = prodData.offer.offerPrice || prodData.offer.price;
  const [endDate, setEndDate] = useState("");
  const [startDate, setStartDate] = useState("");
  const [offerPrice, setOfferPrice] = useState(0);
  const [offerEnable, setOfferEnable] = useState(false);
  const [offerModal, setOfferModal] = useState(false);
  const [availableOn, setAvailableOn] = useState("all");
  const [adBgColor, setAdBgColor] = useState("#333333");
  const [adBg, setAdBg] = useState("");
  const [loading, setLoading] = useState(false);

  const hdr = localStorage.token;

  useEffect(() => {
    setOfferPrice(offPrice);
    setStartDate(prodData.offer.startDate);
    setEndDate(prodData.offer.endDate);
    setOfferEnable(!!prodData.offerEnable);
    if (offerBackgrounds.length > 0) {
      setAdBg(offerBackgrounds[0]._id); // Default to the first background
    }
  }, [prodData, offerBackgrounds]);

  const openNotification = (type, message) => {
    notification[type]({
      message,
      placement: "topRight",
    });
  };

  const offerPriceApi = async () => {
    setLoading(true); // Start loading
    const data = {
      startDate,
      endDate,
      offerPrice,
      id,
      offerEnable,
      availableOn,
      adBgColor,
      adBg,
    };

    try {
      const resData = await Axios.post(
        `${endpoint}/api/v1/employee/products/addOffer`,
        data,
        {
          headers: {
            token: hdr,
          },
        }
      );

      if (resData.data.status === 200) {
        openNotification("success", "Offer saved successfully!");
        setIsofferSet(!isofferSet);
        setOfferModal(false); // Close modal
      } else {
        openNotification("error", "Failed to save the offer.");
      }
    } catch (error) {
      openNotification("error", "An error occurred while saving the offer.");
    } finally {
      setLoading(false); // Stop loading
    }
  };

  const offerUi = () => {
    const offerSDate = moment(new Date(prodData.offer.startDate)).format("DD-MM-YYYY");
    const offerEDate = moment(new Date(prodData.offer.endDate)).format("DD-MM-YYYY");

    return (
      <Modal
        visible={offerModal}
        closable={!loading} // Prevent closing the modal during loading
        footer={[
          <Button
            key="back"
            className="btn-ab text-color"
            onClick={() => setOfferModal(false)}
            disabled={loading} // Disable cancel button during loading
          >
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            className="btn-ab text-color"
            onClick={offerPriceApi}
            loading={loading} // Add loading spinner to Save button
          >
            Save
          </Button>,
        ]}
      >
        {loading && (
          <div
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              width: "100vw",
              height: "100vh",
              background: "rgba(0, 0, 0, 0.5)",
              zIndex: 1000,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Spin size="large" tip="Processing..." />
          </div>
        )}
        <Row>
          <Col span={24}>
            <h5>New Offer</h5>
          </Col>
          <Divider />
          <div className="d-flex justify-content-between align-items-center">
            <p>Product Name :</p>
            <p className="text-right">{prodData.name}</p>
          </div>
          <div className="d-flex justify-content-between align-items-center">
            <p>Offer Price :</p>
            <input
              className="text-right"
              type="number"
              placeholder="Set Offer Price"
              onChange={(e) => setOfferPrice(e.target.value)}
              value={offerPrice}
            />
          </div>
          <div className="d-flex justify-content-between align-items-center">
            <p>Start Date :</p>
            <DatePicker
              onChange={(date, dateString) => setStartDate(new Date(date))}
              format="YYYY-MM-DD"
              placeholder="Select Day"
            />
          </div>
          <div className="d-flex justify-content-between align-items-center">
            <p>End Date :</p>
            <DatePicker
              onChange={(date, dateString) => setEndDate(new Date(date))}
              format="YYYY-MM-DD"
              placeholder="Select Day"
            />
          </div>
          <div className="d-flex justify-content-between align-items-center">
            <p>Activate Offer :</p>
            <BootstrapSwitchButton
              size="xs"
              checked={offerEnable}
              onChange={() => setOfferEnable(!offerEnable)}
            />
          </div>
          <div className="d-flex justify-content-between align-items-center">
            <p>Available On (All/Online):</p>
            <select onChange={(e) => setAvailableOn(e.target.value)}>
              <option value="all">All</option>
              <option value="online">Online</option>
            </select>
          </div>
          <div className="d-flex justify-content-between align-items-center">
            <p>Advertisement background color:</p>
            <input
              type="color"
              value={adBgColor}
              onChange={(e) => setAdBgColor(e.target.value)}
            />
          </div>
          <div className="mt-3">
            <p>
              <strong>Select Advertisement Background: </strong>
            </p>
            {offerBackgrounds.map((item, index) => (
              <img
                key={index}
                src={`${appConfig.s3url}/${item.url}`}
                style={{
                  maxWidth: 100,
                  margin: "5px",
                  cursor: "pointer",
                  border: adBg === item._id ? "3px solid blue" : "none",
                }}
                onClick={() => setAdBg(item._id)}
              />
            ))}
          </div>
          <Col span={24} className="mt-3">
            <h5>Current Offer ({offerEnable ? "Active" : "Closed"})</h5>
          </Col>
          <Divider />
          <div className="d-flex justify-content-between align-items-center">
            <p>Date :</p>
            <p>
              {offerSDate} to {offerEDate}
            </p>
          </div>
          <div className="d-flex justify-content-between align-items-center">
            <p>Price :</p>
            <p>{offPrice}</p>
          </div>
        </Row>
      </Modal>
    );
  };

  return (
    <div>
      <Button onClick={() => setOfferModal(true)}>Add Offer</Button>
      {offerUi()}
    </div>
  );
};

export default Offer;
