import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Logo from "../../assets/images/logo.png";
import Machine from "../../assets/circleimg.png";
import ORCdark from "../../assets/images/orc-dark.png";
import AryanLogo from "../../assets/images/logo.png"
import { Container, Row, Image, Col as Column } from "react-bootstrap";
import "./style.css";
import { postLogin, emptyErrorMsg, emptySuccessMsg } from "../../action";
import { Form, Input, Checkbox, Button, Col, notification } from "antd";
import { sendNotification } from "../../helpers/commFuc";
import ForgetEmail from "./ForgetEmail";
const errorObj = {
  uname: {
    show: false,
    massage: ""
  },
  password: {
    show: false,
    massage: ""
  }
};
const Login = () => {
  const dispatch = useDispatch();
  let [errors, setErrors] = useState(errorObj);
  let [username, setUserName] = useState("");
  let [password, setPassword] = useState("");
  let [visibleModel, setVisibleModel] = useState(false);
  const errorMassage = useSelector(({ errorMassage }) => errorMassage);
  const successMassage = useSelector(
    ({ successMassage }) => successMassage.message
  );
  const handleSubmit = () => {
    // if (validateForm()) {
    //   const data = {
    //     username,
    //     password,
    //   };
    //  dispatch(postLogin(data));
    // }
    if(username && password) {
      const data = {
        username,
        password
      };
      dispatch(postLogin(data));
    }
  };
  const validateForm = () => {
    let errorObj = { ...errors };
    let isErr = 0;
    // const pattern = new RegExp(/^[a-zA-Z0-9-.]+@(?:[a-zA-Z0-9]+\.)+[A-Za-z]+$/);
    if (!username) {
      errorObj.uname.show = true;
      errorObj.uname.massage = "*Username is required";
      isErr++;
    }
    // if (username && !pattern.test(username)) {
    //   errorObj.uname.show = true;
    //   errorObj.uname.massage = "*Invalid Username";
    //   isErr++;
    // }
    if (username) {
      errorObj.uname.show = true;
      errorObj.uname.massage = "*Invalid Username";
      isErr++;
    }
    if (!password) {
      errorObj.password.show = true;
      errorObj.password.massage = "*Password is required";
      isErr++;
    }
    if (isErr) {
      setErrors(errorObj);
      return false;
    } else {
      return true;
    }
  };
  const handlePassword = () => {
    let errorObj = { ...errors };
    errorObj.password.show = false;
    errorObj.password.massage = "";
    setErrors(errorObj);
  };
  const handleUserName = () => {
    let errorObj = { ...errors };
    errorObj.uname.show = false;
    errorObj.uname.massage = "";
    setErrors(errorObj);
  };

  useEffect(() => {
    if (errorMassage) {
      sendNotification("error", errorMassage);
      dispatch(emptyErrorMsg());
    }
  }, [errorMassage]);
  useEffect(() => {
    if (successMassage) {
      sendNotification("success", successMassage);
      dispatch(emptySuccessMsg());
    }
  }, [successMassage]);

  return (
    <>
        <Container  className="login-body">
            <Row className=" justify-content-center">
              <Column sm={6} md={12}  lg={12} className=" login-main">
                <div className="imgcontainer">
                  <Image 
                  // src={ORCdark} 
                  src={AryanLogo}
                  alt="Avatar" className="avatar" />
                  <h3>Login to your Member Account</h3>
                </div>
                <Form
                  name="loginForm"
                  className="login-form"
                  initialValues={{ remember: true }}
                >
                  <Form.Item
                    name="username"
                    className={errors.uname.show ? "isError" : ""}
                  >
                    <Input
                      placeholder="Username"
                      required
                      type="email"
                      onClick={() => handleUserName()}
                      onChange={(e) => setUserName(e.target.value)}
                    />
                    <p className="text-danger login-error-msg">
                      {errors.uname.massage ? errors.uname.massage : ""}
                    </p>
                  </Form.Item>
                  <br/>
                  <Form.Item
                    name="password"
                    className={errors.uname.password ? "isError" : ""}
                  >
                    <Input
                      type="password"
                      required
                      placeholder="Password"
                      onClick={() => handlePassword()}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                    <p className="text-danger login-error-msg">
                      {errors.password.massage ? errors.password.massage : ""}
                    </p>
                  </Form.Item>
                  <Form.Item className="py-2">
                    <Col span={12}>
                      <Form.Item name="remember" valuepropname="checked">
                        <Checkbox>Remember me</Checkbox>
                      </Form.Item>
                    </Col>
                  </Form.Item>
                  <Form.Item>
                    <Button
                      onClick={handleSubmit}
                      htmlType="submit"
                      className="login-form-button btn-ab loginIn-btn"
                    >
                      Log in
                    </Button>
                  </Form.Item>
                  <Form.Item className="py-2">
                    <Col span={12}>
                      <a
                        className="login-form-forgot "
                        href="#"
                        onClick={() => setVisibleModel(true)}
                      >
                        Forgot password?
                      </a>
                    </Col>
                  </Form.Item>
                </Form>
            </Column>
              {/* <Column
                sm={5}
                md={5}
                lg={5}
                className=" left-login a-logo productImg position-relative"
              >
                <Image
                  src={Logo}
                  alt="Avatar"
                  className="a-board position-absolute"
                />
                <Image src={Machine} className="img-fluid mac-pad" />
              </Column> */}
          </Row>
        <ForgetEmail
          setVisibleModel={setVisibleModel}
          visibleModel={visibleModel}
        />
      </Container>
    </>
  );
};
export default Login;
