import React, { useEffect, useState } from "react";
import axios from "axios";
import { Card, Row, Col } from "react-bootstrap";
import { DatePicker, Button, Select } from "antd";
import { connect } from "react-redux";
import history from "../../../history";
import {
  getDailyReportPrint,
  viewDailyReport
} from "./../../../action/ReportAction";
import moment from "moment";
import TableSum from "../../../containers/TableSum/TableSum";
import PrintReport from "../../../containers/PrintReport/PrintReport";
import {
  dailySummaryColumns,
  dailyColumns,
  summaryData
} from "./../../Table/TableColumns.js";

import appConfig from "../../../helpers/appConfig";
const { MonthPicker, WeekPicker } = DatePicker;
const currentYear = new Date().getFullYear();
const Option = Select.Option;
const yearsArr = [];
for (let i = currentYear; i > 2018; i--) {
  yearsArr.push(i.toString());
}
const ReportTable = (props) => {
  const { page } = props;
  const defaultDate = page === "year" ? currentYear : moment(new Date());
  let [rdate, setRDate] = useState(defaultDate);
  let [sendProps, setSendProps] = useState(false);
  let [isLoaded, setIsLoaded] = useState(false);
  let [totalSubtotal, setTotalSubtotal] = useState(0);
  let [totalTax, setTotalTax] = useState(0);
  let [totalDriveoutCharge, setTotalDriveoutCharge] = useState(0);
  let [totalTaxPrice, setTotalTaxPrice] = useState(0);
  let [items, setItems] = useState([]);
  let [pageName, setPageName] = useState("");
  let [dailyReport, setDailyReport] = useState({ invoiceData: [], branch: "" });
  useEffect(() => {
    setIsLoaded(true);
    getReports(rdate);
  }, []);
  const onFilterChange = (date) => {
    setRDate(date);
    setIsLoaded(true);
    getReports(date);
  };
  useEffect(() => {
    const setItemsData = async () => {
      const resArr = await getItems(dailyReport);
      setItems(resArr);
      setTotal(dailyReport);
      setSendProps(true);
    };
    if (dailyReport.invoiceData.length) {
      setItemsData();
    }
  }, [dailyReport]);
  const searchYear = async (e) => {
    let date = e.key;
    setRDate(date);
    setIsLoaded(true);
    getReports(date);
  };

  const getItems = (dailyReport) => {
    return dailyReport.invoiceData && dailyReport.invoiceData.length
      ? dailyReport.invoiceData.map((item) => ({
          ...item,
          // createdDate: moment(new Date(item.created).format("DD/MM/YYYY")),
          createdDate: moment(item.created).format("DD/MM/YYYY"),
          createdTime: new Date(item.created).toLocaleTimeString(),
          taxPrice: Number(item.taxPrice).toFixed(3),
          isLoaded: true,
          key: item._id
        }))
      : [];
  };
  const setTotal = (dailyReport) => {
    totalSubtotal = 0;
    totalTax = 0;
    totalDriveoutCharge = 0;
    totalTaxPrice = 0;
    let totalRefund = 0;
    dailyReport.invoiceData.length &&
      dailyReport.invoiceData.forEach((current, index) => {
        current.taxPrice = parseFloat(current.taxPrice);
        current.tax = parseFloat(current.tax);
        current.driveoutCharge = parseFloat(current.driveoutCharge || 0);
        current.totalPrice = parseFloat(current.totalPrice || 0);
        totalSubtotal =
          totalSubtotal +
          (current.taxPrice -
            (current.taxPrice -
              (100 * current.taxPrice) / (100 + current.tax)));

        totalTax =
          totalTax +
          (current.taxPrice - (100 * current.taxPrice) / (100 + current.tax));

           // Adjust totalTaxPrice with refundAmt
           totalRefund = totalRefund + parseFloat(current.refundAmt || 0);
           console.log("totalRefund in daily report", totalRefund)

        if (current.orderType === "Drive Out")
          totalDriveoutCharge =
            totalDriveoutCharge +
            current.driveoutCharge +
            current.extraDriveOut;
        totalTaxPrice = totalTaxPrice + current.totalPrice;
      });
    setTotalSubtotal(totalSubtotal);
    setTotalTax(totalTax);
    setTotalDriveoutCharge(totalDriveoutCharge);
    setTotalTaxPrice(totalTaxPrice-totalRefund);
  };

  const showInvoice = async (invoiceNumber) => {
    let index = await items.findIndex(
      (invoice) => invoice.invoiceNumber === invoiceNumber
    );
    const data = {
      ele: items[index],
      branch: dailyReport.branch ? dailyReport.branch : {},
      reportName: pageName
    };
    await props.viewDailyReport(data).then(() => {
      history.push("/arkiv/view-report");
    });
  };
  // const getReports = (date) => {
  //   let startDate, endDate;
  //   switch (page) {
  //     case "week":
  //       startDate = moment(date).startOf("week");
  //       endDate = moment(date).endOf("week");
  //       setPageName("UKENTLIG RAPPORT");
  //       break;
  //     case "month":
  //       startDate = moment(date).startOf("month");
  //       endDate = moment(date).endOf("month");
  //       setPageName("MÅNEDLIG RAPPORT");
  //       break;
  //     case "year":
  //       const start = new Date(`1/1/${date}`);
  //       startDate = moment(start);
  //       const end = new Date(`12/31/${date}`);
  //       endDate = moment(end);
  //       setPageName("ÅRLIG RAPPORT");
  //       break;
  //   }
  //   const data = {
  //     startDate,
  //     endDate
  //   };
  //   getReportInvoice(data);
  // };

  const getReports = (date) => {
    let startDate, endDate;
    switch (page) {
      case "week":
        startDate = moment(date).startOf("week").add(2, "days");
        endDate = moment(date).endOf("week").add(2, "days");
        setPageName("UKENTLIG RAPPORT");
        break;
      case "month":
        startDate= moment(date).startOf("month").add(1, "days");
        endDate= moment(date).endOf("month").add(1, "days")
        setPageName("MÅNEDLIG RAPPORT");
        break;
      case "year":
        case "year":
              console.log("  year date--------",date)
        console.log("  next year date--------",date+1)
        const start = new Date(`1/1/${date}`);
        startDate =start
        date=Number(date)+1
        const end = new Date(`1/1/${date}`);
        endDate =end;

        console.log("-------- startDate-----" ,startDate)
        console.log("-------- endDate----" ,endDate)
        setPageName("ÅRLIG RAPPORT");
        startDate= moment(startDate).add(1, "days")
      endDate= moment(endDate).add(1, "days")
        break;
    }
    const data = {
      startDate,
      endDate
    };
    getReportInvoice(data);
  };
  const getReportInvoice = async (data) => {
    try {
      const res = await axios.post(
        `${appConfig.endpoint}/api/v1/employee/invoice/daily-reports`,
        data
      );
      if (res.status === 200) {
        setDailyReport((prevState)=>{
          return {...prevState,invoiceData:res.data.data.invoiceData,branch:res.data.data.branch}
        });
      }
      if(res.data.data.invoiceData.length==0){
        setTotalSubtotal(0);
        setTotalTax(0);
        setTotalDriveoutCharge(0);
        setTotalTaxPrice(0);
        setItems([])
      }
      setIsLoaded(false);
    } catch (error) {
      setIsLoaded(false);
    }
  };
  const getFilter = () => {
    switch (page) {
      case "week":
        return (
          <WeekPicker
            onChange={(date, dateString) => onFilterChange(date)}
            placeholder="Select week"
          />
        );
      case "month":
        return (
          <MonthPicker
            onChange={(date, dateString) => onFilterChange(date)}
            placeholder="Select Month"
          />
        );
      case "year":
        return (
          <Select
            labelInValue
            defaultValue={{ key: currentYear }}
            onChange={(e) => searchYear(e)}
          >
            {yearsArr.map((year) => (
              <Option key={year} value={year}>
                {year}
              </Option>
            ))}
          </Select>
        );
    }
  };

  return (
    <>
      <Card >
        <Card.Body className="">
          <Row className="filter">
            <Col span={12} className="">{getFilter()}</Col>
            <Col span={12} className="text-right">
              <Button className="btn-ab" style={{background:"#dcbb68"}}>
                <i
                  className="fa fa-print"
                  style={{ fontSize: 20 }}
                  aria-hidden="true"
                />
              </Button>
            </Col>
          </Row>
        </Card.Body>
        <Row className="row">
          <Col span={12} className=" ">
            <div className="report-table">
              <TableSum
                loading={isLoaded}
                columns={dailyColumns(showInvoice)}
                summaryColumns={dailySummaryColumns(
                  totalSubtotal,
                  totalTax,
                  totalDriveoutCharge,
                  totalTaxPrice
                )}
                data={items}
                summaryData={summaryData()}
                rowKey={(record) => record.productName}
                bordered
              />
              <div style={{ display: "none"}}>
                {sendProps ? (
                  <PrintReport
                    columns={dailyColumns()}
                    summaryColumns={dailySummaryColumns()}
                    data={items}
                    summaryData={summaryData()}
                    branch={dailyReport.branch ? dailyReport.branch : {}}
                    totalTaxPrice={totalTaxPrice}
                    totalSubtotal={totalSubtotal}
                    totalTax={totalTax}
                    type="daily-reports"
                  />
                ) : (
                  ""
                )}
              </div>
            </div>
          </Col>
        </Row>
      </Card>
    </>
  );
};
const mapStateToProps = ({}) => {
  return {};
};
export default connect(mapStateToProps, {
  getDailyReportPrint,
  viewDailyReport
})(ReportTable);
