import React, { useEffect, useState } from "react";
import axios from "axios";
import Home from "../Home";
import { Row, Col, Card } from "react-bootstrap";
import { Button, Input, Form, notification } from "antd";
import appConfig from "../../helpers/appConfig";
import request from "superagent";

/*----------------------------fetch data api start----------------------------*/

export default function tdDiscount(props) {
  const [tdDriveOutdiscountRange, settdDriveOutdiscountRange] = useState({});
  const [tdTakeAwayDiscountRange, settdTakeAwaydiscountRange] = useState({});

  // const [tdTakeAwayDiscountRange, settdTakeAwaydiscountRange] = useState("");

  const tdDiscountPermission = async () => {
    try {
      let brid = localStorage.getItem("bid");
      const res = await axios.get(
        `${appConfig.endpoint}/api/v1/employee/discountModify/getdiscount/${brid}`
        // `${appConfig.endpoint}/api/v1/employee/discountModify/getdiscount/6396ff1b01d2f16f16e8277a`
      );
      settdDriveOutdiscountRange(res.data.data.tdDriveOutdiscountRange);
      settdTakeAwaydiscountRange(res.data.data.tdTakeAwayDiscountRange);
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) {
        }
      }
    }
  };

  useEffect(() => {
    tdDiscountPermission();
  }, []);

  useEffect(() => {}, [tdDriveOutdiscountRange], [tdTakeAwayDiscountRange]);

  /*----------------------------fetch data api end----------------------------*/

  /*--------------change input data for update start-------------------*/

  const handleChange = (e) => {
    if (e.target.name == "tdDriveOutdiscountRange1Min") {
      let t = tdDriveOutdiscountRange;

      let newVal1 = e.target.value;

      // console.log(t.range1Min);
      settdDriveOutdiscountRange((prevState) => ({
        ...prevState,
        range1Min: newVal1
      }));
    }
    if (e.target.name == "tdDriveOutdiscountRange2Min") {
      let t = tdDriveOutdiscountRange;

      let newVal2 = e.target.value;

      // console.log(t.range2Min);
      settdDriveOutdiscountRange((prevState) => ({
        ...prevState,
        range2Min: newVal2
      }));
    }
    if (e.target.name == "tdDriveOutdiscountRange3Min") {
      let t = tdDriveOutdiscountRange;

      let newVal3 = e.target.value;

      // console.log(t.range3Min);
      settdDriveOutdiscountRange((prevState) => ({
        ...prevState,
        range3Min: newVal3
      }));
    }

    if (e.target.name == "tdDriveOutdiscountRange1Max") {
      let t = tdDriveOutdiscountRange;

      let newValmax1 = e.target.value;

      // console.log(t.range1Max);
      settdDriveOutdiscountRange((prevState) => ({
        ...prevState,
        range1Max: newValmax1
      }));
    }
    if (e.target.name == "tdDriveOutdiscountRange2Max") {
      let t = tdDriveOutdiscountRange;

      let newValmax2 = e.target.value;

      // console.log(t.range2Max);
      settdDriveOutdiscountRange((prevState) => ({
        ...prevState,
        range2Max: newValmax2
      }));
    }
    if (e.target.name == "tdDriveOutdiscountRange3Max") {
      let t = tdDriveOutdiscountRange;

      let newValmax3 = e.target.value;

      // console.log(t.range3Max);
      settdDriveOutdiscountRange((prevState) => ({
        ...prevState,
        range3Max: newValmax3
      }));
    }

    if (e.target.name == "tdDriveOutdiscountRangeDis") {
      let t = tdDriveOutdiscountRange;

      let newValDis = e.target.value;

      // console.log(t.discount1);
      settdDriveOutdiscountRange((prevState) => ({
        ...prevState,
        discount1: newValDis
      }));
    }
    if (e.target.name == "tdDriveOutdiscountRangeDis2") {
      let t = tdDriveOutdiscountRange;

      let newValDis2 = e.target.value;

      // console.log(t.discount1);
      settdDriveOutdiscountRange((prevState) => ({
        ...prevState,
        discount2: newValDis2
      }));
    }

    if (e.target.name == "tdDriveOutdiscountRangeDis3") {
      let t = tdDriveOutdiscountRange;

      let newValDis3 = e.target.value;

      // console.log(t.discount1);
      settdDriveOutdiscountRange((prevState) => ({
        ...prevState,
        discount3: newValDis3
      }));
    }

    if (e.target.name == "tdTakeAwayDiscountRangeMin1") {
      let ta = tdTakeAwayDiscountRange;

      let newTA1 = e.target.value;

      // console.log(ta.range1Min);
      settdTakeAwaydiscountRange((prevState) => ({
        ...prevState,
        range1Min: newTA1
      }));
    }
    if (e.target.name == "tdTakeAwayDiscountRangeMin2") {
      let ta = tdTakeAwayDiscountRange;

      let newTA2 = e.target.value;

      // console.log(ta.range2Min);
      settdTakeAwaydiscountRange((prevState) => ({
        ...prevState,
        range2Min: newTA2
      }));
    }
    if (e.target.name == "tdTakeAwayDiscountRangeMin3") {
      let ta = tdTakeAwayDiscountRange;

      let newTA3 = e.target.value;

      // console.log(ta.range3Min);
      settdTakeAwaydiscountRange((prevState) => ({
        ...prevState,
        range3Min: newTA3
      }));
    }
    if (e.target.name == "tdTakeAwayDiscountRangeMin4") {
      let ta = tdTakeAwayDiscountRange;

      let newTA4 = e.target.value;

      // console.log(ta.range4Min);
      settdTakeAwaydiscountRange((prevState) => ({
        ...prevState,
        range4Min: newTA4
      }));
    }

    if (e.target.name == "tdTakeAwayDiscountRangeMax1") {
      let ta = tdTakeAwayDiscountRange;

      let newTAmax1 = e.target.value;

      // console.log(ta.range4Min);
      settdTakeAwaydiscountRange((prevState) => ({
        ...prevState,
        range1Max: newTAmax1
      }));
    }
    if (e.target.name == "tdTakeAwayDiscountRangeMax2") {
      let ta = tdTakeAwayDiscountRange;

      let newTAmax2 = e.target.value;

      // console.log(ta.range2Min);
      settdTakeAwaydiscountRange((prevState) => ({
        ...prevState,
        range2Max: newTAmax2
      }));
    }
    if (e.target.name == "tdTakeAwayDiscountRangeMax3") {
      let ta = tdTakeAwayDiscountRange;

      let newTAmax3 = e.target.value;

      // console.log(ta.range3Min);
      settdTakeAwaydiscountRange((prevState) => ({
        ...prevState,
        range3Max: newTAmax3
      }));
    }
    if (e.target.name == "tdTakeAwayDiscountRangeMax4") {
      let ta = tdTakeAwayDiscountRange;

      let newTAmax4 = e.target.value;

      // console.log(ta.range4Min);
      settdTakeAwaydiscountRange((prevState) => ({
        ...prevState,
        range4Max: newTAmax4
      }));
    }

    if (e.target.name == "tdTakeAwayDiscountDis1") {
      let ta = tdTakeAwayDiscountRange;

      let newTAdis1 = e.target.value;

      // console.log(ta.discount1);
      settdTakeAwaydiscountRange((prevState) => ({
        ...prevState,
        discount1: newTAdis1
      }));
    }

    if (e.target.name == "tdTakeAwayDiscountDis2") {
      let ta = tdTakeAwayDiscountRange;

      let newTAdis2 = e.target.value;

      // console.log(ta.discount2);
      settdTakeAwaydiscountRange((prevState) => ({
        ...prevState,
        discount2: newTAdis2
      }));
    }
    if (e.target.name == "tdTakeAwayDiscountDis3") {
      let ta = tdTakeAwayDiscountRange;

      let newTAdis3 = e.target.value;

      // console.log(ta.discount3);
      settdTakeAwaydiscountRange((prevState) => ({
        ...prevState,
        discount3: newTAdis3
      }));
    }
    if (e.target.name == "tdTakeAwayDiscountDis4") {
      let ta = tdTakeAwayDiscountRange;

      let newTAdis4 = e.target.value;

      // console.log(ta.discount4);
      settdTakeAwaydiscountRange((prevState) => ({
        ...prevState,
        discount4: newTAdis4
      }));
    }
  };

  /*----------------------------update data api start----------------------------*/
  const [tdDriveOutdiscountupdate, settdDriveOutdiscountupdate] = useState({});
  const [tdTakeAwaydiscountupdate, settdTakeAwaydiscountupdate] = useState({});

  const tdDiscountPermissionUpdate = async (request) => {
    try {
      // console.log(localStorage, "line no 2922222222222222");
      let brid = localStorage.getItem("bid");
      // console.log(brid, "hello")

      let formData = {
        // branchId: "",
        range1Min: tdDriveOutdiscountRange.range1Min,
        range1Max: tdDriveOutdiscountRange.range1Max,
        range2Min: tdDriveOutdiscountRange.range2Min,
        range2Max: tdDriveOutdiscountRange.range2Max,
        range3Min: tdDriveOutdiscountRange.range3Min,
        discount1: tdDriveOutdiscountRange.discount1,
        discount2: tdDriveOutdiscountRange.discount2,
        discount3: tdDriveOutdiscountRange.discount3,

        range1MinTA: tdTakeAwayDiscountRange.range1Min,
        range1MaxTA: tdTakeAwayDiscountRange.range1Max,
        range2MinTA: tdTakeAwayDiscountRange.range2Min,
        range2MaxTA: tdTakeAwayDiscountRange.range2Max,
        range3MinTA: tdTakeAwayDiscountRange.range3Min,
        range3MaxTA: tdTakeAwayDiscountRange.range3Max,
        range4MinTA: tdTakeAwayDiscountRange.range4Min,
        discount1TA: tdTakeAwayDiscountRange.discount1,
        discount2TA: tdTakeAwayDiscountRange.discount2,
        discount3TA: tdTakeAwayDiscountRange.discount3,
        discount4TA: tdTakeAwayDiscountRange.discount4
      };

      const res = await axios
        .put(
          `${appConfig.endpoint}/api/v1/employee/discountModify/tddiscount/${brid}`,
          formData
        )
        .then((response) => {
          if (response.status === 200) {
            notification["success"]({
              message: "TD discount updated successfully"
            });
          }
        });
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) {
          notification["error"]({
            message: "TD discount not updated."
          });
        }
      }
    }
  };

  useEffect(() => {}, [tdDriveOutdiscountupdate], [tdTakeAwaydiscountupdate]);

  /*----------------------------update data api end----------------------------*/

  const handleClick = () => {
    tdDiscountPermissionUpdate();
  };

  /*--------------change input data for update end-------------------*/

  return (
    <Home>
      <div className="report-container text-center">
        <span className="item ">EZYGO Discount</span>
      </div>
      <Card>
        <Card.Body>
          <Row>
            <h5>EZYGO DRIVEOUT DISCOUNT SECTION</h5>
          </Row>
          <>
            <Row>
              <Col>
                {" "}
                <p>Price Range 1 (Min) Kr.</p>
                <Input
                  type="number"
                  name="tdDriveOutdiscountRange1Min"
                  value={tdDriveOutdiscountRange.range1Min}
                  onChange={handleChange}
                />
              </Col>
              <Col>
                {" "}
                <p>Price Range 1 (Max) Kr.</p>
                <Input
                  type="number"
                  name="tdDriveOutdiscountRange1Max"
                  value={tdDriveOutdiscountRange.range1Max}
                  onChange={handleChange}
                />
              </Col>
              <Col>
                {" "}
                <p>Discount 1 (%)</p>
                <Input
                  type="number"
                  name="tdDriveOutdiscountRangeDis"
                  value={tdDriveOutdiscountRange.discount1}
                  onChange={handleChange}
                />
              </Col>
            </Row>

            <Row className="pt-2">
              <Col>
                {" "}
                <p>Price Range 2 (Min) Kr.</p>
                <Input
                  type="number"
                  name="tdDriveOutdiscountRange2Min"
                  value={tdDriveOutdiscountRange.range2Min}
                  onChange={handleChange}
                />
              </Col>
              <Col>
                {" "}
                <p>Price Range 2 (Max) Kr.</p>
                <Input
                  type="number"
                  name="tdDriveOutdiscountRange2Max"
                  value={tdDriveOutdiscountRange.range2Max}
                  onChange={handleChange}
                />
              </Col>
              <Col>
                {" "}
                <p>Discount 2 (%)</p>
                <Input
                  type="number"
                  name="tdDriveOutdiscountRangeDis2"
                  value={tdDriveOutdiscountRange.discount2}
                  onChange={handleChange}
                />
              </Col>
            </Row>

            <Row className="pt-2">
              <Col>
                {" "}
                <p>Price Range 3 (Min) Kr.</p>
                <Input
                  type="number"
                  name="tdDriveOutdiscountRange3Min"
                  value={tdDriveOutdiscountRange.range3Min}
                  onChange={handleChange}
                />
              </Col>
              <Col>
                {" "}
                <p>Price Range 3 (Max) Kr.</p>
                <Input
                  type="number"
                  name="tdDriveOutdiscountRange3Max"
                  value={tdDriveOutdiscountRange.range3Max}
                  onChange={handleChange}
                />
              </Col>
              <Col>
                {" "}
                <p>Discount 3 (%)</p>
                <Input
                  type="number"
                  name="tdDriveOutdiscountRangeDis3"
                  value={tdDriveOutdiscountRange.discount3}
                  onChange={handleChange}
                />
              </Col>
            </Row>

            <Row className="pt-4">
              <h5>EZYGO TAKEAWAY DISCOUNT SECTION</h5>
            </Row>
            <Row>
              <Col>
                {" "}
                <p>Price Range 1 (Min) Kr.</p>
                <Input
                  type="number"
                  name="tdTakeAwayDiscountRangeMin1"
                  value={tdTakeAwayDiscountRange.range1Min}
                  onChange={handleChange}
                />
              </Col>
              <Col>
                {" "}
                <p>Price Range 1 (Max) Kr.</p>
                <Input
                  type="number"
                  name="tdTakeAwayDiscountRangeMax1"
                  value={tdTakeAwayDiscountRange.range1Max}
                  onChange={handleChange}
                  // onChange={(e) => settdDriveOutdiscountRange}
                />
              </Col>
              <Col>
                {" "}
                <p>Discount 1 (%)</p>
                <Input
                  type="number"
                  name="tdTakeAwayDiscountDis1"
                  value={tdTakeAwayDiscountRange.discount1}
                  onChange={handleChange}
                />
              </Col>
            </Row>

            <Row className="pt-2">
              <Col>
                {" "}
                <p>Price Range 2 (Min) Kr.</p>
                <Input
                  type="number"
                  name="tdTakeAwayDiscountRangeMin2"
                  value={tdTakeAwayDiscountRange.range2Min}
                  onChange={handleChange}
                />
              </Col>
              <Col>
                {" "}
                <p>Price Range 2 (Max) Kr.</p>
                <Input
                  type="number"
                  name="tdTakeAwayDiscountRangeMax2"
                  value={tdTakeAwayDiscountRange.range2Max}
                  onChange={handleChange}
                />
              </Col>
              <Col>
                {" "}
                <p>Discount 2 (%)</p>
                <Input
                  type="number"
                  name="tdTakeAwayDiscountDis2"
                  value={tdTakeAwayDiscountRange.discount2}
                  onChange={handleChange}
                />
              </Col>
            </Row>

            <Row className="pt-2">
              <Col>
                {" "}
                <p>Price Range 3 (Min) Kr.</p>
                <Input
                  type="number"
                  name="tdTakeAwayDiscountRangeMin3"
                  value={tdTakeAwayDiscountRange.range3Min}
                  onChange={handleChange}
                />
              </Col>
              <Col>
                {" "}
                <p>Price Range 3 (Max) Kr.</p>
                <Input
                  type="number"
                  name="tdTakeAwayDiscountRangeMax3"
                  value={tdTakeAwayDiscountRange.range3Max}
                  onChange={handleChange}
                />
              </Col>
              <Col>
                {" "}
                <p>Discount 3 (%)</p>
                <Input
                  type="number"
                  name="tdTakeAwayDiscountDis3"
                  value={tdTakeAwayDiscountRange.discount3}
                  onChange={handleChange}
                />
              </Col>
            </Row>
            <Row className="pt-2">
              <Col>
                {" "}
                <p>Price Range 4 (Min) Kr.</p>
                <Input
                  type="number"
                  name="tdTakeAwayDiscountRangeMin4"
                  value={tdTakeAwayDiscountRange.range4Min}
                  onChange={handleChange}
                />
              </Col>
              <Col>
                {" "}
                <p>Price Range 4 (Max) Kr.</p>
                <Input
                  type="number"
                  name="tdTakeAwayDiscountRangeMax4"
                  value={tdTakeAwayDiscountRange.range4Max}
                  onChange={handleChange}
                />
              </Col>
              <Col>
                {" "}
                <p>Discount 4 (%)</p>
                <Input
                  type="number"
                  name="tdTakeAwayDiscountDis4"
                  value={tdTakeAwayDiscountRange.discount4}
                  onChange={handleChange}
                />
              </Col>
            </Row>
          </>
          <Row className="p-3">
            <button
              className="button is-primary btn-ab px-5 rounded py-2"
              onClick={handleClick}
            >
              Update
            </button>
          </Row>
        </Card.Body>
      </Card>
    </Home>
  );
}
