import React, { Component, Fragment } from "react";
//import Home from "../Home/Home";
import Home from "../Home";
import {
  Button,
  Col,
  Row,
  notification,
  DatePicker,
  Select,
  Badge
} from "antd";
import { NavLink } from "react-router-dom";
import "./OpprettKonto.css";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import eatDrink from "../../assets/eatdrink.png";
import steering from "../../assets/steering.png";
import wrapper from "../../assets/wrapper.png";
import appConfig from "../../helpers/appConfig";
import PrintReport from "../../containers/PrintReport/PrintReport";
import SingleOrder from "../../containers/SingleOrder/SingleOrder";
import TableSum from "../../containers/TableSum/TableSum";
import moment from "moment";
import ReportsButton from "../ReportsButton/ReportsButton";
const Option = Select.Option;

class PLUComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: false,
      isLoaded: false,
      currentPage: "PLU",
      cancelModalvisible: false,
      timemodalvisible: false,
      paginationNo: 1,
      items: [],
      orders: 0,
      timeString: "00:00",
      utkjoringCreateLoading: false,
      takeawayLoading: false,
      onlineDate: "",
      onlineStatus: "all",
      fetchApi: true,
      onlineOrderCount: 0
    };

    this.addTableTakeAway = this.addTableTakeAway.bind(this);
  }
  componentDidMount() {
    this.getOnlineOrders(moment().date()._d);
    let hdr = localStorage.token;
    fetch(`${appConfig.endpoint}/api/v1/employee/orders/ordercount`, {
      method: "GET",
      headers: {
        "x-auth-token": hdr,
        "Content-Type": "application/json"
      }
    })
      .then((res) => res.json())
      .then((res) => this.setState({ orders: res.data }))
      .catch((err) => console.log(err));
  }

  openNotification = (type, message) => {
    notification[type]({
      message: message
    });
  };
  onChangetime = (time, timeString) => {
    console.log("this is time", timeString);
    timeString = timeString;
    this.setState({ timeString: timeString });
  };

  createOrder = (str) => {
    this.setState({ orderType: str }, function () {
      this.checkForDriveOut();
    });
  };

  createEatDrink = () => {
    // console.log("create eat and drink")
    this.props.history.push("/tableview");
  };

  reserverTable = () => {
    this.props.history.push("/reservetable");
  };

  checkForDriveOut = () => {
    if (this.state.orderType === "Drive Out") {
      this.getResDetails();
    } else {
      this.addTableTakeAway();
    }
  };
  showtModal = () => {
    this.setState({
      timemodalvisible: true
    });
  };
  handleCancel = () => {
    // console.log(e);
    this.setState({
      timemodalvisible: false,
      cancelModalvisible: false
    });
  };
  showcancelModel = () => {
    this.setState({ cancelModalvisible: true });
  };
  pageChange = (pagination) => {
    if (pagination) {
      this.setState({ paginationNo: pagination.current });
    }
  };

  changeStatus = (type, id, timeString, orderType) => {
    const data = JSON.stringify({
      orderStatus: type,
      id,
      time: this.state.timeString ? this.state.timeString : 0,
      orderType: orderType,
      cancelReason: this.state.cancelReason ? this.state.cancelReason : ""
    });
    let hdr = localStorage.token;
    if (this.state.fetchApi) {
      this.setState({ fetchApi: false });
      fetch(
        `${appConfig.endpoint}/api/v1/employee/orders/onlineinvoicecreation`,
        {
          method: "POST",
          headers: {
            "x-auth-token": hdr,
            "Content-Type": "application/json"
          },
          body: data
        }
      )
        .then((res) => res.json())
        .then((result) => {
          if (result.status === "failure") {
            // this.openNotification('error', result.data);
            this.setState({ fetchApi: true });
          } else {
            //console.log("status", result);
            this.handleCancel();
            if (type === "DELIVERED") {
              this.reduceDeliveredStock(id);
            }
            this.setState({ fetchApi: true });
            this.setState(
              {
                currentPage: "ONLINE ORDERS"
              },
              () => this.getOnlineOrders(this.state.onlineDate)
            );
          }
        })
        .catch((error) => {
          this.setState({
            error,
            fetchApi: true
          });
        });
    }
  };
  reduceDeliveredStock = (id) => {
    var hdr = localStorage.token;
    fetch(`${appConfig.endpoint}/api/v1/bords/reduceDeliveredStock`, {
      method: "POST",
      body: JSON.stringify({ id: id }),
      headers: {
        "x-auth-token": hdr,
        "Content-Type": "application/json"
      }
    })
      .then((res) => res.json())
      .then((result) => {
        if (result.status === "failure") {
          console.log("failed to reduce the ingredients!");
        } else {
          console.log("Successfully reduced the ingredients!");
        }
      });
  };
  getResDetails = () => {
    this.setState({ utkjoringCreateLoading: true });
    var hdr = localStorage.token;
    fetch(`${appConfig.endpoint}/profile`, {
      method: "GET",
      headers: {
        "x-auth-token": hdr
      }
    })
      .then((res) => res.json())
      .then(
        (result) => {
          console.log(result);
          if (result.status === "failure") {
            this.openNotification("error", result.data);
          } else {
            this.setState({ utkjoringCreateLoading: false });
            console.log("dataaa", result.data);
            this.addTableTakeAway("Drive Out", result.data.driveoutCharge);
          }
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      );
  };
  showOrder = (id) => {
    let index = this.state.items.findIndex((item) => item._id === id);
    this.setState({
      currentPage: "order",
      orderData: this.state.items[index]
    });
  };
  goBack = () => {
    this.setState({ currentPage: "PLU" });
  };

  addTableTakeAway = (type, charge) => {
    this.setState({ takeawayLoading: true });
    var hdr = localStorage.token;
    var data;
    if (type === "Drive Out") {
      data = JSON.stringify({
        number: 0,
        orderType: this.state.orderType,
        driveoutCharge: charge /*created: unix time string*/
      });
    } else {
      data = JSON.stringify({
        number: 0,
        orderType: this.state.orderType,
        driveoutCharge: 0 /*created: unix time string*/
      });
    }

    console.log("dataa", data);
    fetch(`${appConfig.endpoint}/api/v1/employee/board/savetable`, {
      method: "POST",
      body: data,
      headers: {
        "x-auth-token": hdr,
        "Content-Type": "application/json"
      }
    })
      .then((res) => res.json())
      .then(
        (result) => {
          if (result.status === "failure") {
            this.openNotification("error", result.data);
          } else {
            console.log("dataaaaa is in plu", result);
            localStorage.setItem("taxID", result.data.taxes.taxId);
            this.setState({ takeawayLoading: false });
            this.openNotification("success", result.message);
            console.log(result);
            localStorage.setItem("table", result.data._id);
            this.props.history.push("/BillingOK");
          }
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      );
  };
  showonlineOrder() {
    this.setState({ currentPage: "ONLINE ORDERS" });
  }
  getOnlineOrders = (date) => {
    this.setState({ onlineDate: date });
    const hdr = localStorage.token;
    const startDate = moment(date).set({
      hour: 4,
      minute: 0,
      second: 0
    });
    const endDate = moment(date).add(1, "days").set({
      hour: 3,
      minute: 59,
      second: 59
    });
    const orderType = this.state.onlineStatus;
    const data = JSON.stringify({
      startDate: startDate,
      endDate: endDate,
      orderType: orderType
    });
    fetch(`${appConfig.endpoint}/api/v1/employee/orders/getOrders`, {
      method: "POST",
      body: data,
      headers: {
        "x-auth-token": hdr,
        "Content-Type": "application/json"
      }
    })
      .then((res) => res.json())
      .then((result) => {
        if (result.status === "failure") {
        } else {
          let items = result.data && result.data.order ? result.data.order : [];
          let onlineTotal =
            result.data && result.data.total[0]
              ? result.data.total[0].totalAmount
              : 0;
          this.setState({
            items,
            onlineTotal,
            isLoaded: true,
            sendProps: true
          });
        }
      });
  };

  render() {
    console.log("Insite Opperteteekonoto");
    const ordersSummaryColumn = [
      {
        title: "Total",
        width: "72%",
        render: () => <strong>Total</strong>
      },
      {
        title: "Total",
        render: () => `Kr.${this.state.onlineTotal}`
      }
    ];
    const summaryData = [
      {
        key: "-1",
        render: () => "test"
      }
    ];

    const ordersColumns = [
      {
        title: "Sr. Nr.",
        key: "orderNumber",
        width: "5%",
        render: (text, record, index) => index + 1
      },
      {
        title: "Ordere tid",
        key: "orderTime",
        width: "18%",
        render: (text, record) =>
          `${new Date(record.createdAt).toLocaleDateString()} - ${new Date(
            record.createdAt
          ).toLocaleTimeString()}`
      },
      {
        title: "Produkt Antall",
        key: "noProducts",
        width: "9%",
        render: (text, record) =>
          record.items.reduce((acc, current) => acc + current.quantity, 0)
      },

      {
        title: "Status",
        key: "status",
        width: "11%",
        render: (text, record) => record.orderStatus
      },
      {
        title: "Ordere Type",
        key: "status",
        width: "10%",
        render: (text, record) => record.orderType
      },
      {
        title: "Total",
        key: "total",
        width: "9%",
        render: (text, record) => {
          if (record.orderType === "Drive Out") {
            return `Kr.${
              record.items.reduce(
                (acc, current) =>
                  acc +
                  current.quantity * current.price +
                  (current.extraPrice ? current.extraPrice : 0),
                0
              ) + record.driveoutCharge
            }`;
          } else {
            return `Kr.${record.items.reduce(
              (acc, current) =>
                acc +
                current.quantity * current.price +
                (current.extraPrice ? current.extraPrice : 0),
              0
            )}`;
          }
        }
      },
      {
        title: "View",
        key: "view",
        width: "10%",
        render: (text, record) => (
          <Button onClick={() => this.showOrder(record._id)}>UTSIKT</Button>
        )
      }
    ];
    const handleOnlineStatus = async (e) => {
      this.setState({ onlineStatus: e });
      setTimeout(() => this.getOnlineOrders(this.state.onlineDate), 200);
    };
    if (this.state.currentPage == "PLU") {
      return (
        <Home>
          <Row className="report-container d-flex justify-content-center align-items-center">
            <span className="item">Opprett konto</span>
          </Row>
          <div className=" test1">
            <Row className="d-flex justify-content-between">
              <Col>
                <Button
                  type="primary"
                  className="btn-link-ok test3"
                  onClick={() => {
                    this.createEatDrink();
                  }}
                >
                  <span>SPISE/ DRIKKE HER</span>
                  <span>PLU 1</span>
                </Button>
              </Col>
              <Col>
                <Button
                  type="primary"
                  className="btn-link-ok test3"
                  onClick={() => {
                    this.createOrder("Take away");
                  }}
                >
                  <span>TA MED</span>
                  <span>PLU 2</span>
                </Button>
              </Col>
              <Col>
                <Button
                  // loading={this.state.utkjoringCreateLoading}
                  className="btn-link-ok"
                  onClick={() => {
                    this.createOrder("Drive Out");
                  }}
                >
                  <span>UTKJØRING</span>
                  <span>PLU 2</span>
                </Button>
              </Col>
            </Row>
            {/* <Row className="d-flex justify-content-between pt-4">
            <Col>
                <Button
                  loading={this.state.utkjoringCreateLoading}
                  className="btn-link-ok"
                  onClick={() => {
                    this.reserverTable();
                  }}
                >
                  <span>RESERVE TABLE</span>
                </Button>
              </Col>
              <Col className=" text-center">
                <NavLink
                  to="/rapporter/online-order"
                  className="btn btn-link"
                  style={{ width: "120px", padding: "30px" }}
                >
                  ONLINE ORDERS
                </NavLink>
                {this.state.onlineOrderCount &&
                Number(this.state.onlineOrderCount) ? (
                  <Badge className="badgeCount" bg="danger">
                    {this.state.onlineOrderCount}
                  </Badge>
                ) : (
                  ""
                )}
              </Col>
            </Row> */}
          </div>
        </Home>
      );
    }
    if (this.state.currentPage === "ONLINE ORDERS") {
      return (
        <Home isAdmin={true}>
          <div className="report-container">
            <span className="item">
              <span id="less-visible">HJEM / ONLINE ORDERE</span>
            </span>

            <span className="item">
              <Select
                style={{ width: 120 }}
                onChange={(e) => handleOnlineStatus(e)}
                value={this.state.onlineStatus}
              >
                <Option key="all" value="all">
                  All
                </Option>
                <Option key="pending" value="pending">
                  Pending
                </Option>
                <Option key="active" value="active">
                  Active
                </Option>
                <Option key="delivered" value="delivered">
                  Delivered
                </Option>
                <Option key="cancelled" value="cancelled">
                  Cancelled
                </Option>
              </Select>

              <DatePicker
                onChange={(date, dateString) => {
                  this.getOnlineOrders(date);
                }}
                format="YYYY-MM-DD"
                placeholder="Select day"
              />
            </span>
          </div>

          <Fragment>
            <TableSum
              loading={this.state.isLoaded ? false : true}
              columns={ordersColumns}
              summaryColumns={ordersSummaryColumn}
              data={this.state.items}
              summaryData={summaryData}
              onChange={(pagination) => this.pageChange(pagination)}
              rowKey={(record) => record.productName}
              bordered
            />
            <div style={{ display: "none" }}>
              {this.state.sendProps && (
                <PrintReport
                  columns={ordersColumns}
                  summaryColumns={ordersSummaryColumn}
                  data={this.state.items}
                  summaryData={summaryData}
                  ref={(el) => (this.componentRef = el)}
                  branch={this.state.branchInfo}
                  totalTaxPrice={this.state.totalTaxPrice}
                  totalSubtotal={this.state.totalSubtotal}
                  totalTax={this.state.totalTax}
                  type="online"
                />
              )}
            </div>
          </Fragment>
        </Home>
      );
    } else {
      return (
        <>
          <Home isAdmin={true}>
            <div className="report-container">
              <span className="item">
                <span id="less-visible">Hjem / </span>
                Order Details
              </span>
              <span className="item">
                <span id="less-visible">
                  <div onClick={this.goBack} className="back-button-border">
                    <i className="fa fa-arrow-circle-left" aria-hidden="true" />
                    <span>Back</span>
                  </div>
                </span>
              </span>
            </div>
            <SingleOrder
              data={this.state.orderData}
              changeStatus={(type, id, timeString, orderType) =>
                this.changeStatus(type, id, timeString, orderType)
              }
              timemodalvisible={this.state.timemodalvisible}
              cancelModalvisible={this.state.cancelModalvisible}
              showcancelModel={() => this.showcancelModel()}
              showtModal={() => this.showtModal()}
              handleCancel={() => this.handleCancel()}
              onChangetime={this.onChangetime}
              timeString={this.state.timeString}
              onChangeCancelReason={() => this.onChangeCancelReason}
              cancelReason={this.state.cancelReason}
            />
          </Home>
        </>
      );
    }
  }
  navigate = (route) => {
    const { dispatch } = this.props;
    dispatch(push(route));
  };
}
const mapStateToProps = (state) => {
  return {};
};
const OpprettKonto = connect(mapStateToProps)(PLUComponent);
export default OpprettKonto;
