import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { info, logout as logoutIcon } from "react-icons-kit/iconic";
import { Icon as RIcon } from "react-icons-kit";

import { Container, Row, Col, Button, Image } from "react-bootstrap";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";

import Accordion from "react-bootstrap/Accordion";
// import Card from 'react-bootstrap/Card';
import Table from "react-bootstrap/Table";

import BootstrapSwitchButton from "bootstrap-switch-button-react";

import { DatePicker } from "antd";

import moment from "moment";

import ReportList from "./../Arkiv/GroupReport/ReportList";

// import { postLogin, emptyErrorMsg, emptySuccessMsg } from "../../action";

import appConfig from "./../../helpers/appConfig";

import Login from "../Login/index";
import { phone } from "react-icons-kit/iconic";

export default function MobileView() {
  const dispatch = useDispatch();

  const [authenticated, setAuthenticated] = useState(false);
  const [loginToken, setLoginToken] = useState("");
  const [validated, setValidated] = useState(false);

  const [currentPage, setCurrentPage] = useState("mobileDash");

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      setAuthenticated(true);
      setLoginToken(token);
    }
  });

  const logOutUser = () => {
    setLoginToken("");
    localStorage.removeItem("token");
    setAuthenticated(false);
  };

  const httpRequest = axios.create({
    //baseURL: `${appConfig.endpoint}/api/v1/employee/menu/supercat`,
    baseURL: `${appConfig.endpoint}`,
    headers: {
      "Content-type": "application/json",
      //"X-Auth-Token": loginToken
    },
  });

  const changeView = useCallback(
    (val) => {
      console.log("---view clicked----", val);

      setCurrentPage(val);
    },
    [setCurrentPage]
  );

  //Show Navigation
  function PageNav({ change }) {

    const logout = async() => {
      console.log("Logout button clicked");
      var data = JSON.stringify({ name: "your_username" });

      await httpRequest({
        // url of the api endpoint (can be changed)
        url: "/logout",
        method: "POST",
        data: data,
      }).then((res) => {
        // handle success
        console.log("---logout response---", res.data.status);
        
        if(res.data.status == 'success'){
          localStorage.removeItem("token");
          localStorage.clear();
          console.log("Logout successful. Redirect to login page.");
          window.location.reload();
        }else {
          console.log("Logout error.");
        }

      });

    };

    return (
      <>
        <Navbar
          collapseOnSelect
          expand="lg"
          className="bg-body-tertiary row mb-4"
        >
          <Container>
            <Navbar.Brand href="#home">A-Board</Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav className="me-auto">
                <Nav.Link href="#" onClick={() => change("mobileDash")}>
                  <h4>Home</h4>
                </Nav.Link>
                <Nav.Link href="#" onClick={() => change("mobileMenu")}>
                  <h4>Products</h4>
                </Nav.Link>
                <Nav.Link href="#" onClick={() => change("mobileReports")}>
                  <h4>Reports</h4>
                </Nav.Link>
                <Nav.Link href="#" onClick={logout}>
                  <h4>Log Out</h4>
                </Nav.Link>

                {/*
              
               <li className="nav-item">
                <button
                  style={{
                    border: "none",
                    height: "inherit",
                    backgroundColor: "transparent"
                  }}
                  className="nav-link"
                  onClick={() => props.logout()}
                >
                  <RIcon size={32} icon={logoutIcon} />
                  <p className="para">Logg Ut</p>
                </button>
              </li> */}
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </>
    );
  }

  //Show Dashboard
  function MobileDash({ change }) {
    return (
      <>
        <Row>
          <Col>
            <h1>Welcome, Admin</h1>
            <Button
              className="btn-block mb-2"
              onClick={() => change("mobileMenu")}
            >
              Food Menu
            </Button>
            <Button
              className="btn-block mb-2"
              onClick={() => change("mobileReports")}
            >
              Reports
            </Button>
          </Col>
        </Row>
      </>
    );
  }

  //Show Restaurant products
  function MobileMenu({ change }) {
    const [category, setCategory] = useState([]);
    const [products, setProducts] = useState([]);
    const [selCategory, setSelCategory] = useState({});
    const [prodImg, setProdImg] = useState("");

    useEffect(() => {
      console.log("---Mobile menu is open--");
      getCategory();
    }, []);

    useEffect(() => {
      console.log("---category---", category);
    }, [category]);

    const getCategory = async () => {
      await httpRequest({
        // url of the api endpoint (can be changed)
        url: "api/v1/employee/menu/category",
        method: "GET",
        //data: data,
      })
        .then((res) => {
          // handle success
          console.log(res.data.data);
          setCategory(res.data.data);
        })
        .catch((err) => {
          console.log("-----error-----", err.response);
          if (err.response.status == 401) {
            console.log("----Logged out-----");
            logOutUser();
          }
        });
    };

    const getProducts = async (catId) => {
      // https://api.a-board.tech/api/v1/employee/products/getProduct/61b05b7477d5f9e0f2c4ce05
      await httpRequest({
        // url of the api endpoint (can be changed)
        url: "api/v1/employee/products/getProduct/" + catId,
        method: "GET",
      })
        .then((res) => {
          // handle success
          console.log(res.data.data);
          setProducts(res.data.data);
          setSelCategory(res.data.data[0].categoryId);
        })
        .catch((err) => {
          console.log("-----error-----", err.response);
          if (err.response.status == 401) {
            console.log("----Logged out-----");
            logOutUser();
          }
        });
    };

    const displayMVA = (item) => {
      let taxes = [];

      item.catTaxes.map((i) => {
        item.superCategory.taxType.map((e) => {
          if (i == e.tax) {
            let t = e.taxName != "vendor" ? "Tax " + e.val : e.taxName;
            taxes.push(t);
          }
        });
      });

      taxes = taxes.join(", ");

      console.log("-----taxes----", taxes);
      return taxes;
    };

    const displayCategories = () => {
      return (
        <>
          <h3>All Categories</h3>
          <Accordion>
            {category.length > 0 &&
              category.map((item, i) => {
                return (
                  <Accordion.Item eventKey={i} key={item._id}>
                    <Accordion.Header>
                      <Col xs={3}>
                        <img
                          src={`${appConfig.s3url}/${item.image}`}
                          style={{ maxWidth: 45, maxHeight: 45 }}
                        />
                      </Col>

                      <Col>
                        <h6>{item.name}</h6>
                      </Col>
                    </Accordion.Header>

                    <Accordion.Body>
                      <Row className="mb-3">
                        <Col>Gruppert Kode:</Col>
                        <Col>{item.superCategory.name}</Col>
                      </Row>

                      <Row className="mb-3">
                        <Col>MVA:</Col>
                        <Col>{displayMVA(item)}</Col>
                      </Row>

                      {/* <Row className="mb-3">
                        <Col>Vis på nettet: </Col>
                        <Col>
                          <BootstrapSwitchButton
                            size="xs"
                            style="btn-ab"
                            onstyle="btn-ab"
                            checkedChildren="Yes"
                            unCheckedChildren="No"
                            checked={item.isShowWeb}
                            //onChange={(e) => onChangeIsWeb(e, record, index)}
                          />
                        </Col>
                      </Row>

                      <Row className="mb-3">
                        <Col>Vis som ekstra: </Col>
                        <Col>
                          <BootstrapSwitchButton
                            size="xs"
                            style="btn-ab"
                            onstyle="btn-ab"
                            checkedChildren="Yes"
                            unCheckedChildren="No"
                            checked={item.isShowAsExtra}
                            //onChange={(e) => onChangeIsWeb(e, record, index)}
                          />
                        </Col>
                      </Row> */}

                      <Row>
                        <Button
                          variant="primary"
                          size="lg"
                          onClick={() => getProducts(item._id)}
                        >
                          View Products
                        </Button>
                      </Row>
                    </Accordion.Body>
                  </Accordion.Item>
                );
              })}
          </Accordion>
        </>
      );
    };

    const handleImage = (e) => {
      console.log("---file----", e.target.files[0]);
      let imgFile = e.target.files[0];
      setProdImg(imgFile);
    };

    const uploadImage = async (itemId) => {
      if (prodImg) {
        console.log("---product---", itemId, prodImg);

        //limit size to 500kb
        if (prodImg.size > 500000) {
          console.log("---file too large---");
          alert("File size too large");
        } else {
          //upload product image to server
          let formData = new FormData();
          formData.append("id", itemId);
          formData.append("image", prodImg, prodImg.name);

          await httpRequest({
            url: "api/v1/employee/products/imageUpdate",
            method: "PUT",
            data: formData,
          })
            .then((res) => {
              // handle success
              console.log(res);
              getProducts(selCategory._id);
            })
            .catch((err) => {
              console.log("-----error-----", err.response);
              if (err.response.status == 401) {
                console.log("----Logged out-----");
                logOutUser();
              }
            });
        }
      }
    };

    const displayProducts = () => {
      return (
        <>
          <Row>
            <Col>
              <h3>Category: {selCategory.name}</h3>
            </Col>
            <Col className="text-right">
              <Button onClick={() => setProducts([])}>Back</Button>
            </Col>
          </Row>
          <Accordion>
            {products.length > 0 &&
              products.map((item, i) => {
                return (
                  <Accordion.Item eventKey={i} key={item._id}>
                    <Accordion.Header>
                      <Col xs={3}>
                        <img
                          src={`${appConfig.s3url}/${item.image}`}
                          style={{ maxWidth: 45, maxHeight: 45 }}
                        />
                      </Col>

                      <Col>
                        <h6>{item.name}</h6>
                      </Col>
                    </Accordion.Header>

                    <Accordion.Body>
                      <Row className="mb-3">
                        <Col>
                          <input
                            onChange={handleImage}
                            name="image-file"
                            type="file"
                            accept=".jpg, .jpeg"
                          ></input>
                        </Col>
                        <Col>
                          <Button onClick={() => uploadImage(item._id)}>
                            Upload
                          </Button>
                        </Col>
                      </Row>

                      <Row className="mb-3">
                        <Col>Produkt Nummer:</Col>
                        <Col>{item.itemNo}</Col>
                      </Row>

                      <Row className="mb-3">
                        <Col>Produkt Pris:</Col>
                        <Col>{item.price}</Col>
                      </Row>

                      {/* <Row className="mb-3">
                        <Col>Tilgjengelighet: </Col>
                        <Col>
                          <BootstrapSwitchButton
                            size="xs"
                            style="btn-ab"
                            onstyle="btn-ab"
                            checkedChildren="Yes"
                            unCheckedChildren="No"
                            checked={item.isShowTable}
                            //onChange={(e) => onChangeIsWeb(e, record, index)}
                          />
                        </Col>
                      </Row>

                      <Row className="mb-3">
                        <Col>Spiciness: </Col>
                        <Col>
                          <BootstrapSwitchButton
                            size="xs"
                            style="btn-ab"
                            onstyle="btn-ab"
                            checkedChildren="Yes"
                            unCheckedChildren="No"
                            checked={item.isExtraInformation}
                            //onChange={(e) => onChangeIsWeb(e, record, index)}
                          />
                        </Col>
                      </Row> */}
                    </Accordion.Body>
                  </Accordion.Item>
                );
              })}
          </Accordion>
        </>
      );
    };

    return (
      <>
        {products.length > 0 ? (
          <>{displayProducts()}</>
        ) : (
          <>{displayCategories()}</>
        )}
      </>
    );
  }

  //Show Z Report
  function MobileZReport({ change }) {
    const { MonthPicker } = DatePicker;
    const [loaded, setLoaded] = useState(false);
    const [groupReportData, setGroupReportData] = useState({});
    const [finalTotal, setFinalTotal] = useState(0);

    useEffect(() => {
      handleDateFilter(moment(new Date()), "d");
    }, []);

    const calcTotal = (groupReportData) => {
      console.log(
        groupReportData.totalBank,
        groupReportData.totalCash,
        groupReportData.onlineVippsTotal,
        groupReportData.onlineTotal,
        groupReportData.totalCredit,
        groupReportData.totalTip,
        groupReportData.treeDriveTotal,
        groupReportData.treeDriveVippsTotal,
        groupReportData.totalCoupan,
        groupReportData.restCouponAmt
      );

      let restCouponAmt = groupReportData.couponData.totalCouponValue
        ? groupReportData.couponData.totalCouponValue
        : 0;
      let finalTotal = 0;
      finalTotal =
        Number(groupReportData.totalBank) +
        Number(groupReportData.totalCash) +
        Number(groupReportData.onlineVippsTotal) +
        Number(groupReportData.onlineTotal) +
        Number(groupReportData.totalCredit) -
        Number(groupReportData.totalTip) +
        Number(groupReportData.treeDriveTotal) +
        Number(groupReportData.treeDriveVippsTotal) +
        Number(groupReportData.OrcTotal) +
        Number(groupReportData.totalCoupan) -
        Number(restCouponAmt);

      setFinalTotal(finalTotal);

      console.log(
        "---final total is:---",
        groupReportData.OrcTotal,
        finalTotal
      );
    };

    const getGroupReport = async (postData) => {
      console.log("---postdata for group report---", postData);

      setLoaded(false);

      await httpRequest({
        // url of the api endpoint (can be changed)
        url: "api/v1/employee/arkiv/group-report",
        method: "POST",
        data: postData,
      }).then((res) => {
        // handle success
        console.log("---group report response---", res.data.data);
        setGroupReportData(res.data.data);
        calcTotal(res.data.data);
        setLoaded(true);
      });
    };

    const handleDateFilter = (date, type) => {
      let postdata = {};

      switch (type) {
        case "m":
          postdata = {
            startDate: moment(date).startOf("month").add(1, "days"),
            endDate: moment(date).endOf("month").add(1, "days"),
          };
          break;
        case "d":
          postdata = {
            startDate: moment(date),
            endDate: moment(date).add(1, "days"),
          };
      }

      getGroupReport(postdata);
    };

    return (
      <>
        <h3 className="text-center">Z Report</h3>
        <Row className="mb-2 p-2">
          <Col>
            <DatePicker onChange={(e) => handleDateFilter(e, "d")} />
          </Col>
          <Col>
            <MonthPicker
              placeholder="Select Month"
              onChange={(e) => handleDateFilter(e, "m")}
            />
          </Col>
        </Row>

        {loaded ? (
          <>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Type</th>
                  <th>Amount</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <strong>Kontant</strong>
                  </td>
                  <td>${Number(groupReportData.totalCash).toFixed(2)}</td>
                </tr>
                <tr>
                  <td>
                    <strong>EZYGO Card</strong>
                  </td>
                  <td>${Number(groupReportData.OrcTotal).toFixed(2)}</td>
                </tr>
                <tr>
                  <td>
                    <strong>Card</strong>
                  </td>
                  <td>
                    $
                    {Number(groupReportData.totalBank) +
                      Number(groupReportData.treeDriveTotal) +
                      Number(groupReportData.onlineTotal)}
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>Vipps</strong>
                  </td>
                  <td>
                    {localStorage.getItem("vippsPermission") == "true"
                      ? (
                          Number(groupReportData.onlineVippsTotal) +
                          Number(groupReportData.treeDriveVippsTotal)
                        ).toFixed(2)
                      : "$0.00"}
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>Coupon</strong>
                  </td>
                  <td>
                    {localStorage.getItem("couponPermission") == "true" ? (
                      <>${groupReportData.totalCoupan}</>
                    ) : (
                      "$0.00"
                    )}
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>Kredit</strong>
                  </td>
                  <td>${groupReportData.totalCredit}</td>
                </tr>
                <tr>
                  <td>
                    <strong>Kontant Box Rapport</strong>
                  </td>
                  <td>${groupReportData.totalBoxOpen}</td>
                </tr>
                <tr className="bg-warning">
                  <th colSpan={2} className="text-center text-white">
                    Total: ${finalTotal.toFixed(2)}
                  </th>
                </tr>
              </tbody>
            </Table>

            <ReportList report={groupReportData.report} />

            <h3 className="mt-3">Utkjoring</h3>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Orders</th>
                  <th>Utkjoring MVA</th>
                  <th>Utkjoring Pris</th>
                  <th>Total</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{groupReportData.driveOutCharges.count}</td>
                  <td>{groupReportData.driveOutCharges.driveoutTax}</td>
                  <td>{groupReportData.driveOutCharges.driveoutCharge}</td>
                  <td>{groupReportData.driveOutCharges.totalDcharge}</td>
                </tr>
              </tbody>
            </Table>

            <h3 className="mt-3">ANSATTE TIP RAPPORT</h3>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Navn</th>
                  <th>Email</th>
                  <th>Cash</th>
                  <th>Bank</th>
                  <th>Total</th>
                </tr>
              </thead>
              <tbody>
                {groupReportData.tips.length > 0 &&
                  groupReportData.tips.map((tip) => {
                    return (
                      <tr>
                        <td>{tip.firstName}</td>
                        <td>{tip.email}</td>
                        <td>{tip.cash}</td>
                        <td>{tip.bank}</td>
                        <td>{tip.total}</td>
                      </tr>
                    );
                  })}

                <tr className="bg-warning">
                  <th colSpan={5} className="text-center text-white">
                    Total: ${Number(groupReportData.totalTip).toFixed(2)}
                  </th>
                </tr>
              </tbody>
            </Table>
          </>
        ) : (
          <>
            <p className="text-center">Please wait....</p>
          </>
        )}
      </>
    );
  }

  //Show X Report
  function MobileXReport({ change }) {
    const { MonthPicker } = DatePicker;
    const [loaded, setLoaded] = useState(false);
    const [groupReportData, setGroupReportData] = useState({});
    const [finalTotal, setFinalTotal] = useState(0);

    useEffect(() => {
      getGroupReport();
    }, []);

    const getGroupReport = async () => {
      //let postData = {};
      let date = new Date();
      // let date = new Date('12/05/2023');
      let postData = {
        startDate: moment(date),
        endDate: moment(date).add(1, "days"),
      };

      console.log("---postdata for x report---", postData);

      setLoaded(false);

      await httpRequest({
        // url of the api endpoint (can be changed)
        ///https://api.a-board.tech/api/v1/employee/xreport/report
        url: "api/v1/employee/xreport/report",
        method: "POST",
        data: postData,
      }).then((res) => {
        // handle success
        console.log("---group report response---", res.data.data);
        setGroupReportData(res.data.data);
        calcTotal(res.data);
        setLoaded(true);
      });
    };

    const calcTotal = (result) => {
      let unitPriceTotal = result.data.reportData.reduce(
        (acc, record, index) => {
          return (
            acc +
            (record.total -
              (record.taxObj.reduce((sum, obj) => {
                return sum + obj.taxPrice;
              }, 0) -
                (record.extraPrice ? record.extraPrice : 0)))
          );
        },
        0
      );
      let finalTotal = result.data.reportData.reduce((acc, current, index) => {
        return acc + current.total;
      }, 0);

      let totalDiscount = result.data.reportData.reduce(
        (acc, current, index) => {
          return acc + current.discountPrice;
        },
        0
      );
      let totalExtra = result.data.reportData.reduce((acc, current, index) => {
        if (current.extraPrice) {
          return acc + current.extraPrice;
        }
        return acc;
      }, 0);

      let totalPriceAntall = (
        unitPriceTotal +
        parseInt(result.data.tatalOrdersPrice * 100) / 125
      ).toFixed(2);
      console.log(
        "--total calc---",
        unitPriceTotal,
        totalPriceAntall,
        finalTotal,
        totalDiscount,
        totalExtra
      );
      // setUnitPriceTotal(unitPriceTotal);
      // setFinalTotal(finalTotal);
      // setTotalDiscount(totalDiscount);
      // setTotalExtra(totalExtra);
    };

    return (
      <>
        <h3 className="text-center">X Report</h3>

        {loaded ? (
          <>
            <h3>Utkjøring</h3>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Total Ordre</th>
                  <th>Utkjøring Mva</th>
                  <th>Utkjøring Pris</th>
                  <th>Total Utkjøring Pris</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{groupReportData.dcount}</td>
                  <td>
                    Kr.{" "}
                    {(
                      parseInt(groupReportData.tatalOrdersPrice) -
                      (parseInt(groupReportData.tatalOrdersPrice) * 100) /
                        (100 + groupReportData.dOT)
                    ).toFixed(2)}
                  </td>

                  <td>
                    Kr.{" "}
                    {(
                      (groupReportData.tatalOrdersPrice * 100) /
                      (100 + groupReportData.dOT)
                    ).toFixed(2)}
                  </td>

                  <td>{groupReportData.tatalOrdersPrice}</td>
                </tr>
              </tbody>
            </Table>

            <h3 className="mt-3">Bestillingene</h3>
            <Accordion>
              {groupReportData.reportData.length > 0 &&
                groupReportData.reportData.map((item, i) => {
                  let enhetPrice =
                    item.total -
                    item.taxObj.reduce((total, row) => {
                      return total + row.taxPrice;
                    }, 0);

                  return (
                    <Accordion.Item eventKey={i} key={item._id}>
                      <Accordion.Header>
                        <Col>
                          <h6>{item.productName}</h6>
                        </Col>
                      </Accordion.Header>

                      <Accordion.Body>
                        <Row className="mb-3">
                          <Col>MVA</Col>
                          <Col>
                            {item.taxObj.map((tax) => {
                              return (
                                <div>
                                  {tax.tax}% = Kr.{tax.taxPrice.toFixed(2)}{" "}
                                </div>
                              );
                            })}
                          </Col>
                        </Row>

                        <Row className="mb-3">
                          <Col>Enhet Price:</Col>
                          <Col>Kr.{enhetPrice.toFixed(2)}</Col>
                        </Row>

                        <Row className="mb-3">
                          <Col>Antall</Col>
                          <Col>
                            {item.taxObj.map((tax) => {
                              return (
                                <div>
                                  {tax.tax}% = {tax.unitOfTax}{" "}
                                </div>
                              );
                            })}
                          </Col>
                        </Row>

                        <Row className="mb-3">
                          <Col>Discount:</Col>
                          <Col>Kr.{item.discountPrice}</Col>
                        </Row>
                        <Row className="mb-3">
                          <Col>Ekstra varepris:</Col>
                          <Col>Kr.{item.extraPrice}</Col>
                        </Row>
                        <Row className="mb-3">
                          <Col>Total:</Col>
                          <Col>Kr.{item.total.toFixed(2)}</Col>
                        </Row>
                      </Accordion.Body>
                    </Accordion.Item>
                  );
                })}
            </Accordion>
          </>
        ) : (
          <>
            <p className="text-center">Please wait....</p>
          </>
        )}
      </>
    );
  }

  //show daily reports
  function DailyReports({ change }) {
    const { MonthPicker } = DatePicker;

    const [loaded, setLoaded] = useState(false);
    const [invoiceData, setInvoiceData] = useState([]);
    const [singleItem, setSingleItem] = useState({});
    const [selectedDate, setSelectedDate] = useState(moment(new Date()));

    useEffect(() => {
      handleDateFilter(moment(new Date()), "d");
    }, []);

    // useEffect(() => {
    //   getDailyReports();
    // }, [selectedDate]);

    const getDailyReports = async (postData) => {
      // const startDate = moment(selectedDate).startOf("day");
      // const endDate = moment(selectedDate).endOf("day");
      await axios
        .post(
          "https://api.a-board.tech/api/v1/employee/invoice/daily-reports",
          // {
          //   startDate,
          //   endDate,
          // }
          postData
        )

        .then((res) => {
          console.log(
            "--------------res-data----------------------",
            res.data.data.invoiceData
          );
          setInvoiceData(res.data.data.invoiceData);
        })
        .catch((err) => {
          console.log("err", err);
        });
    };

    const handleDateFilter = (date, type) => {
      let postdata = {};

      switch (type) {
        case "m":
          postdata = {
            startDate: moment(date).startOf("month").add(1, "days"),
            endDate: moment(date).endOf("month").add(1, "days"),
          };
          break;
        case "d":
          postdata = {
            startDate: moment(date),
            endDate: moment(date).add(1, "days"),
          };
      }

      getDailyReports(postdata);
    };

    const showDailyReportLists = () => {
      return (
        <>
          <div className="pb-4">
            <DatePicker onChange={(e) => handleDateFilter(e, "d")} />
          </div>
          <Table striped bordered hover>
            {/* Table Header */}
            <thead>
              <tr>
                <th>Invoice No.</th>
                <th>Date</th>
                {/* <th>Order From</th> */}
                <th>Order Type</th>
                <th>Total</th>
                <th>Action</th>
              </tr>
            </thead>
            {/* Table Body */}
            <tbody>
              {invoiceData.map((invoiceItem) => (
                <>
                  {console.log("iten", invoiceItem)}
                  <tr key={invoiceItem.invoiceNo}>
                    <td>{invoiceItem.invoiceNumber}</td>
                    <td>
                      {new Date(invoiceItem.created).toLocaleDateString(
                        "en-US"
                      )}
                    </td>
                    {/* <td>{invoiceItem.orderFrom}</td> */}
                    <td>{invoiceItem.orderType}</td>
                    <td>{invoiceItem.totalPrice}</td>
                    <td>
                      {
                        <button
                          style={{ borderWidth: "0" }}
                          onClick={() => setSingleItem(invoiceItem)}
                        >
                          View
                        </button>
                      }
                    </td>
                  </tr>
                </>
              ))}
            </tbody>
          </Table>
        </>
      );
    };

    const showReportInfo = (item) => {
      const storedAddress = localStorage.getItem("branchInfo");
      console.log("---------storeAddress------------", storedAddress);
      const branchInfo = JSON.parse(storedAddress);
      console.log("-------------branch-------------------", branchInfo);
      const {
        name,
        address,
        taxNo,
        phoneNo,
        bEmail,
        accountNumber,
        orderType,
      } = branchInfo;
      console.log("-------------branchInfo-----------", name);
      console.log("----------address----------", address);
      console.log("--------------email------------------", bEmail);

      return (
        <>
          <Button onClick={() => setSingleItem({})}>Go Back</Button>
          <div className="d-flex align-items-center justify-content-between pt-4">
            <div className="col">
              {/* <p>{address}</p> */}
              <p>
                {address.line1 ? address.line1 : ""}
                {address.line2 ? " " + address.line2 : ""}
              </p>
              <p>
                {address.city ? address.city : ""}
                {address.pin ? "," + " " + address.pin : ""}
                {address.state ? "," + " " + address.state : ""}
              </p>
              <p>Mva Nr.:{taxNo}</p>
              <p>Telefon Nr.{phoneNo}</p>
              <p>
                {" "}
                <span style={{ fontWeight: "800" }}>E-Post:</span> {bEmail}
              </p>
            </div>
            <div className="col">
              <p>
                <span style={{ fontWeight: "800" }}>Faktura Nr:</span>{" "}
                {singleItem.invoiceNumber}
              </p>
              <p>
                <span style={{ fontWeight: "800" }}>Date:</span>{" "}
                {new Date(singleItem.created).toLocaleDateString("en-US")}
              </p>
              <p>
                <span style={{ fontWeight: "800" }}>Tid:</span>{" "}
                {singleItem.tableTime}
              </p>
              <p>
                <span style={{ fontWeight: "800" }}>Order Type:</span>{" "}
                {singleItem.orderType}
              </p>
            </div>
          </div>
          
          <Table striped bordered hover>
            {/* Table Header */}
            <thead>
              <tr>
                <th>Sr Nr.</th>
                <th>Navn</th>
                <th>QTY</th>
                <th>Pris</th>
                <th>Ekstra vare Pris</th>
                <th>Beløp</th>
              </tr>
            </thead>
            <tbody>
              {singleItem.items.map((item, itemIndex) => (
                <tr key={`${item.productName}-${itemIndex}`}>
                  <td>{itemIndex + 1}</td>
                  <td>{item.productName}</td>
                  <td>{item.quantity}</td>
                  <td>{item.productPrice}</td>
                  <td>{item.price}</td>
                  <td>{item.extraPrice}</td>
                </tr>
              ))}
            </tbody>
          </Table>
          <div className="d-flex justify-content-end align-items-end flex-column">
            {console.log("single item", singleItem)}
            <p className="mobileview-P">
              <span style={{ fontWeight: "700" }}>Sub-Totalt:</span>{" "}
              {`Kr. ${
                singleItem.isAppOrder
                  ? (singleItem.taxPriceInc / 1.15).toFixed(2)
                  : (
                      singleItem.taxPrice -
                      (
                        singleItem.taxPrice -
                        Number(100 * singleItem.taxPrice) /
                          Number(100 + singleItem.tax)
                      ).toFixed(3)
                    ).toFixed(2)
              }`}
            </p>
            <p className="mobileview-P">
              <span style={{ fontWeight: 700 }}>Mva</span>(
              {`${singleItem.tax.toFixed(2)}%`}
              ):{" "}
              {`Kr. ${
                singleItem.isAppOrder
                  ? singleItem
                      .taxPriceInc(singleItem.taxPriceInc / 1.15)
                      .toFixed(2)
                      .toFixed(2)
                  : (
                      singleItem.taxPrice -
                      (100 * singleItem.taxPrice) / (100 + singleItem.tax)
                    ).toFixed(2)
              }`}
            </p>
            <p className="mobileview-P">
              <span style={{ fontWeight: 700 }}>
                Rabatt ({`${singleItem.tdDiscountPickUp}%`}) :
              </span>
              <span>
                {`- Kr. ${Number(
                  singleItem.taxPrice * (singleItem.tdDiscountPickUp / 100)
                ).toFixed(2)}`}
              </span>
            </p>
            <p style={{ marginBottom: 4 }}>
              <span style={{ fontWeight: "700" }}>CASH:</span> Kr.
              {parseFloat(singleItem.cash).toFixed(2)}
            </p>
            <p style={{ marginBottom: 4 }}>
              <span style={{ fontWeight: "700" }}> BANK:</span> Kr.
              {parseFloat(singleItem.cash).toFixed(2)}
            </p>
            <p className="mobileview-P">
              <span style={{ fontWeight: 700 }}>Totalt:</span>{" "}
              {`Kr.${parseFloat(singleItem.totalPrice).toFixed(2)}`}
            </p>
            <div className="d-flex justify-content-end align-items-end flex-column pt-2">
              {singleItem.transaction && singleItem.transaction.card ? (
                <>
                  <Col align="right" className="row-font">
                    <Row align="text-center">
                      <Col>
                        <h4 style={{ fontWeight: "900" }}>Card Details</h4>
                      </Col>
                    </Row>
                    <p style={{ marginBottom: 4 }}>
                      <span style={{ fontWeight: "700" }}> Card:</span>
                      {singleItem.transaction.card.masked_pan}
                    </p>
                    <p style={{ marginBottom: 4 }}>
                      <span style={{ fontWeight: "700" }}> Card Type: </span>
                      {singleItem.transaction.card.brand}
                    </p>
                    <p style={{ marginBottom: 4 }}>
                      <span style={{ fontWeight: "700" }}> Expiry Date:</span>{" "}
                      {singleItem.transaction.card.expiry_date}
                    </p>
                  </Col>
                </>
              ) : (
                ""
              )}
            </div>
          </div>
        </>
      );
    };

    return (
      <>
        <h3 className="text-center">Daily Report</h3>
        {singleItem._id ? (
          <>{showReportInfo()}</>
        ) : (
          <>{showDailyReportLists()}</>
        )}
      </>
    );
  }

  //show reports component
  function MobileReports({ change }) {
    return (
      <>
        <h1>Reports</h1>
        <div className="d-grid gap-2">
          <Button
            variant="primary"
            size="lg"
            onClick={() => change("mobileZReport")}
          >
            Z-Report
          </Button>

          {/* <Button
            variant="primary"
            size="lg"
            onClick={() => change("mobileXReport")}

          >
            X-Report
          </Button> */}
          <Button
            variant="primary"
            size="lg"
            onClick={() => change("dailyReports")}
          >
            Daily-Report
          </Button>
        </div>
      </>
    );
  }

  function Page() {
    switch (currentPage) {
      case "mobileDash":
        return <MobileDash change={changeView} />;

      case "mobileMenu":
        return <MobileMenu change={changeView} />;

      case "mobileReports":
        return <MobileReports change={changeView} />;

      case "mobileZReport":
        return <MobileZReport change={changeView} />;

      case "mobileXReport":
        return <MobileXReport change={changeView} />;

      case "dailyReports":
        return <DailyReports change={changeView} />;

      default:
        return <MobileDash change={changeView} />;
    }
  }

  return (
    <Container className="bg-light p-3">
      {authenticated ? (
        <>
          <PageNav change={changeView} />
          <Page />
        </>
      ) : (
        <Login mobile="true" />
      )}
    </Container>
  );
}
