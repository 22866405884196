import React, { useEffect, useState } from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import Home from "../../Home";
import appConfig from "../../../helpers/appConfig";
import { POSTAPI } from "../../Vendor/AllApi";
import moment from "moment";
import { Table, Select, DatePicker } from "antd";
import TableSumX from "../../Table/TableSumX";
import history from "../../../history";

const { MonthPicker } = DatePicker;

const RefundReport = (props) => {
  const [dailyData, setDailyData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [onlineDate, setOnlineDate] = useState("");
  const bid = localStorage.getItem("bid");

  const getDailyRecord = async (data) => {
    try {
      let resData = await POSTAPI(
        `${appConfig.endpoint}/api/v1/employee/invoice/getRefundOrder`,
        data
      );
      setDailyData(resData.data);
      setLoading(false);
      console.log("====refund orders===", resData.data);
    } catch (error) {
      console.log(error);
    }
  };

  const printbill = () => {
    window.print();
  };

  useEffect(() => {
    setLoading(true);
    filterData(moment(new Date()), "d");
  }, []);

  const filterData = (date, type) => {
    let postdata = {};

    switch (type) {
      case "m":
        postdata = {
          startDate: moment(date).startOf("month").add(1, "days"),
          endDate: moment(date).endOf("month").add(1, "days"),
          branchId: bid,
        };
        break;
      case "d":
        postdata = {
          startDate: moment(date),
          endDate: moment(date).add(1, "days"),
          branchId: bid,
        };
        break;
      case "w":
        postdata = {
          startDate: moment(date).startOf("isoWeek"),
          endDate: moment(date).startOf("isoWeek").add(7, "days"),
          branchId: bid,
        };
        break;
      default:
        break;
    }
    getDailyRecord(postdata);
    console.log(postdata);
  };

  const goBackMainPage = () => {
    props.history.push("/arkiv");
  };

  
  const ReportSummaryData = [
    {
        key: "-1",
        render: () => "test"
    }
];

  const columns = () => [
    {
      title: "Refusjonsfakturanummer",
      key: "invoiceNumber",
      width: "10%",
      render: (text, record) => record.invoiceNumber,
    },
    {
      title: "Refusjonsdato og klokkeslett",
      key: "orderTime",
      width: "18%",
      render: (text, record) =>
        `${moment(record.created).format("DD/MM/YYYY")} - ${new Date(
          record.created
        ).toLocaleTimeString()}`,
    },
    {
      title: "Ordrefakturanummer",
      key: "invoiceNumber",
      width: "10%",
      render: (text, record) => record.oldInvoiceNumber,
    },
    {
      title: "Ordre dato og klokkeslett",
      key: "orderTime",
      width: "18%",
      render: (text, record) =>
        `${moment(record.oldInvoiceCreated).format("DD/MM/YYYY")} - ${new Date(
          record.oldInvoiceCreated
        ).toLocaleTimeString()}`,
    },
    {
      title: "Kundens e-post",
      key: "noProducts",
      width: "9%",
      render: (text, record) => record.customerEmail,
    },
    {
      title: "Kundetelefon",
      key: "noProducts",
      width: "9%",
      render: (text, record) => record.customerPhone,
    },
    {
      title: "Bestilling refundert av",
      key: "noProducts",
      width: "9%",
      render: (text, record) => record.userEmail,
    },
    {
      title: "Refusjonsbeløp",
      key: "total",
      width: "9%",
      render: (text, record) => {
        return `Kr.${record.refundAmt.toFixed(2)}`;
      },
    },
  ];

  const ReportSummary = (totalBillPrice) => [
    {
      title: "Invoice Number",
      key: "invoiceNumber",
      width: "80%",
      render: () => <strong>Total</strong>,
    },
    {
      title: "Total",
      key: "total",
      render: () => `Kr.${totalBillPrice.toFixed(2)}`,
    },
  ];

  let totalBillPrice = 0;
  dailyData.map((e) => {
    totalBillPrice += e.refundAmt;
  });

  return (
    <Home>
      <>
        <Row>
          <Col>
            <div className="report-container">
              <span className="item" style={{ width: "60%" }}>
                <span id="less-visible">Arkiv : </span>
                REFUSJON RAPPORT
              </span>
              <span id="less-visible">
                <Button
                  onClick={() => goBackMainPage()}
                  className="ant-btn back-btn"
                >
                  <i className="fa fa-arrow-circle-left" aria-hidden="true" />
                </Button>
              </span>
            </div>
            <div className="d-flex">
                <DatePicker onChange={(e) => filterData(e, "d")} />
                <MonthPicker
                  placeholder="Select Month"
                  onChange={(e) => filterData(e, "m")}
                />
            </div>
           <div className="mt-2">
           <TableSumX
                  id="tablePrint"
                  loading={loading}
                  columns={columns()}
                  data={dailyData}
                  summaryData={ReportSummaryData}
                  summaryColumns={ReportSummary(totalBillPrice)}
                  striped
                  bordered
                  rowKey={props.rowKey}
                />
           </div>
          </Col>
        </Row>
      </>
    </Home>
  );
};

export default RefundReport;
