import React, { useState, useEffect } from 'react'
import { DatePicker , Button } from 'antd';
import { Chart } from "react-google-charts";
import { POSTAPI } from '../../Vendor/AllApi';
import appConfig from '../../../helpers/appConfig';
import { Empty } from 'antd';
import moment from 'moment';
import { Card } from "react-bootstrap";
const bid = localStorage.getItem("bid");
const token = localStorage.getItem("token");
const { MonthPicker, RangePicker, WeekPicker } = DatePicker;

const MonthIncomeGraph = () => {
    const [data, setData] = useState([]);
  const [sDate , setSDate ] = useState("");
  const [eDate , setEDate ] = useState("");
  const [currDate , setCurrDate ] = useState("");
  const [selectedMonth , setSelectedMonth ] = useState([]);
  const [selectedMode , setSelectedMode] = useState(['month', 'month']);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  const [time, setTime] = useState(null);
  const [time2, setTime2] = useState(null);
  const [graphDate, setGraphDate] = useState("");


  const [lineChartData, setLineChartData] = useState([]);

  useEffect(() => {
    //setLoading(true);
    // filterData(moment(new Date()), "d");
    console.log('----data array-----', lineChartData);
  }, [lineChartData]);


  useEffect(()=>{
    let date = new Date();
    let startDate = moment(date).startOf("year").add(1, "days")._d;
    let endDate =moment(date).endOf("month").add(1, "days")._d;
    let branchId = bid;
    let data = {};
    data.startDate = startDate;
    data.endDate = endDate;
    data.branchId = bid;
    data.type = "month";

    incomeByDaysData(data);

    console.log("intial data =====", startDate , endDate , branchId);

  },[]);


  const incomeByDaysData = async (postdata) => {
    const headers = {
      "x-auth-token": token,
    };
    try {
      let resData = await POSTAPI(
        `${appConfig.endpoint}/api/v1/employee/invoice/IncomeAmountDashboard`,
        postdata,
        {
          headers: headers,
        }
        
      );
      if(resData.data){
        // setData(resData.data);
        // console.log(Object.keys(resData.data).length);

        if(resData.data.length > 0){
          let d = resData.data;

          let dataArray = [["Periode", "Salg (NOK)"]];


      let b = "";

          
          d.map((i)=>{
            // switch(i._id.month){
            //   case 1: b = "January";
            //       break;
            //   case 2: b = "February";
            //       break;
            //   case 3: b = "March";
            //       break;
            //   case 4: b = "April";
            //       break;
            //   case 5: b = "May";
            //       break;
            //   case 6: b = "June"; 
            //       break;
            //   case 7: b = "July";
            //       break;
            //   case 8: b = "August";
            //       break;
            //   case 9: b = "September";
            //       break;
            //   case 10: b = "October";
            //       break;
            //   case 11: b = "November";
            //       break;
            //   case 12: b = "December";
            //       break;
            //   }
            switch(i._id.month){
              case 1: b = "Jan";
                  break;
              case 2: b = "Feb";
                  break;
              case 3: b = "Mar";
                  break;
              case 4: b = "Apr";
                  break;
              case 5: b = "May";
                  break;
              case 6: b = "June"; 
                  break;
              case 7: b = "July";
                  break;
              case 8: b = "Aug";
                  break;
              case 9: b = "Sep";
                  break;
              case 10: b = "Oct";
                  break;
              case 11: b = "Nov";
                  break;
              case 12: b = "Dec";
                  break;
              }

            let t = [];
            let thisDate = b;
            let totalAmount = i.total;

            t = [thisDate, totalAmount];
            dataArray.push(t);

          })

          setLineChartData(dataArray);
          setGraphDate(resData.BeDate);
          setIsOpen(false);

        }else{
          setLineChartData([]);
          setGraphDate("");
        }

      }else{
        setData([]);
        setLineChartData([]);
      }

    } catch (error) {
      setData([]);
      setLineChartData([]);
      setGraphDate("");
      console.log(error);
    }
  };

function filterData(start, end) {
  //setSelectedMonth(date);
  // console.log("selected month ",date,dateString)
 let startDate = start;
 let endDate = end;
  console.log( startDate,endDate);

  let payload = {};
  payload.startDate = startDate;
  payload.endDate = endDate;
  payload.branchId = bid;
  payload.type = "month";


  incomeByDaysData(payload)
}

const handlePanelChange =  (value, mode) => {
  // setIsOpen(true);
  console.log('---Panel change---', value, mode)
  let startDate = moment(value[0])._d;
  let endDate =  moment(value[1])._d;
  console.log(startDate, endDate)
  let m = [mode[0] === 'date' ? 'month' : mode[0], mode[1] === 'date' ? 'month' : mode[1]];
  setSelectedMonth(value);
  setSelectedMode(m);
  filterData(startDate , endDate)
};

const handleMonthChange = (value)=>{

  console.log("abc")
  console.log('----month change value---', value);
}

// console.log("data", data)
function onChange2(date, dateString) {
  console.log(date, dateString);
  setTime(null);
}

function onChange3(date, dateString) {
  console.log(date, dateString);
  setTime2(null);
}

  
function handleDone(){
  let payload = {};
      payload.startDate = sDate;
      payload.endDate = eDate;
      payload.branchId = bid;
      payload.type = "month";
    
      incomeByDaysData(payload)


}

const options = {
//  title: "Month Wise Income",
  curveType: "function",
  legend: { position: "top right" },
  hAxis: {
    title: "Periode",
  },
  vAxis: {
    title: "Salg (NOK)",
  },
  animation: {
    startup: true,
    duration: 2000,
    easing: "out",
  },
  // colors: ['#07182c'],

};
  return (
    <>
    <Card className='mb-4'>
      <Card.Header className="h4">Sammenligningsdiagram for salgsresultater : Månedsmessig</Card.Header>
      </Card>
    <div className="d-flex m-3 justify-content-center">
    {/* <RangePicker
        placeholder={['Start month', 'End month']}
        format="MM"
        value={selectedMonth}
        mode={selectedMode}
        //onChange={filterData}
        onChange={handleMonthChange}
        onPanelChange={handlePanelChange}
        // allowClear={true}
        // open = {isOpen}
      /> */}
      <DatePicker
      className="mr-3"
        value={time}
        open={isOpen}
        mode="month"
        placeholder="Start Month"
        format="MMMM"
        onOpenChange={status => {
          if (status) {
            setIsOpen(true);
          } else {
            setIsOpen(false);
          }
        }}
        onPanelChange={v => {
          setSDate(moment(v).startOf("month").add(1, "days")._d);
          console.log("start",v);
          setIsOpen(false);
          setTime(v);
        }}
        onChange={onChange2}
      />
        <DatePicker
        className="mr-3"
        value={time2}
        open={isOpen2}
        mode="month"
        placeholder="End Month"
        format="MMMM"
        onOpenChange={status => {
          if (status) {
            setIsOpen2(true);
          } else {
            setIsOpen2(false);
          }
        }}
        onPanelChange={v => {
          //moment(v).startOf("month").add(1, "days")._d
          //setEDate(moment(v._d));
          setEDate(moment(v).endOf("month").add(1, "days")._d);
          console.log("end",v);
          setIsOpen2(false);
          setTime2(v);
        }}
        onChange={onChange3}
      />
       <Button
                key="back"
                className="btn btn-ab"
                style={{
                    height:"32px",
                    width:"120px"
                  }}
                onClick={() => handleDone()}
              >
                Done
      </Button>
   <br />
   </div>
   <div>
   {
     lineChartData.length == 0 ?
     <div className="d-flex justify-content-center pt-5">
     <Empty /> 
     </div>
     :
    <>
   
     <Chart
     chartType="LineChart"
     width="100%"
     height="400px"
     data={lineChartData}
     options={options}
   />
    <div className="d-flex justify-content-center">
              <span className="heading2"> {moment(graphDate.startDate).format('MMM DD, YYYY')} 04:00 am to {moment(graphDate.endDate).format('MMM DD, YYYY')} 03:59 am</span>

              </div>

    </>
   }

   </div>
  </>
  )
}

export default MonthIncomeGraph
