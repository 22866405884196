import React, { useEffect, useState } from "react";
import {
  getDailyReport,
  getDailyReportPrint,
  getDailyXReport
} from "./../../../action/ReportAction";
import { connect } from "react-redux";
import { Button, Card, Col, Row } from "react-bootstrap";
import Home from "../../Home";
import List from "../../Arkiv/PosReport/List";
import ViewReport from "./ViewReport";
import { setDefaultDates } from "../../../helpers/commonDates";
import moment from "moment";

const DailyXReport = (props) => {
  let [page, setPage] = useState("list");
  let [date, setDate] = useState(moment().date()._d);
  let [isLoaded, setIsLoaded] = useState(false);
  useEffect(() => {
    const time = moment(date).format("HH");
    if (Number(time) < 4) {
      date = moment(date).subtract(1, "days");
    }
    async function fetchData(tSDay, tEDay) {
      const data = {
        startDate: tSDay,
        endDate: tEDay
      };
      setIsLoaded(true);
      await props.getDailyXReport(data).then(() => setIsLoaded(false));
    }
    const { tSDay, tEDay } = setDefaultDates(date);
    fetchData(tSDay, tEDay);
  }, [date]);
  const handleDateChange = (date) => {
    setDate(date);
  };
  const printReportHandler = async () => {
    const { tSDay, tEDay } = await setDefaultDates(date);
    const data = {
      startDate: tSDay,
      endDate: tEDay,
      title: "X-REPORTS",
      type: "X"
    };
    setIsLoaded(true);
    await props.getDailyReportPrint(data).then(() => setIsLoaded(false));
  };
  const goBackMainPage = () => {
    props.history.push("/rapporter");
  };
  const renderPage = () => {
    if (page === "list") {
      return (
        <Home>
          <div className="report-container">
            <span className="item" style={{ width: "60%" }}>
              <span id="less-visible">Rapporter/ </span>X Rapport
            </span>
            <span id="less-visible">
              <Button
                onClick={() => goBackMainPage()}
                className="ant-btn back-btn"
              >
                <i className="fa fa-arrow-circle-left" aria-hidden="true" />
              </Button>
            </span>
          </div>
          <Card>
            <Card.Body>
              <Row className="py-3">
                <Col className="d-flex align-items-center">
                  <h4 className="mb-0"> Today's Report</h4>
                </Col>
                <Col className="text-right">
                  <Button
                    className="btn btn-ab"
                    onClick={() => printReportHandler()}
                  >
                    <i
                      className="fa fa-print"
                      style={{ fontSize: 20, marginTop: 1 }}
                      aria-hidden="true"
                    />
                  </Button>
                </Col>
              </Row>
              <List
                setPage={setPage}
                isLoaded={isLoaded}
                dailyXReport={props.dailyXReport}
              />
            </Card.Body>
          </Card>
        </Home>
      );
    } else if (page === "view") {
      return <ViewReport setPage={setPage} />;
    } else {
      return <List />;
    }
  };

  return <>{renderPage()}</>;
};
const mapStateToProps = ({ dailyXReport }) => {
  return {
    dailyXReport
  };
};
export default connect(mapStateToProps, {
  getDailyReport,
  getDailyReportPrint,
  getDailyXReport
})(DailyXReport);
