import React, { useState, useEffect } from "react";
import moment from "moment";
import Countdown from "react-countdown";
import { Button, Card, Container } from "react-bootstrap";
import {
  Table,
  DatePicker,
  Row,
  Col,
  Radio,
  Modal,
  notification,
  Icon,
} from "antd";
import { Alert } from "antd";
import "./orders.css";
import appConfig from "../../../helpers/appConfig";
import { GETAllAPI, POSTAPI } from "../AllApi";
import history from "../../../history";
//import Modal from 'react-bootstrap/Modal';
import "./orders.css";

const { MonthPicker, RangePicker, WeekPicker } = DatePicker;

const { Column } = Table;
const { Meta } = Card;

const SingleOrder = (props) => {
  let { setSingleOrder, statusChanged, setStatusChanged } = props;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [onlineDate, setOnlineDate] = useState("");
  const [dataSource, setDataSource] = useState();
  const [confirmShow, setConfirmShow] = useState(false);
  const [confirmCancel, setConfirmCancel] = useState(false);
  const [cancel, setCancel] = useState("");
  const [onlineStatus, setOnlineStatus] = useState("all");
  const [inputDate, setInputDate] = useState();

  const printbill = async () => {
    window.print();
  };
  const bid = localStorage.getItem("bid");
  const token = localStorage.getItem("token");

  const handleClose = () => {
    setConfirmShow(false);
  };

  const handleShow = () => {
    setConfirmShow(true);
  };

  const handleClosed = () => {
    setConfirmCancel(false);
  };

  const handleCancel = () => {
    setConfirmCancel(true);
  };

  const headers = {
    "x-auth-token": token,
  };
//console.log(" address======", props.orderData.customerDetail.address.length > 1 )
  let IncompeleteOrder = async (data) => {
    let resData = await POSTAPI(
      `${appConfig.endpoint}/api/v1/vendor/orders/confirmVendorOrder`,
      data,
      {
        headers: headers,
      }
    );

    if (resData.status == 200) {
      if (props.orderData.orderStatus == "PENDING" && !cancel) {
        notification.open({
          message: "Order confirmed successfully",
          icon: <Icon type="check-circle" style={{ color: "green" }} />,
          duration: 2,
        });
      } else if (props.orderData.orderStatus == "IN-PROCESS" && !cancel) {
        notification.open({
          message: "Invoice generated and order delivered",
          icon: <Icon type="check-circle" style={{ color: "green" }} />,
          duration: 2,
        });
      } else if (cancel) {
        notification.open({
          message: "Order cancelled successfully",
          icon: <Icon type="close-circle" style={{ color: "red" }} />,
          duration: 2,
        });
      } else {
        console.log("error");
      }
      history.push("/vendor-vendors");
      setSingleOrder(false);
      setStatusChanged(!statusChanged);
    }
  };

  // console.log("=====value from getvendor orders======")

  const changeOrderStatus = (orderStatus) => {
    setConfirmShow(false);
    // sending  payload
    let postData = {
      //branchId:bid,
      orderId: props.orderData._id,
      orderStatus: orderStatus,
      deliveryTime: inputDate,
      cancelReason: cancel,
    };
    console.log("post data ----", postData);
    IncompeleteOrder(postData);
  };

  const handleReason = (val) => {
    setCancel(val);
  };

  const cancelReason = () => {
    // console.log("submit")
    if (!cancel) {
      notification.open({
        message: "Please select cancel reason",
        icon: <Icon type="close-circle" style={{ color: "red" }} />,
        duration: 2,
      });
    } else {
      changeOrderStatus("CANCEL");
    }
  };

  const selectDate = (date) => {
    let dd = moment(date).format();

    // let m, d, y;

    // let dd = new Date(date);
    // m = dd.getMonth()
    // d = dd.getDate();
    // y = dd.getFullYear();

    // dd = d+"/"+m+"/"+y;
    // console.log("======date =====", dd, date._d)

    console.log("---moment date---", dd);

    setInputDate(dd);
    if (dd) {
      setDisabled(false);
    }
  };

  let orderArr = [];
  orderArr.push(props.orderData);
  //console.log("array=====>",orderArr)
  useEffect(() => {
    console.log("itemmmm");
  }, [itemArr]);
  let itemArr = props.orderData.items;
  let totalQuantity = 0;
  let totalPrice = 0;
  itemArr.map((e) => {
    totalQuantity += e.quantity;
    totalPrice += e.totalPrice;
  });
  //console.log("total quantity====",totalQuantity)

  let tdServiceCharge = props.orderData.tdServiceCharge;
  const DriveOutColumns = (totalQuantity, totalPrice) => [
    {
      title: "Total Unit Price",
      render: () => {
        return `Kr.${totalPrice.toFixed(2)}`;
      },
    },
    {
      title: `Service Charge`,
      render: (record, text) => record.tdServiceCharge.toFixed(2),
    },
    {
      title:"Drive Out Charges",
      render:(record , text) => record.driveoutCharge.toFixed(2)
    },
    {
      title: "Total quantity",
      render: () => {
        return `${totalQuantity}`;
      },
    },
    {
      title: "Total",
      render: (text, record) => {
        return `Kr.${record.totalPrice.toFixed(2)}`;
      },
    },
  ];

  let CustomerName = props.orderData.customerDetail.name;
  let CustomerPhone = props.orderData.customerDetail.phoneNo;
  let CustomerEmail = props.orderData.customerDetail.email;
  let Address = props.orderData.customerDetail.address;
  let userEmail= props.orderData.customerDetail.userEmail;
  let customerPhone= props.orderData.customerDetail.customerPhone;
console.log("length=======" , Address.length)
console.log("length=======" , userEmail.length)
console.log("length=======" , customerPhone.length)

  return (
    <Container fluid className="p-0">
    <Row
        className="justify-content-between bg-white p-0"
        id="displayrow">
      <Col className="p-2 ">
      <div className="d-flex justify-content-between">
      <div className="justify-content-between" id="tablePrint">
      <>
      <p id="tablePrint">
      <p className="orderType">Order Type : {props.orderData.orderType}</p>
            <strong>OTP: {props.orderData.otp}</strong>
          </p>
            <p>Name: {CustomerName}</p>
            <p> Phone No.: {CustomerPhone}</p>
            <p> Email: {CustomerEmail} </p>
            {Address.length > 1 ?
            <p>Address: {Address}</p>
            : ""}
            {
              userEmail.length ? 
              <p>User Email : {userEmail}</p> : ""
            }
            {
              customerPhone.length ? 
              <p>User Phone : {customerPhone}</p>
              : ""
            }
            {props.orderData.orderStatus == "CANCEL" ? (
            <span id="tablePrint">
              <p>
                Order Status:{" "}
                <b style={{ color: "red", fontWeight: "bold" }}>CANCELLED</b>
              </p>
              <p style={{ color: "red", fontSize: "16px" }}>
                <b>Cancel Reason : </b>
                {props.orderData.cancelReason}
              </p>
            </span>
          ) : (
            ""
          )}
          {props.orderData.orderStatus == "PENDING" && (
            <>
              <div className="d-flex">
                <Button
                  onClick={() => handleShow()}
                  style={{
                    background: "transparent",
                    border: "1px solid black",
                    color: "black",
                    marginRight: "15px",
                    marginBottom: "20px",
                  }}
                >
                  Confirm
                </Button>
                <Modal
                  open={isModalOpen}
                  onCancel={handleCancel}
                  title="Select Date"
                  visible={confirmShow}
                  footer={[
                    <Button
                      onClick={(e) => changeOrderStatus("IN-PROCESS")}
                      disabled={disabled}
                    >
                      Submit
                    </Button>,
                    <Button
                      key="back"
                      className="btn btn-secondary"
                      onClick={() => handleClose()}
                    >
                      Cancel
                    </Button>,
                  ]}
                  closable={true}
                >
                  <DatePicker
                    onChange={(date, dateString) => {
                      selectDate(date);
                    }}
                    format="YYYY-MM-DD"
                    placeholder="Select day"
                  />
                </Modal>

                <Button
                  onClick={handleCancel}
                  style={{
                    background: "transparent",
                    border: "1px solid black",
                    color: "black",
                    marginBottom: "20px",
                  }}
                >
                  Cancel
                </Button>
                <Modal
                  title="Select Reason for canceling order"
                  visible={confirmCancel}
                  footer={[
                    <Button
                      // disabled={cancel?false:true}
                      onClick={(e) => cancelReason()}
                    >
                      Submit
                    </Button>,
                    <Button
                      key="back"
                      className="btn btn-secondary"
                      onClick={() => handleClosed()}
                    >
                      Cancel
                    </Button>,
                  ]}
                  closable={false}
                >
                  <div style={{ textAlign: "center", alignSelf: "center" }}>
                    <Radio.Group className="selected">
                      <Radio.Button
                        value="Item Not Available"
                        onClick={(e) => handleReason(e.target.value)}
                      >
                        Items Not present
                      </Radio.Button>
                      <Radio.Button
                        value="No One to deliver"
                        onClick={(e) => handleReason(e.target.value)}
                      >
                        No One to deliver
                      </Radio.Button>
                      <Radio.Button
                        value="Restaurant closed"
                        onClick={(e) => handleReason(e.target.value)}
                      >
                        Restaurant Closed
                      </Radio.Button>
                      <Radio.Button
                        value=" Not accepting orders"
                        onClick={(e) => handleReason(e.target.value)}
                      >
                        Not accepting orders
                      </Radio.Button>
                    </Radio.Group>
                    <p className="text-center text-danger"></p>
                  </div>
                </Modal>
              </div>
            </>
          )}

          {props.orderData.orderStatus == "IN-PROCESS" && (
            <div>
              <Button
                onClick={(e) => changeOrderStatus("DELIVERED")}
                style={{
                  background: "orange",
                  border: "1px solid black",
                  color: "black",
                  marginRight: "15px",
                    marginBottom: "20px",
                }}
              >
                Handover
              </Button>

              <Button
                onClick={handleCancel}
                style={{
                  background: "transparent",
                  border: "1px solid black",
                  color: "black",
                  marginBottom: "20px",
                }}
              >
                Cancel
              </Button>
              <Modal
                title="Select Reason for canceling order"
                visible={confirmCancel}
                footer={[
                  <Button
                    // disabled={cancel?false:true}
                    onClick={(e) => cancelReason()}
                  >
                    Submit
                  </Button>,
                  <Button
                    key="back"
                    className="btn btn-secondary"
                    onClick={() => handleClosed()}
                  >
                    Cancel
                  </Button>,
                ]}
                closable={false}
              >
                <div style={{ textAlign: "center", alignSelf: "center" }}>
                  <Radio.Group>
                    <Radio.Button
                      value="Item Not Available"
                      onClick={(e) => handleReason(e.target.value)}
                    >
                      Items Not present
                    </Radio.Button>
                    <Radio.Button
                      value="No One to deliver"
                      onClick={(e) => handleReason(e.target.value)}
                    >
                      No One to deliver
                    </Radio.Button>
                    <Radio.Button
                      value="Restaurant closed"
                      onClick={(e) => handleReason(e.target.value)}
                    >
                      Restaurant Closed
                    </Radio.Button>
                    <Radio.Button
                      value=" Not accepting orders"
                      onClick={(e) => handleReason(e.target.value)}
                    >
                      Not accepting orders
                    </Radio.Button>
                  </Radio.Group>
                  <p className="text-center text-danger"></p>
                </div>
              </Modal>
            </div>
          )}
      </>
        </div>
            <div className="justify-content-end mycontent-right"  style={{textAlign:"right"}} id="tablePrint">
            <Button
            onClick={() => printbill()}
            type="primary"
            size="large"
            className="btn-ab  text-color"
            >
            Print
            </Button>
        
            <>
              <h5 className="pt-2">Instruksjoner av kunden</h5>
              {props.orderData.items.map((e) => {
                return (
                  <p key={e._id}>
                    For {e.quantity} {e.name}
                    <br />
                  </p>
                );
              })}
            </>
          </div>
          </div>
          <div className="d-flex flex-column">
          <Table
              id="tablePrint"
              bordered
              dataSource={props.orderData.items}
              pagination={false}
              style={{ borderBlockStartColor: "red" }}
            >
              <Column title="Product Name" dataIndex="name" key="name" />
              <Column title="Item No." dataIndex="itemNo" key="itemNo" />
              <Column
                title="Unit Price"
                //dataIndex="price"
                // render={(text) => `Kr.${Number(text*100/(orderData.tdExtraPriceIncrease+100)).toFixed(2)}`}
                render={(text, render) =>
                  `Kr.${Number(render.price).toFixed(2)}`
                }
                key="price"
              />
              <Column title="Quantity"  key="quantity"
               render={(text, render) =>
                  `${render.quantity}${render.unitType} X ${render.availQuantity}`
                }
               />
              {/* <Column title="Discount" 
               render={(text, render) =>
                `Kr.${Number(render.discount).toFixed(2)}`
                }
               /> */}

              <Column
                title="Total Price"
                dataIndex="totalPrice"
                render={(text, record) =>
                  `Kr.${Number(record.totalPrice).toFixed(2)}`
                }
                key="totalPrice"
              />
            </Table>
            <Table
              style={{ marginTop: "20px" }}
              pagination={false}
              id="tablePrint"
              //loading={loading}
              columns={DriveOutColumns(totalQuantity, totalPrice)}
              dataSource={orderArr}
              striped
              bordered
            />

          </div>
          
          </Col>
        </Row>

    </Container>
    
  );
};

export default SingleOrder;
