import React, { Component } from "react";
import Home from "../Home/Home";
import "./restaurantProfile.css";
import { connect } from "react-redux";
import Modal from "react-modal";
import moment from "moment";
import { Icon as RIcon } from "react-icons-kit";
import {check} from 'react-icons-kit/fa/check'
import {close} from 'react-icons-kit/fa/close'
import {chevronRight} from 'react-icons-kit/fa/chevronRight'
import {checkCircle} from 'react-icons-kit/fa/checkCircle'
import appConfig from "../../helpers/appConfig";
import {chevronLeft} from 'react-icons-kit/fa/chevronLeft'
import Loader from "../Loader/Loader";
import EPToolkit from "escpos-printer-toolkit";
import {
  message,
  notification,
  Switch,
  TimePicker,
  Button,
  Collapse,
  Alert,
  label,
  Divider
} from "antd";
const { Panel } = Collapse;

const format = "HH:mm";
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)"
  }
};
const options = {
  beep: true,
  cut: true,
  tailingLine: true
};

const props = {
  name: "file",
  action: "//jsonplaceholder.typicode.com/posts/",
  headers: {
    authorization: "authorization-text"
  },
  onChange(info) {
    if (info.file.status !== "uploading") {
      console.log(info.file, info.fileList);
    }
    if (info.file.status === "done") {
      message.success(`${info.file.name} file uploaded successfully`);
    } else if (info.file.status === "error") {
      message.error(`${info.file.name} file upload failed.`);
    }
  }
};

class resProfileComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modalIsOpen: false,
      error: false,
      isLoaded: false,
      isEditable: false,
      items: [],
      name: "",
      email: "",
      website: "",
      taccountantEmail: "",
      phoneNo: "",
      taxNo: "",
      description: "",
      line1: "",
      line2: "",
      city: "",
      pin: "",
      state: "",
      country: "",
      currentPage: "",
      logo: "",
      accountNumber: "",
      resId: "",
      monday: false,
      tuesday: false,
      wednesday: false,
      thursday: false,
      friday: false,
      saturday: false,
      sunday: false,
      mondayTime: "",
      tuesdayTime: "",
      wednesdayTime: "",
      thursdayTime: "",
      fridayTime: "",
      saturdayTime: "",
      sundayTime: "",
      mondayEndTime: "",
      tuesdayEndTime: "",
      wednesdayEndTime: "",
      thursdayEndTime: "",
      fridayEndTime: "",
      saturdayEndTime: "",
      sundayEndTime: "",

      weekTime: "",
      weekEndTime: "",
      weekday: "",
      availabilityStatus: ""
    };

    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.setProfile = this.setProfile.bind(this);
    this.toggleweekday = this.toggleweekday.bind(this);
  }
  callback(key) {
    console.log(key);
  }
  changeavailability(checked) {
    // alert(checked);
    let postData = {
      status: checked
    };
    this.setState({ availabilityStatus: checked });
    let hdr = localStorage.token;
    // alert(checked);
    fetch(`${appConfig.endpoint}/changeavailability`, {
      method: "POST",
      body: JSON.stringify(postData),
      headers: {
        "x-auth-token": hdr,
        "Content-Type": "application/json"
      }
    })
      .then((res) => res.json())
      .then(
        (result) => {
          console.log(result);
          if (result.status === "failure") {
            this.openNotification("error", result.data);
          } else {
            this.openNotification("success", "Online Availibility Updated");
            console.log("status updated successfully", result);
          }
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      );
    // console.log(`switch to ${checked}`);
  }
  printercheck() {
    alert("getting");
    EPToolkit.exchange_text("<C>hello printer is working</C>", options);
  }

  setProfile = () => {
    // e.preventDefault();
    let website = this.state.website;
    const regex = /^https{0,1}:\/\//i;
    if (regex.test(website)) {
      website = website.toLowerCase();
    } else {
      website = `http://${website}`;
    }
    var data = JSON.stringify({
      name: this.state.name,
      phoneNo: this.state.phoneNo,
      taxNo: this.state.taxNo,
      description: this.state.description,
      email: this.state.email,
      line1: this.state.line1,
      line2: this.state.line2,
      city: this.state.city,
      pin: this.state.pin,
      state: this.state.state,
      country: this.state.country,
      website,
      taccountantEmail: this.state.taccountantEmail,
      bid: localStorage.bid
    });
    var hdr = localStorage.token;
    fetch(`${appConfig.endpoint}/profile`, {
      method: "PUT",
      body: data,
      headers: {
        "x-auth-token": hdr,
        "Content-Type": "application/json"
      }
    })
      .then((res) => res.json())
      .then(
        (result) => {
          console.log(result);
          if (result.status === "failure") {
            this.openNotification("error", result.data);
          } else {
            this.getResProfile();
            this.setState({ currentPage: "" });
          }
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      );
  };

  openNotification = (type, message) => {
    notification[type]({
      message: message
    });
  };

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
    console.log(this.state);
  };

  copy = () => {
    const textarea = document.getElementById("menu-button-code");
    textarea.select();
    document.execCommand("copy");
  };

  handleLogoChange = (e) => {
    console.log(e.target.files[0]);
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("logo", file);
    var hdr = localStorage.token;
    console.log(formData);
    fetch(`${appConfig.endpoint}/profile/logo`, {
      method: "POST",
      body: formData,
      headers: {
        "x-auth-token": hdr
      }
    })
      .then((res) => res.json())
      .then(
        (result) => {
          if (result.status === "failure") {
            this.openNotification("error", result.data);
          } else {
            localStorage.setItem("logo", result.data);
            this.getResProfile();
            this.setState({ currentPage: "edit" });
          }
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      );
  };

  openModal() {
    this.setState({ modalIsOpen: true });
  }

  closeModal() {
    this.setState({ modalIsOpen: false });
  }

  componentDidMount = () => {
    this.getResProfile();
  };

  getResProfile = () => {
    var hdr = localStorage.token;
    fetch(`${appConfig.endpoint}/profile`, {
      method: "GET",
      headers: {
        "x-auth-token": hdr
      }
    })
      .then((res) => res.json())
      .then(
        (result) => {
          console.log(result);
          if (result.status === "failure") {
            this.openNotification("error", result.data);
          } else {
            console.log("RESULT DATA", result.data);
            this.setState({
              isLoaded: true,
              items: result.status === "failure" ? [] : result.data,
              name: result.data.name,
              phoneNo: result.data.phoneNo,
              email: result.data.email,
              availabilityStatus: result.data.availability.status,
              taxNo: result.data.taxNo,
              description: result.data.description,
              line1: result.data.address.line1,
              line2: result.data.address.line2,
              city: result.data.address.city,
              pin: result.data.address.pin,
              state: result.data.address.state,
              country: result.data.address.country,
              isEditable: result.data.isEditable,
              logo: result.data.logo,
              accountNumber: result.data.accountNumber,
              website: result.data.website,
              taccountantEmail: result.data.taccountantEmail,
              resId: result.data._id,
              monday: result.data.monday.status,
              mondayTime: result.data.monday.openingTime
                ? result.data.monday.openingTime
                : "12:00",
              mondayEndTime: result.data.monday.closingTime
                ? result.data.monday.closingTime
                : "12:00",
              tuesday: result.data.tuesday.status,
              tuesdayTime: result.data.tuesday.openingTime
                ? result.data.tuesday.openingTime
                : "12:00",
              tuesdayEndTime: result.data.tuesday.closingTime
                ? result.data.tuesday.closingTime
                : "12:00",
              wednesday: result.data.wednesday.status,
              wednesdayTime: result.data.wednesday.openingTime
                ? result.data.wednesday.openingTime
                : "12:00",
              wednesdayEndTime: result.data.wednesday.closingTime
                ? result.data.wednesday.closingTime
                : "12:00",
              thursday: result.data.thursday.status,
              thursdayTime: result.data.thursday.openingTime
                ? result.data.thursday.openingTime
                : "12:00",
              thursdayEndTime: result.data.thursday.closingTime
                ? result.data.thursday.closingTime
                : "12:00",
              friday: result.data.friday.status,
              fridayTime: result.data.friday.openingTime
                ? result.data.friday.openingTime
                : "12:00",
              fridayEndTime: result.data.friday.closingTime
                ? result.data.friday.closingTime
                : "12:00",
              saturday: result.data.saturday.status,
              saturdayTime: result.data.saturday.openingTime
                ? result.data.saturday.openingTime
                : "12:00",
              saturdayEndTime: result.data.saturday.closingTime
                ? result.data.saturday.closingTime
                : "12:00",
              sunday: result.data.sunday.status,
              sundayTime: result.data.sunday.openingTime
                ? result.data.sunday.openingTime
                : "12:00",
              sundayEndTime: result.data.sunday.closingTime
                ? result.data.sunday.closingTime
                : "12:00"
            });
            localStorage.setItem("website", result.data.website);
          }
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      );
  };

  openNotification = (type, message) => {
    notification[type]({
      message: message
    });
  };

  editResProfile = () => {
    this.setState({ currentPage: "edit" });
  };
  toggleweekday = (weekday, value) => {
    switch (weekday) {
      case "monday":
        this.setState({ monday: value });
        break;
      case "tuesday":
        this.setState({ tuesday: value });
        break;
      case "wednesday":
        this.setState({ wednesday: value });
        break;
      case "thursday":
        this.setState({ thursday: value });
        break;
      case "friday":
        this.setState({ friday: value });
        break;
      case "saturday":
        this.setState({ saturday: value });
        break;
      case "sunday":
        this.setState({ sunday: value });
        break;
    }

    let obj = {
      weekday: weekday,
      status: value,
      branchId: localStorage.bid
    };
    var hdr = localStorage.token;
    fetch(`${appConfig.endpoint}/api/v1/employee/information/updateDay`, {
      method: "POST",
      headers: {
        "x-auth-token": hdr,
        "Content-Type": "application/json"
      },
      body: JSON.stringify(obj)
    })
      .then((res) => res.json())
      .then((result) => {
        if (result.status === "failure") {
        } else {
          this.openNotification("success", "Weekday Successfully Updated!");
        }
      });
  };
  toggleweekdayTime(time, timeString, weekday) {
    console.log("weekday", weekday);
    console.log("time", time);
    console.log("timeString", timeString);
    this.setState({ weekTime: timeString, weekday: weekday });
  }
  toggleweekdayEndTime(time, timeString, weekday) {
    console.log("weekday", weekday);
    console.log("time", time);
    console.log("timeString", timeString);
    this.setState({ weekEndTime: timeString, weekday: weekday });
  }
  postweek(weekday, weekStatus) {
    let postData = {
      openingTime: this.state.weekTime,
      closingTime: this.state.weekEndTime,
      weekday: weekday,
      weekStatus: weekStatus
    };
    console.log("postData", postData);
    var hdr = localStorage.token;
    fetch(`${appConfig.endpoint}/updateweekavailability`, {
      method: "POST",
      body: JSON.stringify(postData),
      headers: {
        "x-auth-token": hdr,
        "Content-Type": "application/json"
      }
    })
      .then((res) => res.json())
      .then((result) => {
        if (result.status === "failure") {
          this.openNotification("error", result.data);
        } else {
          this.getResProfile();
          this.openNotification("success", "Online Availibility Updated");
          this.setState({ currentPage: "" });
          console.log("success you found", result);
        }
      });
  }
  render() {
    const {
      error,
      isLoaded,
      items,
      currentPage,
      name,
      email,
      taxNo,
      phoneNo,
      line1,
      line2,
      city,
      pin,
      state,
      country,
      description,
      isEditable,
      imageUrl,
      logo,
      accountNumber,
      website,
      taccountantEmail
    } = this.state;
    console.log(this.state);
    if (!isLoaded) {
      return (
        <Home>
          <Loader />
        </Home>
      );
    } else if (error) {
      return <div>Error: {error.message}</div>;
    } else if (items.length === 0 || currentPage === "edit") {
      return (
        <Home>
          <div className="res">
            <form onSubmit={this.setProfile} className="form1 add-restaurant">
              <Button
                style={{ right: 0 }}
                onClick={() => this.setState({ currentPage: "" })}
                type="button"
              >
                <RIcon Icon= {chevronLeft} /> Back
              </Button>
              <li>
                <center>
                  <h2>Legg Til Restaurant</h2>
                </center>
              </li>
              <li>
                {" "}
                <p style={{ textAlign: "left" }}>Online Uke Tilgjengelighet</p>
              </li>
              <li>
                <h3 align="left">Monday</h3>
                <span>
                  <Switch
                    style={{ minWidth: "100%" }}
                    checkedChildren={<RIcon Icon= {check} />}
                    unCheckedChildren={<RIcon Icon= {close} />}
                    onChange={(monday) => {
                      this.toggleweekday("monday", monday);
                    }}
                    checked={this.state.monday}
                  />
                  <br />

                  <TimePicker
                    style={{ marginLeft: 30, marginRight: 10 }}
                    disabled={!this.state.monday}
                    defaultValue={moment(this.state.mondayTime, format)}
                    format={format}
                    onChange={(time, timeString) =>
                      this.toggleweekdayTime(time, timeString, "monday")
                    }
                  />
                  <TimePicker
                    disabled={!this.state.monday}
                    defaultValue={moment(this.state.mondayEndTime, format)}
                    format={format}
                    onChange={(time, timeString) =>
                      this.toggleweekdayEndTime(time, timeString, "monday")
                    }
                  />
                  <Button
                    style={{ marginLeft: 10 }}
                    disabled={!this.state.monday}
                    disabled={
                      !this.state.monday && this.state.mondayTime == "00:00"
                    }
                    onClick={() => this.postweek("monday", this.state.monday)}
                  >
                    <RIcon Icon={chevronRight}   ></RIcon>
                  </Button>
                </span>
              </li>
              <li>
                <h3 align="left">Tuesday</h3>
                <span>
                  <Switch
                    style={{ minWidth: "100%" }}
                    checkedChildren={<RIcon Icon= {check} />}
                    unCheckedChildren={<RIcon Icon= {close} />}
                    checked={this.state.tuesday}
                    onChange={(tuesday) =>
                      this.toggleweekday("tuesday", tuesday)
                    }
                  />
                  <br />

                  <TimePicker
                    disabled={!this.state.tuesday}
                    defaultValue={moment(this.state.tuesdayTime, format)}
                    format={format}
                    onChange={(time, timeString) =>
                      this.toggleweekdayTime(time, timeString, "tuesday")
                    }
                  />
                  <TimePicker
                    style={{ marginLeft: 30, marginRight: 10 }}
                    disabled={!this.state.tuesday}
                    defaultValue={moment(this.state.tuesdayEndTime, format)}
                    format={format}
                    onChange={(time, timeString) =>
                      this.toggleweekdayEndTime(time, timeString, "tuesday")
                    }
                  />
                  <Button
                    style={{ marginLeft: 10 }}
                    disabled={!this.state.tuesday}
                    disabled={
                      !this.state.tuesday && this.state.tuesdayTime == "00:00"
                    }
                    onClick={() => this.postweek("tuesday", this.state.tuesday)}
                  >
                    <RIcon Icon={chevronRight} ></RIcon>
                  </Button>
                </span>
              </li>
              <li>
                <h3 align="left">Wednesday</h3>
                <span>
                  <Switch
                    style={{ minWidth: "100%" }}
                    checkedChildren={<RIcon Icon= {check} />}
                    unCheckedChildren={<RIcon Icon= {close} />}
                    checked={this.state.wednesday}
                    onChange={(wednesday) =>
                      this.toggleweekday("wednesday", wednesday)
                    }
                  />
                  <br />
                  <TimePicker
                    style={{ marginLeft: 30, marginRight: 10 }}
                    disabled={!this.state.wednesday}
                    defaultValue={moment(this.state.wednesdayTime, format)}
                    format={format}
                    onChange={(time, timeString) =>
                      this.toggleweekdayTime(time, timeString, "wednesday")
                    }
                  />
                  <TimePicker
                    disabled={!this.state.wednesday}
                    defaultValue={moment(this.state.wednesdayEndTime, format)}
                    format={format}
                    onChange={(time, timeString) =>
                      this.toggleweekdayEndTime(time, timeString, "wednesday")
                    }
                  />
                  <Button
                    style={{ marginLeft: 10 }}
                    disabled={!this.state.wednesday}
                    disabled={
                      !this.state.wednesday &&
                      this.state.wednesdayTime == "00:00"
                    }
                    onClick={() =>
                      this.postweek("wednesday", this.state.wednesday)
                    }
                  >
                    <RIcon Icon={chevronRight}></RIcon>
                  </Button>
                </span>
              </li>
              <li>
                <h3 align="left">Thursday</h3>
                <span>
                  <Switch
                    style={{ minWidth: "100%" }}
                    checkedChildren={<RIcon Icon= {check} />}
                    unCheckedChildren={<RIcon Icon= {close} />}
                    checked={this.state.thursday}
                    onChange={(thursday) =>
                      this.toggleweekday("thursday", thursday)
                    }
                    // defaultChecked
                    value={this.state.thursday}
                  />
                  <br />
                  <TimePicker
                    style={{ marginLeft: 30, marginRight: 10 }}
                    disabled={!this.state.thursday}
                    defaultValue={moment(this.state.thursdayTime, format)}
                    format={format}
                    onChange={(time, timeString) =>
                      this.toggleweekdayTime(time, timeString, "thursday")
                    }
                  />
                  <TimePicker
                    disabled={!this.state.thursday}
                    defaultValue={moment(this.state.thursdayEndTime, format)}
                    format={format}
                    onChange={(time, timeString) =>
                      this.toggleweekdayEndTime(time, timeString, "thursday")
                    }
                  />
                  <Button
                    style={{ marginLeft: 10 }}
                    disabled={!this.state.thursday}
                    disabled={
                      !this.state.thursday && this.state.thursdayTime == "00:00"
                    }
                    onClick={() =>
                      this.postweek("thursday", this.state.thursday)
                    }
                  >
                    <RIcon Icon={chevronRight}></RIcon>
                  </Button>
                </span>
              </li>
              <li>
                <h3 align="left">Friday</h3>
                <span>
                  <Switch
                    style={{ minWidth: "100%" }}
                    checkedChildren={<RIcon Icon={check} />}
                    unCheckedChildren={<RIcon Icon={close} />}
                    checked={this.state.friday}
                    onChange={(friday) => this.toggleweekday("friday", friday)}
                  />
                  <br />
                  <TimePicker
                    style={{ marginLeft: 30, marginRight: 10 }}
                    disabled={!this.state.friday}
                    defaultValue={moment(this.state.fridayTime, format)}
                    format={format}
                    onChange={(time, timeString) =>
                      this.toggleweekdayTime(time, timeString, "friday")
                    }
                  />
                  <TimePicker
                    disabled={!this.state.friday}
                    defaultValue={moment(this.state.fridayEndTime, format)}
                    format={format}
                    onChange={(time, timeString) =>
                      this.toggleweekdayEndTime(time, timeString, "friday")
                    }
                  />
                  <Button
                    style={{ marginLeft: 10 }}
                    disabled={!this.state.friday}
                    onClick={() => this.postweek()}
                    disabled={
                      !this.state.friday && this.state.fridayTime == "00:00"
                    }
                    onClick={() => this.postweek("friday", this.state.friday)}
                  >
                    <RIcon Icon={chevronRight}></RIcon>
                  </Button>
                </span>
              </li>
              <li>
                <h3 align="left">Saturday</h3>
                <span>
                  <Switch
                    style={{ minWidth: "100%" }}
                    checkedChildren={<RIcon Icon= {check} />}
                    unCheckedChildren={<RIcon Icon= {close} />}
                    checked={this.state.saturday}
                    onChange={(saturday) =>
                      this.toggleweekday("saturday", saturday)
                    }
                  />
                  <br />
                  <TimePicker
                    style={{ marginLeft: 30, marginRight: 10 }}
                    disabled={!this.state.saturday}
                    defaultValue={moment(this.state.saturdayTime, format)}
                    format={format}
                    onChange={(time, timeString) =>
                      this.toggleweekdayTime(time, timeString, "saturday")
                    }
                  />
                  <TimePicker
                    disabled={!this.state.saturday}
                    defaultValue={moment(this.state.saturdayEndTime, format)}
                    format={format}
                    onChange={(time, timeString) =>
                      this.toggleweekdayEndTime(time, timeString, "saturday")
                    }
                  />
                  <Button
                    style={{ marginLeft: 10 }}
                    // disabled={!this.state.saturday}
                    // onClick={() => this.postweek()}
                    disabled={
                      !this.state.saturday && this.state.saturdayTime == "00:00"
                    }
                    onClick={() =>
                      this.postweek("saturday", this.state.saturday)
                    }
                  >
                    <RIcon Icon={chevronRight}></RIcon>
                  </Button>
                </span>
              </li>
              <li>
                <h3 align="left">Sunday</h3>
                <span>
                  <Switch
                    style={{ minWidth: "100%" }}
                    checkedChildren={<RIcon Icon= {check} />}
                    unCheckedChildren={<RIcon Icon= {close} />}
                    onChange={(sunday) => this.toggleweekday("sunday", sunday)}
                    checked={this.state.sunday}
                  />
                  <br />
                  <TimePicker
                    style={{ marginLeft: 30, marginRight: 10 }}
                    disabled={!this.state.sunday}
                    defaultValue={moment(this.state.sundayTime, format)}
                    format={format}
                    onChange={(time, timeString) =>
                      this.toggleweekdayTime(time, timeString, "sunday")
                    }
                  />
                  <TimePicker
                    disabled={!this.state.sunday}
                    defaultValue={moment(this.state.sundayEndTime, format)}
                    format={format}
                    onChange={(time, timeString) =>
                      this.toggleweekdayEndTime(time, timeString, "sunday")
                    }
                  />
                  <Button
                    style={{ marginLeft: 10 }}
                    disabled={
                      !this.state.sunday && this.state.sundayTime == "00:00"
                    }
                    onClick={() => this.postweek("sunday", this.state.sunday)}
                  >
                    <RIcon Icon={chevronRight}></RIcon>
                  </Button>
                </span>
              </li>
              {/* <li
                style={{
                  textAlign: "center"
                }}
              >
                <img
                  src={`${appConfig.endpoint}/${logo}`}
                  style={{ maxWidth: "100px", height: "auto" }}
                />
              </li> */}
              {/* <li>
                <input
                  className="file-input"
                  type="file"
                  name="icon"
                  accept="image/*"
                  onChange={this.handleLogoChange}
                />
              </li> */}

              {/* <form onSubmit={() => this.setProfile}> */}
              <li>
                {" "}
                <p style={{ textAlign: "left" }}>Generall Informasjon</p>
              </li>
              <li>
                <hr />
                <input
                  type="text"
                  className="input1"
                  onChange={this.handleChange}
                  name="name"
                  placeholder="Restaurant Navn"
                  value={name}
                  required
                />
              </li>
              <li>
                <input
                  type="email"
                  className="input1"
                  onChange={this.handleChange}
                  name="email"
                  placeholder="E-mail"
                  value={email}
                  required
                />
              </li>
              {/* <li>
                <input
                  type="email"
                  className="input1"
                  onChange={this.handleChange}
                  name="taccountantEmail"
                  placeholder="Taccountant Email"
                  value={taccountantEmail}
                  required
                />
              </li> */}
              <li>
                <input
                  type="text"
                  className="input1"
                  onChange={this.handleChange}
                  name="website"
                  placeholder="Nettsted"
                  value={website}
                  required
                />
              </li>
              <li>
                <input
                  type="number"
                  className="input1"
                  onChange={this.handleChange}
                  name="taxNo"
                  placeholder="Org Nr."
                  value={taxNo}
                  required
                />
              </li>
              <li>
                <input
                  type="number"
                  onChange={this.handleChange}
                  className="input1"
                  name="phoneNo"
                  min="0000000000"
                  max="9999999999"
                  placeholder="Tel. Nr."
                  value={phoneNo}
                  required
                />
              </li>

              <li>
                <p>Addresse</p>
                <input
                  type="text"
                  onChange={this.handleChange}
                  name="line1"
                  maxlength="20"
                  className="input2"
                  placeholder="Addresse"
                  value={line1}
                />
              </li>
              <li>
                <input
                  type="text"
                  onChange={this.handleChange}
                  name="line2"
                  maxlength="20"
                  className="input2"
                  placeholder="Addresse 2"
                  value={line2}
                />
              </li>
              <li style={{ marginTop: "1em" }}>
                <input
                  style={{
                    maxWidth: "37%",
                    float: "left",
                    margin: "0 2% 0 11%"
                  }}
                  type="text"
                  onChange={this.handleChange}
                  name="city"
                  pattern="[A-Za-z]+"
                  maxlength="20"
                  className="input2"
                  placeholder="By"
                  value={city}
                />

                <input
                  style={{
                    maxWidth: "37%",
                    float: "left",
                    margin: "0 11% 0 2%"
                  }}
                  type="number"
                  onChange={this.handleChange}
                  name="pin"
                  className="input2"
                  placeholder="PIN"
                  value={pin}
                />
              </li>
              <li>
                <input
                  style={{
                    maxWidth: "37%",
                    float: "left",
                    margin: "0 2% 0 11%"
                  }}
                  type="text"
                  onChange={this.handleChange}
                  name="state"
                  pattern="[A-Za-z]+"
                  maxlength="20"
                  className="input2"
                  placeholder="Fylke"
                  value={state}
                />

                <input
                  style={{
                    maxWidth: "37%",
                    float: "left",
                    margin: "0 11% 0 2%"
                  }}
                  type="text"
                  onChange={this.handleChange}
                  name="country"
                  pattern="[A-Za-z]+"
                  maxlength="20"
                  className="input2"
                  placeholder="Land"
                  value={country}
                />
              </li>
              <li>
                <textarea
                  type="text"
                  className="input1"
                  onChange={this.handleChange}
                  name="description"
                  rows="5"
                  placeholder="Om"
                  required
                  value={description}
                />
              </li>
              <li>
                <button onClick={() => this.setProfile()} id="submit-landing1">
                  Submit
                </button>
              </li>
            </form>
          </div>
        </Home>
      );
    } else {
      return (
        <Home isProfile={true}>
          <div className="res-profile-wrapper">
            <div className="res-flex title">
              <img
                src={`${appConfig.s3url}/${logo}`}
                style={{ maxWidth: "80px", height: "auto" }}
              />
              <h1 id="hotel-name">
                <strong>{items.name}</strong>
              </h1>
              {isEditable ? (
                <i
                  onClick={this.editResProfile}
                  className="fa fa-pencil"
                  aria-hidden="true"
                />
              ) : (
                ""
              )}
            </div>
            <div className="res-flex">
              <div className="info-hotel-mini">
                <p className="hotel-info">
                  <span>
                    <b>Online Tilgjengelighet :{items.availability.status}</b>
                  </span>
                  <span style={{ marginTop: -14 }}>
                    <Switch
                      checked={this.state.availabilityStatus}
                      // defaultChecked
                      checkedChildren="ACTIVE"
                      unCheckedChildren="NOT-ACTIVE"
                      onChange={(checked) => this.changeavailability(checked)}
                    />
                  </span>
                </p>

                <p className="hotel-info">
                  <span>
                    <b>Uke Tilgjengelighet :</b>
                  </span>

                  <span style={{ marginTop: -14 }}>
                    {items.monday && items.monday.status == true ? (
                      <div
                        style={{
                          background: "#efefef"
                        }}
                      >
                        <h3
                          style={{
                            marginLeft: 14,
                            color: "#00b894"
                          }}
                        >
                          <RIcon Icon={checkCircle} /> Monday{" "}
                          <p style={{ marginLeft: 25, paddingRight: 20 }}>
                            {items.monday.openingTime} -{" "}
                            {items.monday.closingTime}
                          </p>
                        </h3>
                      </div>
                    ) : (
                      <Alert message="Monday (OFF)" type="warning" showIcon />
                    )}

                    {items.tuesday && items.tuesday.status == true ? (
                      <div
                        style={{
                          background: "#efefef",
                          marginTop: 10
                        }}
                      >
                        <h3
                          style={{
                            marginLeft: 14,
                            color: "#00b894"
                          }}
                        >
                          <RIcon Icon={checkCircle} /> Tuesday{" "}
                          <p style={{ marginLeft: 25 }}>
                            {items.tuesday.openingTime} -{" "}
                            {items.tuesday.closingTime}
                          </p>
                        </h3>
                      </div>
                    ) : (
                      <Alert message="Tuesday (OFF)" type="warning" showIcon />
                    )}

                    {items.wednesday && items.wednesday.status == true ? (
                      <div
                        style={{
                          background: "#efefef",
                          marginTop: 10
                        }}
                      >
                        <h3
                          style={{
                            marginLeft: 14,
                            color: "#00b894"
                          }}
                        >
                          <RIcon type="check-circle" /> Wednesday{" "}
                          <p style={{ marginLeft: 25 }}>
                            {items.wednesday.openingTime} -{" "}
                            {items.wednesday.closingTime}
                          </p>
                        </h3>
                      </div>
                    ) : (
                      <Alert
                        message="WEDNESDAY (OFF)"
                        type="warning"
                        showIcon
                      />
                    )}

                    {items.thursday && items.thursday.status == true ? (
                      <div
                        style={{
                          background: "#efefef",
                          marginTop: 10
                        }}
                      >
                        <h3
                          style={{
                            marginLeft: 14,
                            color: "#00b894"
                          }}
                        >
                          <RIcon Icon={checkCircle} /> Thursday{" "}
                          <p style={{ marginLeft: 25 }}>
                            {items.thursday.openingTime} -{" "}
                            {items.thursday.closingTime}
                          </p>
                        </h3>
                      </div>
                    ) : (
                      <Alert message="THURSDAY (OFF)" type="warning" showIcon />
                    )}

                    {items.friday && items.friday.status == true ? (
                      <div
                        style={{
                          background: "#efefef",
                          marginTop: 10
                        }}
                      >
                        <h3
                          style={{
                            marginLeft: 14,
                            color: "#00b894"
                          }}
                        >
                         <RIcon Icon={checkCircle} /> Friday{" "}
                          <p style={{ marginLeft: 25 }}>
                            {items.friday.openingTime} -{" "}
                            {items.friday.closingTime}
                          </p>
                        </h3>
                      </div>
                    ) : (
                      <Alert message="FRIDAY (OFF)" type="warning" showIcon />
                    )}

                    {items.saturday && items.saturday.status == true ? (
                      <div
                        style={{
                          background: "#efefef",
                          marginTop: 10
                        }}
                      >
                        <h3
                          style={{
                            marginLeft: 14,
                            color: "#00b894"
                          }}
                        >
                         <RIcon Icon={checkCircle} /> Saturday{" "}
                          <p style={{ marginLeft: 25 }}>
                            {items.saturday.openingTime} -{" "}
                            {items.saturday.closingTime}
                          </p>
                        </h3>
                      </div>
                    ) : (
                      <Alert message="SATURDAY (OFF)" type="warning" showIcon />
                    )}

                    {items.sunday && items.sunday.status == true ? (
                      <div
                        style={{
                          background: "#efefef",
                          marginTop: 10
                        }}
                      >
                        <h3
                          style={{
                            marginLeft: 14,
                            color: "#00b894"
                          }}
                        >
                         <RIcon Icon={checkCircle} /> Sunday{" "}
                          <p style={{ marginLeft: 25 }}>
                            {items.sunday.openingTime} -{" "}
                            {items.sunday.closingTime}
                          </p>
                        </h3>
                      </div>
                    ) : (
                      <Alert message="SUNDAY (OFF)" type="warning" showIcon />
                    )}
                  </span>
                </p>

                <p className="hotel-info">
                  <span>
                    <b>Tel. Nr.:</b>
                  </span>
                  <span>{items.phoneNo}</span>
                </p>
                <p className="hotel-info">
                  <span>
                    <b>Email:</b>
                  </span>
                  <span>{items.email}</span>
                </p>
               
                <p className="hotel-info">
                  <span>
                    <b>Nettsted:</b>
                  </span>
                  <span>
                    <a href={items.website}>{items.website}</a>
                  </span>
                </p>
                <p className="hotel-info">
                  <span>
                    <b>Org. Nr.:</b>
                  </span>
                  <span>{items.taxNo}</span>
                </p>
                <p className="hotel-info">
                  <span>
                    <b>Konto Nr.:</b>
                  </span>
                  <span>{items.accountNumber}</span>
                </p>
                <p className="hotel-info">
                  <span>
                    <b>Addresse:</b>
                  </span>
                  <span>
                    {items.address.line1}
                    <br />
                    {items.address.line2}
                    <br />
                    {items.address.city}, {items.address.state}
                    <br />
                    {items.address.pin}, {items.address.country}
                  </span>
                </p>
                <span className="hotel-info description">
                  <span>
                    <h4>
                      <b>Om-Oss:</b>
                    </h4>
                  </span>
                  <span>{items.description}</span>
                </span>
                
              </div>
            </div>
          </div>

         
          <Modal
            isOpen={this.state.modalIsOpen}
            onAfterOpen={this.afterOpenModal}
            onRequestClose={this.closeModal}
            style={customStyles}
            contentLabel="Edit Hotel Details"
          >
            <div className="button-container">
              <button onClick={this.closeModal} className="close-button">
                X
              </button>
            </div>
            <form className="add-employee my-modal">
              <li>
                <center>
                  <h2>Edit Hotel Details</h2>
                </center>
              </li>
              <li>
                <input
                  type="text"
                  className="input2"
                  placeholder="Hotel Name"
                  value={items.name}
                />
              </li>
              <li>
                <input
                  type="number"
                  className="input2"
                  placeholder="Contact No."
                  value={items.phoneNo}
                />
              </li>
              <li>
                <input
                  type="text"
                  className="input2"
                  placeholder="E-mail"
                  value={items.email}
                />
              </li>
              <li>
                <input
                  type="number"
                  className="input2"
                  placeholder="Tax no."
                  value={items.taxNo}
                />
              </li>
              <h4>Address</h4>
              <li>
                <textarea className="input2" value={items.address} />
              </li>
              <li>
                <label htmlFor="des">Description</label>
                <textarea
                  className="input2"
                  name="description"
                  id="des"
                  cols="20"
                  rows="5"
                  value={items.description}
                />
              </li>
              <li>
                <button type="submit" id="submit-landing2">
                  Submit
                </button>
              </li>
            </form>
          </Modal>
        </Home>
      );
    }
  }
}
const mapStateToProps = (state) => {
  return {};
};
const resProfile = connect(mapStateToProps)(resProfileComponent);
export default resProfile;
