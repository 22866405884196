import React, {useState} from 'react'
import { POSTAPI } from "../AllApi";
import {
    Row,Col,
    Button,
    Checkbox,
    Form,
    Input,
    Card,
    Select,
    Icon,
    Divider,
    notification,
    DatePicker
} from "antd";
import "./Checkout.css";
import { Container } from "react-bootstrap";
import history from "../../../history";
import moment from 'moment'
//import { useLocation } from 'react-router-dom';
import { NavLink } from "react-router-dom";
import appConfig from "../../../helpers/appConfig";
import VendorOrder from "../VendorOrder";
import { useEffect } from "react";

const layout = {
    labelCol: {
        //span: 7,
    },
    wrapperCol: {
        span: 24
    }
};
const tailLayout = {
    wrapperCol: {
        span: 24
    }
};



const Checkout = (props) => {

    const branchInfo = JSON.parse(localStorage.getItem("branchInfo"));
    const user = JSON.parse(localStorage.getItem("user"));
    let restaurantName = branchInfo.name;
    let emailId = branchInfo.bEmail;
    let city = branchInfo.address.city;
    let country = branchInfo.address.country;
    let state = branchInfo.address.state;
    let phoneNo = branchInfo.phone;
    const vid = localStorage.getItem("vid");
    const name = props.location.state.name;
    const logo = props.location.state.logo;
    const userEmail = user.email;
    localStorage.setItem("vendorlogo", logo);
    let cityDriveOut = localStorage.getItem("cityDriveOut")
    let cityId = localStorage.getItem("cityId")

    const bid = localStorage.getItem("bid");
    
   console.log("user info======",userEmail)

   console.log("rest id===========",bid)
    // console.log("==phone no==", phoneNo)
    

    let [disabled, setDisabled] = useState(true);
    let addressArray = [city, country, state];
    let [countryCode, setCountryCode] = useState("47");
    // const [onlineDate, setOnlineDate] = useState("");
    const [selectedDate, setSelectedDate] = useState("")
    let [address, setAddress] = useState(addressArray);
    let [phone, setPhone] = useState(phoneNo);
    let [orderType, setOrderType] = useState("Take Away");
    //let [customerName , setCustomerName] = useState("");
    let [customerPhone , setCustomerPhone] = useState("")
    const [errorMsg, setErrorMsg] = useState("")
    const onFinish = (values) => { };
    const { Option } = Select;

    // get data sending by props from parent
    const cartData = props.location.state.cartData;
    const totalPrice = props.location.state.total;
    // const discount = props.location.state.discount;
    // const discountPrice = props.location.state.discountPrice;
    const mvaArray = props.location.state.mvaArray;
    const cart = props.location.state.prevCart;
    const cartState = props.location.state.cartState;

    console.log("CART STATE----------",cart)

    // history.push({
    //     pathname: "/vendorOrders",
    //     state: {
    //       cart : cart
    //     }
    //   });

    

    const onChangeEvent = (event) => {
        if (event.target.name == "address") {
            setAddress(event.target.value);
        }
    };
    const onChangeCountryCode = (event) => {
        setCountryCode(event);
    };


    const handleSubmit = () => {
        if (restaurantName != "" && emailId != "" && address != "" && phone != "" && userEmail !="") {
            setDisabled(false)
        }
        // else if(customerName == ""){
        //     notification.open({
        //         message: "Customer Name can't be empty!",
        //         icon: <Icon type="close-circle" style={{ color: 'red' }} />,
        //         duration: 2
        //     });
        // }
    //     else if(restaurantName != ""){
    //         setErrorMsg("Enter restaurant name")
    //         return false
    //     }else if(emailId != ""){
    //         setErrorMsg("Enter email Id")
    //   return false
    //     }else if(address != ""){
    //         setErrorMsg("Enter complete address")
    //         return false
    //     }else if(phone !=""){
    //         setErrorMsg("Enter phone number")
    //   return false
    //     }
    //     return true
        //console.log("======all data=====", postData)
    }

   

    useEffect(() => {
        console.log("--cartdata--", cartData);
    }, [cartData]);
    const payOrder = async (data) => {
        let resData = await POSTAPI(
            `${appConfig.endpoint}/api/v1/vendor/orders/createVendorOrder`,
            data
        );

        if (resData.status == 200) {
            let otp = resData.data.otp;
            let paymentUrl=resData.paymentUrl?resData.paymentUrl:""
            
            // history.push({
            //     pathname: "/paymentSuccess",
            //     state: {
            //         data: cartData,
            //         otp: otp,
            //         totalPrice: totalPrice,
            //         address: address,
            //         resName: name,
            //         vendorLogo: logo
            //     }
            // });

            window.location.replace(paymentUrl) 
            console.log("res data createOrder ", resData.data);
        }
    };
    let addressDetail = address.toString();
    const customerDetail = {
        restaurantName,
        addressDetail:addressDetail,
        emailId,
        phoneNo,
        userEmail,
        customerPhone,

    };

    console.log("CUSTOMER DETAILS====", customerDetail)

    // useEffect(() => {
    //     // const date = onlineDate ? onlineDate : moment().date()._d;
    //     filterDate(date);
    // }, [])

    const filterDate = (date) => {
        //setOnlineDate(date)
        let Sdate = moment(date);
        setSelectedDate(Sdate)
        // handlePay(selectedDate)
        // console.log("date =====", selectedDate)
    } 

    const handlePay = () => {
        let items = [];
        cartData.map((e) => {
            let item = {};
            item.id = e.productId;
            item.name = e.name;
            item.quantity = e.quantity;
            item.unitType = e.unitType;
            item.availQuantity = e.availQuantity;
            // item.image = e.image
            // item.prodTax = e.prodTax
            items.push(item);
            
        });
        let discount = 0;
        if(totalPrice>20){
            if(totalPrice>=20 && totalPrice<50){
                discount = 5;
                //console.log("discount is =======",10)
            }else if(totalPrice >=50 && totalPrice<100){
                discount = 10;
                //console.log("discount is =======",20)
            }else if(totalPrice >=100 ){
                discount = 20;
                //console.log("discount is =======",30)
        }else{
            discount = 0;
            }
        }

        //console.log("discount is =======",discount)

        //console.log("---Data for posting Cart---", items);

        // send payload
        let postData = {
            items,
            branchId: vid,
            orderType: orderType,
            customerType: "restaurant",
            // discount:discount,
            requestedTime: selectedDate,
            customerDetail: customerDetail,
            checkPayment:true,
            cityId:cityId,
            restCustomerId:bid
        };
        //console.log("cart data in items", items);
        console.log("order api paylaod", postData);
        payOrder(postData);
    };

    const onFinishFailed = (errorInfo) => { };

    console.log("data===========", orderType)

  return (
    <div className="bg-white">
    <Container className="bg-white p-5" id="displayrow">
        <header className="homeLayout">
            <nav className="navbar navbar-expand-lg navbar-light bg-light fixed-top justify-content-between">
                <NavLink
                    className="navbar-brand d-position m-0 col-2"
                    to="/dashboard"
                >
                    <img
                        src={logo === null ? "" : `${appConfig.s3url}/${logo}`}
                        className="company-logo-header hide-logo p-0"
                    />
                    <span style={{ marginLeft: "5px" }}>
                        <h5 className="m-0"> {name}</h5>
                    </span>
                    <img
                        src={logo === null ? "" : `${appConfig.s3url}/${logo}`}
                        className="company-logo-header2 hide-logo2"
                    />
                </NavLink>

                <div className="d-flex">
                    <select
                        className="dropSelect"
                        onChange={(e) => {
                            setOrderType(e.target.value);
                        }}
                    >
                        <option value="Take Away">
                            {/* Take Away Order */}
                            Take Away
                        </option>
                        <option value="Drive Out">
                            {/* Drive Out Order */}
                            Drive Out
                        </option>
                    </select>
                </div>
            </nav>
        </header>

        <Row className="d-flex justify-content-between mt-5">
            <Col span={12} className="site-card-border-less-wrapper">
                <Card className="cardSize p-3">
                    <Form {...layout} name="control-ref" onFinish={onFinish}>
                        <h6 className="heading-form m-0">Customer Details</h6>
                        <div className="d-flex">
                            <Form.Item
                                label="*Restaurant Name"
                                name="restaurantName"
                                className="formField w-100"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please input Restaurant name!"
                                    }
                                ]}
                            >
                                <Input value={restaurantName} name="restaurantName" />
                            </Form.Item>
                        </div>
                        <div className="d-flex justify-content-between">
                            <Form.Item
                                name="emailId"
                                label="*Restaurant E-Mail"
                                className="formField"
                                rules={[
                                    {
                                        type: "email",
                                        required: true,
                                        message: "Please input your E-Mail!"
                                    }
                                ]}
                            >
                                <Input name="emailId" value={emailId} />
                            </Form.Item>
                            <Form.Item
                                label="*Restaurant Phone No"
                                name="phone"
                                className="formField ml-3"
                            >
                                <Input.Group compact>
                                    <Select
                                        style={{ width: "35%" }}
                                        placeholder="+47"
                                        //onChange={onChangeCountryCode}
                                    >
                                        <Option value="47">+47</Option>
                                        <Option value="91">+91</Option>
                                    </Select>
                                    <Input
                                        min="0"
                                        style={{ width: "65%" }}
                                        name="phone"
                                        type="number"
                                        value={phone}
                                        //onChange={(event) => setPhone(event.target.value)}
                                    
                                    />
                                </Input.Group>
                            </Form.Item>
                        </div>
                        <div className="d-flex justify-content-between">
                            <Form.Item
                                name="userEmail"
                                label="*Employee Id"
                                className="formField"
                                value={userEmail}
                                //onChange={(event) => setCustomerName(event.target.value)}
                                rules={[
                                    {
                                        type: "email",
                                        required: true,
                                        message: "Please input your E-Mail!"
                                    }
                                ]}
                            >
                                <Input name="userEmail"  value={userEmail}
                                // suffix={<Icon type="edit" style={{ color: "#bfbfbf" }} />}
                                />
                            </Form.Item>
                            <Form.Item
                                label="Other Contact"
                                name="customerPhone"
                                className="formField ml-3"
                            >
                                <Input.Group compact>
                                    <Select
                                        style={{ width: "35%" }}
                                        placeholder="+47"
                                        onChange={onChangeCountryCode}
                                    >
                                        <Option value="47">+47</Option>
                                        <Option value="91">+91</Option>
                                    </Select>
                                    <Input
                                        min="0"
                                        style={{ width: "65%" }}
                                        name="customerPhone"
                                        type="customerPhone"
                                        value={customerPhone}
                                        onChange={(event) => setCustomerPhone(Number(event.target.value))}
                                        suffix={
                                            <Icon type="edit" style={{ color: "#bfbfbf" }} />
                                        }
                                    />
                                </Input.Group>
                            </Form.Item>
                        </div>
                        <div className='datePicker'>
                    Requested Delivery Date : <DatePicker className='date-select'
                                            onChange={(date, dateString) => {
                                                filterDate(date)
                                            }}
                                            format="YYYY-MM-DD"
                                            placeholder="Select day"
                                        />
                        </div>
                        <div className="d-flex pt-2">
                            <Form.Item
                                label="*Restaurant Address"
                                name="address"
                                className="formField w-100"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please input your Address!"
                                    }
                                ]}
                            >
                                <Input
                                    className="address-field"
                                    value={address}
                                    //defaultValue={addressArray}
                                    onChange={onChangeEvent}
                                    name="address"
                                    suffix={<Icon type="edit" style={{ color: "#bfbfbf" }} />}
                                />
                            </Form.Item>
                        </div>
                        <p>{errorMsg}</p>

                        <Form.Item {...tailLayout}>
                            <Button
                                type="primary"
                                className="w-100 saveBtn checkout-btn"
                                block
                                onClick={handleSubmit}
                            >
                                Save
                            </Button>
                        </Form.Item>
                    </Form>
                </Card>
            </Col>

            <Col span={11} className="site-card-border-less-wrapper">
                <Card className="cardSize p-3">
                    <h6 className="heading-form m-0">Deposit</h6>
                    <Row>
                        {/* <Col className="payCard mt-3 p-0"> */}
                        <Button
                            type="primary"
                            className="w-100 saveBtn payBtn checkout-btn mt-3"
                            block
                            onClick={()=>handlePay()}
                            disabled={disabled}
                        >
                            Pay
                        </Button>
                        {/* </Col> */}
                    </Row>
                    <Divider />
                    <Row className="pt-0 d-flex flex-column productRow">
                        {cartData.map((e) => {
                            return (
                                <Row className="pt-3 product-bg">
                                    <Col
                                        span={8}
                                        className="d-flex justify-content-left paragraph"
                                    >
                                        <p className="itemName">{e.name}</p>
                                    </Col>
                                    <Col span={8} className="d-flex justify-content-center">
                                        <p className="itemQuantity">
                                            <span style={{ fontSize: "7px" }}>x</span>
                                            {e.quantity}
                                        </p>
                                    </Col>
                                    <Col
                                        span={8}
                                        className="d-flex justify-content-end  paragraph"
                                    >
                                        <p className="itemPrice">Kr. {e.totalAmount}</p>
                                    </Col>
                                </Row>
                            );
                        })}
                        <Divider />
                        <Row className="totalDisplay p-2">
                            <Col span={24}>
                                {mvaArray.map((e) => {
                                    let mvaLabel = `MVA(${e.tax})`;
                                    return (
                                        <Row>
                                            <Col span={12}>
                                                <p className="itemName">{mvaLabel}%</p>
                                            </Col>
                                            <Col span={12} className="productTotalContainer">
                                                <p
                                                    className="itemName"
                                                    style={{ textAlign: "right" }}
                                                >
                                                    Kr. {Number(e.taxTotal).toFixed(2)}
                                                </p>
                                            </Col>
                                        </Row>
                                    );
                                })}
                            </Col> 
                            {/* {!discount==0 ?
                            <>
                            <Col span={12}>
                                <p className="itemName mb-0">Discount({discount}%)</p>
                            </Col>
                            <Col span={12} className="productTotalContainer">
                                <p className="itemName mb-0" style={{ textAlign: "right" }}>
                                    Kr. {discountPrice}
                                </p>
                            </Col>
                            </>
                            :""
                            } */}
                            { orderType == 'Drive Out' ? 
                            <>
                            <Col span={12}>
                                <p className="itemName pt-2 mb-0">DriveOut Charges</p>
                            </Col>
                            <Col span={12} className="productTotalContainer">
                                <p className="itemName  pt-2 mb-0" style={{ textAlign: "right" }}>
                                    Kr. {cityDriveOut}
                                </p>
                            </Col>
                            </>
                            
                            : ""}
                            <Col span={12}>
                                <p className="itemName pt-2 mb-0">Total</p>
                            </Col>
                            <Col span={12} className="productTotalContainer">
                                <p className="itemName  pt-2 mb-0" style={{ textAlign: "right" }}>
                                {
                                    orderType == "Drive Out" ?
                                    <>
                                    Kr. {totalPrice + Number(cityDriveOut)}
                                    </>
                                    :
                                    <>
                                    Kr. {totalPrice}
                                    </>
                            }
                                </p>
                            </Col>
                        </Row>
                    </Row>
                </Card>
            </Col>
        </Row>
    </Container>
</div>
  )
}

export default Checkout