import React,{useEffect, useState} from "react";
import { DatePicker, Table } from "antd";
import { POSTAPI } from "../../Vendor/AllApi";
import appConfig from "../../../helpers/appConfig";
import moment from 'moment';
import { Card, Row, Col, Button } from "react-bootstrap";
import {
  employeesTipColumn,
  TipReportSummary,
} from "./../../Table/TableColumns";
import "./TipReportPrint.css";
const List = (props) => {
  const [ data , setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const { MonthPicker } = DatePicker;

  console.log("-------data in table component----", props.allTipsData)
  console.log("-------employeesTips----", props.dataSource)

  let totalEmployeesTip = 0;
  for (let i = 0; i < props.dataSource.length; i++) {
    totalEmployeesTip += props.dataSource[i].total;
  }
  const TipReportSummaryData = [
    {
      title: "TipReportSummaryData",
    },
  ];

  const printReportHandler = () => {
    window.print();
  };


  const employeesAllTipColumn = () => [
    {
      title: "Date",
      render: (text, record) =>
      `${moment(record.createdAt).format("DD/MM/YYYY")} - ${new Date(record.createdAt).toLocaleTimeString()}`,
      width: "20%"
    },
    {
        title: "NAVN",
      width: "20%",
      render: (text, record) => {
        return <b>{record.employeeId.firstName + " " + record.employeeId.lastName}</b>;
      }
    },
    {
      title: "EMAIL",
      width: "20%",
      dataIndex: 'employeeId.email',
    },
    {
      title: "CASH",
      width: "15%",
      dataIndex: 'cash',
      key: 'cash',
    },
    {
      title: "BANK",
      width: "15%",
      dataIndex: 'bank',
      key: 'bank',
    },
    {
      title: "TOTAL",
      width: "10%",
      dataIndex: 'amount',
      key: 'amount',
    }
  ];



  return (
    <>
      <Card>
        <Card.Body>
          <Row className="text-center">
            <Col className="d-flex">
              <DatePicker
                id="TipDate"
                onChange={(date, dateString) => {
                  
                  props.handleDateChange(date, "day");
                }}
                format="YYYY-MM-DD"
                placeholder="Select Day"
              ></DatePicker>
              
              <MonthPicker
                onChange={(date, dateString) => {
                  props.handleMonthChange(date, "month");
                }}
                placeholder="Select Month"
              />
            </Col>
            <Col align="right">
              <Button
                onClick={() => printReportHandler()}
                className="ant-btn btn-ab" style={{background:"#dcbb68"}}
              >
                <i
                  className="fa fa-print"
                  style={{ fontSize: 20, marginTop: 1 }}
                  aria-hidden="true"
                />
              </Button>
            </Col>
          </Row>
          <Row className="mt-3" >
            <Col>
            <Card>
              <Card.Header className="h4">Total Tips Data</Card.Header>
              <Card.Body>
              <Table
                id="TipTable"
                loading={props.isLoading}
                dataSource={props.dataSource}
                columns={employeesTipColumn()}
                footer={
                  props.dataSource.length > 0
                    ? () => (
                        <>
                          <Table
                            dataSource={TipReportSummaryData}
                            columns={TipReportSummary(totalEmployeesTip)}
                            showHeader={false}
                            pagination={false}
                            rowClassName="highlighted-bg"
                            rowKey={props.rowKey}
                          />
                        </>
                      )
                    : ""
                }
                className="ant-table-body"
              />
              </Card.Body>
              </Card>
            </Col>
          </Row>


          <Row className="mt-3 allTipsData">
        <Col>
        {
            props.allTipsData.length ?
          <Card>
              <Card.Header className="h4">All Tips Data</Card.Header>
              <Card.Body>
            <Table 
            id="TipTable"
            dataSource={props.allTipsData} 
            loading = {props.isLoading} 
            columns={employeesAllTipColumn()} 
            /> 
              </Card.Body>
              </Card>
              : " "
          }
        </Col>
          </Row>
        </Card.Body>
      </Card>
    </>
  );
};

export default List;
