import React, { Component } from "react";
import { Route } from "react-router-dom";
import { Router, Switch } from "react-router";
import history from "./history";
import Home from "./containers/Home/Home";
import AdminDashboard from "./containers/AdminDashboard/AdminDashboard";
// import PLU from "./containers/PLU/PLU";  //container plu file import
import TableView from "./containers/TableView/TableView";
import Reports from "./containers/Reports/Reports";
// import Billing from "./containers/Billing/Billing"; //container Billing file import
import LandingPage from "./containers/LandingPage/LandingPage";
import Stocks from "./containers/Stock/stock.js";
import CRM from "./containers/crm/crm.js";
import Invoice from "./containers/invoice/invoice.js";
import taccountant from "./containers/taccountant/taccountant.js";
import ikAccounts from "./containers/ik-accounts/ik-accounts.js";
import details from "./containers/detailedReports/detailedReports.js";
import resProfile from "./containers/restaurantProfile/restaurantProfile.js";
import StockDetails from "./containers/StockDetails/StockDetails.js";
import changeStock from "./containers/stockChange/stockChange.js";
import HRM from "./containers/hrm/hrm.js";
import emptab from "./containers/EmployeeTable/emptable.js";
import Tables from "./containers/Tables/Tables.js";
import CTable from "./containers/CTable/CTable.js";
import Manage from "./containers/Manage/Manage.js";
import SingleInvoice from "./containers/SingleInvoice/SingleInvoice.js";
import UnpaidInvoice from "./containers/UnpaidInvoice/UnpaidInvoice.js";
import Setting from "./containers/Setting/Setting.js";
import BarView from "./containers/BarView/BarView";
import Employeetip from "./containers/EmployeeTip/Employeetip";
import RestReports from "./containers/RestReports/RestReports";
import AdminDashboardFunc from "./components/Admin";
import DailyReports from "./components/Report/DailyReports";
import Menu from "./components/Menu";
import Products from "./components/Products";
import XReport from "./components/Report/XReport";
import Dashboard from "./components/Dashboard";
import Login from "./components/Login";
import Arkiv from "./components/Arkiv";
import Faktura from "./components/Arkiv/faktura";
import WeekReport from "./components/Arkiv/Reports/WeekReport";
import ViewReport from "./components/Arkiv/Reports/ViewReport";
import MonthReport from "./components/Arkiv/Reports/MonthReport";
import YearReport from "./components/Arkiv/Reports/YearReport";
import GroupReport from "./components/Arkiv/GroupReport";
import Profile from "./components/Profile";
import Information from "./components/Information";
import Report from "./components/Report";
import PosReport from "./components/Arkiv/PosReport";
import CashReport from "./components/Report/CashReport";
import LagerReport from "./components/Arkiv/Lager";
import TflReport from "./components/Arkiv/TflReport";
import TipReport from "./components/Arkiv/TipReport";
import RefundReport from "./components/Arkiv/RefundReport";
import LeggTilFiler from "./components/Arkiv/LeggTilFiler";
import Profil from "./components/Profil";
import EmployeeTips from "./components/Profil/EmployeeTips";
import OnlineOrder from "./components/Report/onlineOrders";
import Kunder from "./components/Kunder/index";
import Ansatte from "./components/Ansatte/index";
import Lager from "./components/Lager/index";
import LagerList from "./components/Lager/List";
import Innut from "./components/Lager/Innut";
import InnList from "./components/Lager/InnList";
import UtList from "./components/Lager/UtList";
import IkSystem from "./components/system/index";
import UnpaidInvoices from "./components/UnpaidInvoice";
import TreeDriveOrders from "./components/Report/TreeDriveOrders";
import { ProtectedRoute, DefaultRoute } from "./utils/protectedRoutes";
//import Kunder from "./components/Kunder/kunder";
import onlinekunder from "./components/Kunder/onlinekunder";

import ViewUnpaidInvoices from "./components/UnpaidInvoice/ViewPage";
import NotFound from "./components/NotFound/NotFound";
import Bord from "./components/Bord";
import KitchenView from "./components/Kitchen";
import DailyDining from "./components/Report/DailyDiningReport";
import Redirect from "./components/Redirect";
import CoupanModal from "./components/Coupans/CoupanModal";
import CoupanList from "./components/Coupans/CoupanList";
import CoupanInvoicesList from "./components/Coupans/CouponInvoicesList";
import ReserveTable from "./components/ReserveTable/reserve";

// new imports 22.11.22
import OpprettKonto from "./components/Opprett konto/OpprettKonto";
import BillingOK from "./components/Billing O.K/BillingOk";
import tdDiscountPermission from "./components/TDDiscount/tdDiscount";
// import OpprettKontoOld from "./components/Opprett konto old/OpprettKontoOld";
import VendorOrder from "./components/Vendor/VendorOrder";
import GetVendorOrders from "./components/Vendor/Orders/GetVendorOrders";
import VendorDashboard from "./components/Vendor/VendorDashboard";
import ReportDashboard from "./components/Vendor/Reports/ReportDashboard";
import VendorList from "./components/Vendor/VendorList/VendorList";
import PaymentSuccess from "./components/Vendor/PaymentSuccess/PaymentSuccess";
import DailyBills from './components/Vendor/Reports/Daily/DailyBills'
import ZRepport from "./components/Vendor/Reports/ZRepport/ZRepport";
import Checkout from "./components/Vendor/Checkout/Checkout.js";
import VendorCities from "./components/Vendor/Cities/VendorCities.js";

import index from "./components/Arkiv/ProgressKart/index.js";
import TopSellingPrd from "./components/Arkiv/SellingProducts/TopSellingPrd.js";

import MobileView from "./components/Mobile/MobileView";

export default class Main extends Component {
  render() {
    return (
      <Router history={history}>
        <Switch>

            {/*--------------------new routes start ----------------------*/}
            <ProtectedRoute path="/OpprettKonto" component={OpprettKonto} />
            <ProtectedRoute path="/BillingOK" component={BillingOK} />
             <ProtectedRoute path="/vendorOrders" component={VendorOrder} />
          <ProtectedRoute path="/vendor" component={VendorDashboard} />

            {/*--------------------new routes end ----------------------*/}


            {/* <Route path="/plu" component={PLU} /> */}
            <Route path="/tableview" component={TableView} />
          <ProtectedRoute path="/tdDiscount" component={tdDiscountPermission} />
            {/* <ProtectedRoute path="/billing" component={Billing} /> */}
            <ProtectedRoute path="/dashboard" component={Dashboard} />
            <ProtectedRoute path="/kitchen" exact component={Redirect} />
            <ProtectedRoute path="/bar" exact component={Redirect} />
            <ProtectedRoute path="/tflreport" exact component={TflReport} />
            <ProtectedRoute
              path="/rapporter/daily-report"
              exact
              component={DailyReports}
            />
            <ProtectedRoute path="/menu" exact component={Menu} />
            <ProtectedRoute path="/products" exact component={Products} />
            <ProtectedRoute
              path="/rapporter/x-report"
              exact
              component={XReport}
            />
            <ProtectedRoute path="/arkiv" exact component={Arkiv} />
          <ProtectedRoute path="/arkiv/all-faktura" exact component={Faktura} />
            <ProtectedRoute
              path="/arkiv/week-report"
              exact
              component={WeekReport}
            />
            <ProtectedRoute
              path="/arkiv/month-report"
              exact
              component={MonthReport}
            />
            <ProtectedRoute
              path="/arkiv/year-report"
              exact
              component={YearReport}
            />
            <ProtectedRoute
              path="/arkiv/view-report"
              exact
              component={ViewReport}
            />
            <ProtectedRoute
            path="/vendorCities"
            exact
            component={VendorCities}
          />
          <ProtectedRoute
              path="/arkiv/group-report"
              exact
              component={GroupReport}
            />
            <DefaultRoute path="/" exact component={Login} isHome="true" />
            <ProtectedRoute path="/rapporter" exact component={Report} />
            <ProtectedRoute path="/settingg" exact component={Profile} />
            <ProtectedRoute path="/information" exact component={Information} />
            <ProtectedRoute
              path="/arkiv/pos-report"
              exact
              component={PosReport}
            />
             <ProtectedRoute
            path="/arkiv/progress-kart"
            exact
            component={index}
          />
             <ProtectedRoute
            path="/arkiv/top-selling-prd"
            exact
            component={TopSellingPrd}
          />
            <ProtectedRoute
              path="/rapporter/cash-report"
              exact
              component={CashReport}
            />
            <ProtectedRoute
            path="/vendor-list"
            exact
            component={VendorList}
          />
          {/* <ProtectedRoute
            path="/rapporter/vendor-orders"
            exact
            component={GetVendorOrders}
          /> */}
          <ProtectedRoute
            path="/vendor-vendors"
            exact
            component={GetVendorOrders}
          />
          <ProtectedRoute
            path="/vendor-ZRepport"
            exact
            component={ZRepport}
          />
          <ProtectedRoute
            path="/paymentSuccess"
            exact
            component={PaymentSuccess}
          />
          <ProtectedRoute
            path="/vendor-DailyBills"
            exact
            component={DailyBills}
          />
          <ProtectedRoute
            path="/checkout"
            exact
            component={Checkout}
          />
          <ProtectedRoute
              path="/rapporter/online-order"
              exact
              component={OnlineOrder}
            />
            <ProtectedRoute
              path="/rapporter/treedriveorders"
              exact
              component={TreeDriveOrders}
            />
            <ProtectedRoute
              path="/arkiv/lager-report"
              exact
              component={LagerReport}
            />
            <ProtectedRoute
              path="/arkiv/tfl-rapport"
              exact
              component={TflReport}
            />
            <ProtectedRoute
              path="/arkiv/tip-rapport"
              exact
              component={TipReport}
            />
            <ProtectedRoute
            path="/arkiv/refundReport"
            exact
            component={RefundReport} 

            />
            <ProtectedRoute
              path="/arkiv/legg-til-filer"
              exact
              component={LeggTilFiler}
            />
            <ProtectedRoute path="/profil" exact component={Profil} />
          <ProtectedRoute path="/profil/tips" exact component={EmployeeTips} />
            <ProtectedRoute path="/kunder" exact component={Kunder} />
            <ProtectedRoute path="/ansatte" exact component={Ansatte} />
            <ProtectedRoute path="/lager" exact component={Lager} />
            <ProtectedRoute path="/lager/list" exact component={LagerList} />
            <ProtectedRoute path="/lager/innut" exact component={Innut} />
            <ProtectedRoute path="/lager/inn" exact component={InnList} />
            <ProtectedRoute path="/lager/ut" exact component={UtList} />
            <ProtectedRoute path="/coupons" exact component={Redirect} />
          <ProtectedRoute
            path="/coupons/:id"
            exact
            component={CoupanInvoicesList}
          />
            <ProtectedRoute path="/iksystem" exact component={IkSystem} />
           
            <ProtectedRoute
              path="/unpaidinvoices"
              exact
              component={UnpaidInvoices}
            />
            <ProtectedRoute path="/table" exact component={Bord} />
          <ProtectedRoute path="/admin" exact component={AdminDashboardFunc} />
            <ProtectedRoute
              path="/rapporter/dailydining"
              exact
              component={DailyDining}
            />
          <ProtectedRoute path="/reservetable" exact component={ReserveTable} />

          <Route path="/mobile" component={MobileView} />
          
          <Route path="/*" component={NotFound} />
        </Switch>
      </Router>
    );
  }
}
