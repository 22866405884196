import React, { useState, useEffect } from "react";
import moment from "moment";
import Home from "../../Home";
import { Button, Card, Col, Row } from "react-bootstrap";
import { Select, DatePicker } from "antd";
import { connect, useDispatch, useSelector } from "react-redux";
import TableSumX from "../../Table/TableSumX";
import {
  getOnlineOrdersTreeDrive,
  changeOrderStatusTreeDrive,
  searchDriver,
  allAvailableDrivers
} from "./../../../action/ordersAction";
import View from "./View";

const Option = Select.Option;
const Index = (props) => {
  const [onlineStatus, setOnlineStatus] = useState("all");
  const [onlineDate, setOnlineDate] = useState("");
  const [isLoaded, setIsLoaded] = useState(false);
  const [fetchApi, setFetchApi] = useState(true);
  const [orderData, setOrderData] = useState({});
  const [page, setPage] = useState("list");
  const [timeString, setTimeString] = useState("00:00");
  const [cancelReason, setCancelReason] = useState("");
  const [cancelReasonModel, setCancelReasonModel] = useState(false);
  const [timemodalvisible, setTimemodalvisible] = useState(false);
  const goBackMainPage = () => {
    props.history.push("/rapporter");
    setPage("list");
  };
  // console.log("inside the treedriveorders----->");
  // console.log("inside the index.js tree driver search driver reducer=====>",props.searchDriverReducer)
  useEffect(() => {
    const date = onlineDate ? onlineDate : moment().date()._d;
    handleOnlineOrders(date);
  }, [onlineStatus]);

  // const handleOnlineOrders = (date) => {
  //   setIsLoaded(true);
  //   setOnlineDate(date);
  //   const startDate = moment(date).set({
  //     hour: 4,
  //     minute: 0,
  //     second: 0
  //   });
  //   const endDate = moment(date).add(1, "days").set({
  //     hour: 3,
  //     minute: 59,
  //     second: 59
  //   });


  //   props
  //     .getOnlineOrdersTreeDrive({
  //       startDate: startDate,
  //       endDate: endDate,
  //       orderType: onlineStatus
  //     })
  //     .then(() => setIsLoaded(false));
  // };
  const handleOnlineOrders = (date) => {
    setIsLoaded(true);
    setOnlineDate(date);
    const startDate = moment(date);
    const endDate = moment(date).add(1, "days");
    props
      .getOnlineOrdersTreeDrive({
        startDate: startDate,
        endDate: endDate,
        orderType: onlineStatus
      })
      .then(() => setIsLoaded(false));
  };

  const showOrder = (id) => {
    const index = props.onlineOrdersTreeDrive.items.findIndex(
      (item) => item._id === id
    );
    setOrderData(props.onlineOrdersTreeDrive.items[index]);
    setPage("view");
  };
  const ordersColumns = [
    {
      title: "Sr. No.",
      key: "orderNumber",
      width: "5%",
      render: (text, record, index) => index + 1
    },
    {
      title: "Order time",
      key: "orderTime",
      width: "18%",
      render: (text, record) =>
        `${moment(record.createdAt).format("DD/MM/YYYY")} - ${new Date(
          record.createdAt
        ).toLocaleTimeString()}`
    },
    {
      title: "No. of products",
      key: "noProducts",
      width: "9%",
      render: (text, record) =>
        record.items.reduce((acc, current) => acc + current.quantity, 0)
    },
    {
      title: "Status",
      key: "status",
      width: "11%",
      render: (text, record) => {
        if (record.orderStatus == "DELIVERED") {
          return (
            <p
              style={{
                background: "green",
                textAlign: "center",
                borderRadius: 5,
                color: "white"
              }}
            >
              {record.orderStatus}
            </p>
          );
        } else if (record.orderStatus == "IN-PROCESS") {
          return (
            <p
              style={{
                background: "orange",
                textAlign: "center",
                borderRadius: 5,
                color: "white"
              }}
            >
              {record.orderStatus}
            </p>
          );
        } else if (record.orderStatus == "CANCEL") {
          return (
            <p
              style={{
                background: "red",
                textAlign: "center",
                borderRadius: 5,
                color: "white"
              }}
            >
              {record.orderStatus}
            </p>
          );
        } else {
          return record.orderStatus;
        }
      }
    },
    {
      title: "Order Type",
      key: "OrderType",
      width: "10%",
      render: (text, record) => record.orderType
    },
    {
      title: "Drive Out",
      key: "iDriveMyselfPay",
      width: "9%",
      render: (text, record) => {
        if (record.orderType === "Drive Out" || record.iDriveMyself) {
          // return `Kr.${(record.iDriveMyselfPay).toFixed(2)}`;
          return `Kr.${record.tdDriveOutCharge.toFixed(2)}`;
        }

        if (record.orderType === "Take Away") {
          return `0`;
        }

        if (record.orderType === "Drive Out" && record.iDriveMyself == false) {
          return `0`;
        } 
        // else {
        //   return `Kr.${record.items.reduce(
        //     (acc, current) =>
        //       acc +
        //       current.quantity * current.price +
        //       (current.extraPrice ? +current.extraPrice : 0),
        //     0
        //   )}`;
        // }
      }
    },
    // {
    //   title: "Total",
    //   key: "total",
    //   width: "9%",
    //   // render: (text, record) => {
    //   //   if (record.orderType === "Drive Out" ||record.orderType === "Take Away" && record.iDriveMyself ) {
    //   //     return `Kr.${
    //   //      record.items.reduce((prev,current)=>{
    //   //       return prev+(Number(current.price*100/(100+record.tdExtraPriceIncrease)))*current.quantity +Number(current.extraPrice*100/(100+record.tdExtraPriceIncrease))+record.iDriveMyselfPay
    //   //      },0)
    //   //     }`;
    //   //   }
    //   //   if (record.orderType === "Drive Out" ||record.orderType === "Take Away" ) {
    //   //     return `Kr.${
    //   //      record.items.reduce((prev,current)=>{
    //   //       return prev+(Number(current.price*100/(100+record.tdExtraPriceIncrease)))*current.quantity +Number(current.extraPrice*100/(100+record.tdExtraPriceIncrease))
    //   //      },0)
    //   //     }`;
    //   //   }
    //   //   // else {
    //   //   //   return `Kr.${record.items.reduce(
    //   //   //     (acc, current) =>
    //   //   //       acc +
    //   //   //       current.quantity * current.price +
    //   //   //       (current.extraPrice ? +current.extraPrice : 0),
    //   //   //     0
    //   //   //   )}`;
    //   //   // }
    //   // }
    //   render: (text, record) => {
    //     return (Number(record.items.reduce((acc, curr) => {
    //       acc += (curr.price * curr.quantity) + curr.extraPrice
    //       return acc;
    //     },0) + record.tdServiceCharge + record.tdDriveOutCharge - (record.tdDiscountAbs).toFixed(2))).toFixed(2)
    //   }
    // },
    {
      title: "Total",
      key: "total",
      width: "9%",
      // render: (text, record) => {
      //   if (record.orderType === "Drive Out" ||record.orderType === "Take Away" && record.iDriveMyself ) {
      //     return `Kr.${
      //      record.items.reduce((prev,current)=>{
      //       return prev+(Number(current.price*100/(100+record.tdExtraPriceIncrease)))*current.quantity +Number(current.extraPrice*100/(100+record.tdExtraPriceIncrease))+record.iDriveMyselfPay
      //      },0)
      //     }`;
      //   }
      //   if (record.orderType === "Drive Out" ||record.orderType === "Take Away" ) {
      //     return `Kr.${
      //      record.items.reduce((prev,current)=>{
      //       return prev+(Number(current.price*100/(100+record.tdExtraPriceIncrease)))*current.quantity +Number(current.extraPrice*100/(100+record.tdExtraPriceIncrease))
      //      },0)
      //     }`;
      //   }
      //   // else {
      //   //   return `Kr.${record.items.reduce(
      //   //     (acc, current) =>
      //   //       acc +
      //   //       current.quantity * current.price +
      //   //       (current.extraPrice ? +current.extraPrice : 0),
      //   //     0
      //   //   )}`;
      //   // }
      // }
      render: (text, record) => {
        // return `Kr.${record.extraItems[0] == 'Need to more test' ? record.taxPrice : record.totalPrice.toFixed(2)}`;
        return `Kr.${record.totalPrice.toFixed(2)}`;
      }
    },
    {
      title: "View",
      key: "view",
      width: "10%",
      render: (text, record) => (
        <Button className="btn-ab" onClick={() => showOrder(record._id)}>
          <i className="fa fa-eye"></i>
        </Button>
      )
    }
  ];
  const ordersSummaryColumn = [
    {
      title: "Total",
      width: "72%",
      render: () => <strong>Total</strong>
    },
    {
      title: "Total",
      render: () =>{
        // let total= props.onlineOrdersTreeDrive.items.reduce((prevOrder,currentOrder)=>{
        //   return prevOrder+currentOrder.iDriveMyselfPay+currentOrder.items.reduce((prev,current)=>{
        //     return prev+Number(current.price*100/(100+currentOrder.tdExtraPriceIncrease))*current.quantity +Number(current.extraPrice*100/(100+currentOrder.tdExtraPriceIncrease))
        //   },0)
        // },0);
        let total = props.onlineOrdersTreeDrive.items.reduce((acc, curr) => {
          return (acc += curr.totalPrice);
        },0);
        return `Kr.${total.toFixed(2)}`;
      }
    }
  ];
  console.log("online orders treeDrive====>", props.onlineOrdersTreeDrive);
  const summaryData = [
    {
      key: "-1",
      render: () => "test"
    }
  ];
  const changeStatus = (type, id, timeString, orderType,iDriveMyself) => {
    if (fetchApi) {
      setFetchApi(false);
      props
        .changeOrderStatusTreeDrive({
          orderStatus: type,
          id,
          time: timeString,
          orderType: orderType,
          cancelReason,
          treeDriveOrder:true,
          iDriveMyself:iDriveMyself
        })
        .then(() => {
          setFetchApi(true);
          setCancelReasonModel(false);
          setTimemodalvisible(false);
          handleOnlineOrders(moment().date()._d);
          setPage("list");
        })
        .then(()=>{
          if (orderType == "Drive Out" && !iDriveMyself && type!=="CANCEL") {
            console.log("inside the searchDriver in view ====>");
            props.searchDriver(id,timeString);
          }else{
            console.log(
              "inside the change status index js order typeis Take Away=======> ",
              iDriveMyself
            );
            return;
          }
          
        });
    }
  };
  const renderList = () => {
    return (
      <>
        <div className="report-container">
          <span className="item" style={{ width: "60%" }}>
            <span id="less-visible">Rapport / </span>
            EzyGo Orders
          </span>
          <span id="less-visible">
            <Button
              onClick={() => goBackMainPage()}
              className="ant-btn back-btn"
            >
              <i className="fa fa-arrow-circle-left" aria-hidden="true" />
            </Button>
          </span>
        </div>
        <Card>
          <Card.Body>
            <Row>
              <Col className="text-right" lg="10">
                <Select
                  style={{ width: 120 }}
                  onChange={(e) => setOnlineStatus(e)}
                  value={onlineStatus}
                >
                  <Option key="all" value="all">
                    All
                  </Option>
                  <Option key="pending" value="pending">
                    Pending
                  </Option>
                  <Option key="active" value="active">
                    Active
                  </Option>
                  <Option key="delivered" value="delivered">
                    Delivered
                  </Option>
                  <Option key="cancelled" value="cancelled">
                    Cancelled
                  </Option>
                </Select>
              </Col>
              <Col lg="2">
                <DatePicker
                  onChange={(date, dateString) => {
                    handleOnlineOrders(date);
                  }}
                  format="YYYY-MM-DD"
                  placeholder="Select day"
                />
              </Col>
            </Row>
          </Card.Body>
        </Card>
        <Card>
          <Card.Body>
            <Row>
              <Col>
                <TableSumX
                  loading={isLoaded}
                  columns={ordersColumns}
                  summaryColumns={ordersSummaryColumn}
                  data={props.onlineOrdersTreeDrive.items}
                  summaryData={summaryData}
                  rowKey={(record) => record.productName}
                  bordered
                />
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </>
    );
  };
  
  const getPages = () => {
    if (page === "list") {
      return renderList();
    } else if (page === "view") {
      return (
        <View
          orderData={orderData}
          setPage={setPage}
          timeString={timeString}
          setTimeString={setTimeString}
          changeStatus={(type, id, timeString, orderType,iDriveMyself) =>
            changeStatus(type, id, timeString, orderType,iDriveMyself)
          }
          allAvailableDrivers={props.allAvailableDrivers}
          searchDriverAction={props.searchDriver}
          searchDriverReducer={props.searchDriverReducer}
          allAvailableDriversReducer={props.allAvailableDriversReducer}
          setCancelReason={setCancelReason}
          cancelReason={cancelReason}
          setCancelReasonModel={setCancelReasonModel}
          cancelReasonModel={cancelReasonModel}
          setTimemodalvisible={setTimemodalvisible}
          timemodalvisible={timemodalvisible}
        />
      );
    }
  };
  return <Home>{getPages()}</Home>;
};

const mapStateToProps = ({
  onlineOrdersTreeDrive,
  searchDriverReducer,
  allAvailableDriversReducer
}) => {
  return {
    onlineOrdersTreeDrive,
    searchDriverReducer,
    allAvailableDriversReducer
  };
};
export default connect(mapStateToProps, {
  getOnlineOrdersTreeDrive,
  changeOrderStatusTreeDrive,
  searchDriver,
  allAvailableDrivers
})(Index);
