import React, { useState, useEffect } from 'react'
import PaymentGraph from './PaymentGraph'
import IncomeGraph from './IncomeGraph'
import Home from "../../Home";
import MonthIncomeGraph from './MonthIncomeGraph';
import YearIncomeGraph from './YearIncomeGraph';
import { Card, Row, Col, Button } from "react-bootstrap";
import GroupRepport from './GroupRepport';

const index = (props) => {
  const goBackMainPage = () => {
    props.history.push("/arkiv");
  };
  return (
    <Home>
     <div className="report-container">
            <span className="item" style={{ width: "60%" }}>
              <span id="less-visible">Arkiv :  </span>
                Grafisk gjengivelse av Progress Kart
            </span>

            <span id="less-visible">
              <Button
                onClick={() => goBackMainPage()}
                className="ant-btn back-btn"
              >
                <i className="fa fa-arrow-circle-left" aria-hidden="true" />
              </Button>
            </span>
          </div>
          <div >
        <GroupRepport />
      </div>

      <div style={{marginTop: "150px"}}>
        <PaymentGraph />
      </div>

      <div  style={{marginTop: "200px"}}>
        <IncomeGraph />
      </div>

      <div  style={{marginTop: "150px"}}>
        <MonthIncomeGraph />
      </div>

      <div  style={{marginTop: "150px"}}>
        <YearIncomeGraph />
      </div>
    
    </Home>
  )
}

export default index
