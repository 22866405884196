import React from "react";
import Home from "../../Home";
import { NavLink } from "react-router-dom";
import ReportTable from "./ReportTable";
const YearReport = () => {
  
  return (
    <Home>
      <div className="report-container">
        <span className="item " style={{ width: "60%" }}>
          <span>Arkiv : </span> Årlig Rapport
          {/* <NavLink to="/arkiv" className="back-btn">
            <i className="fa fa-arrow-circle-left" aria-hidden="true" />
          </NavLink> */}
        </span>
        <span id="less-visible">
            <NavLink to="/arkiv" className="back-btn">
            <i className="fa fa-arrow-circle-left" aria-hidden="true" />
          </NavLink>
            </span>
      </div>
      <ReportTable page="year"/>
    </Home>
  );
};
export default YearReport;
