import React from "react";
import Home from "../../Home";
import { NavLink } from "react-router-dom";
import ReportTable from "./ReportTable";
const WeekReport = () => {
  return (
    <Home>
      {/* <div className="report-container">
        <span className="item ">
          <span>Arkiv : </span> Ukentlig Rapport
          <NavLink to="/arkiv" className="back-btn">
            <i className="fa fa-arrow-circle-left" aria-hidden="true" />
          </NavLink>
        </span>
      </div> */}
      <div className="report-container">
            <span className="item" style={{ width: "60%" }}>
              <span id="less-visible">Arkiv:</span>
              Ukentlig Rapport
            </span>

            <span id="less-visible">
            <NavLink to="/arkiv" className="back-btn">
            <i className="fa fa-arrow-circle-left" aria-hidden="true" />
          </NavLink>
            </span>
          </div>
      <ReportTable page="week"/>
    </Home>
  );
};
export default WeekReport;
