export const GET_DAILY_REPORT = "GET_DAILY_REPORT";
export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const GET_BRANCH = "GET_BRANCH";
export const GET_DAILY_PRINT_REPORT = "GET_DAILY_PRINT_REPORT";
export const VIEW_REPORT = "VIEW_REPORT";
export const GET_REPORT = "GET_REPORT";
export const GET_SUPER_CATEGORY = "GET_SUPER_CATEGORY";
export const GET_CATEGORY = "GET_CATEGORY";
export const ADD_CATEGORY = "ADD_CATEGORY";
export const SUCCESS_MESSAGE = "SUCCESS_MESSAGE";
export const ERROR_MASSAGE = "ERROR_MASSAGE";
export const EMPTY_SUCCESS_MESSAGE = "EMPTY_SUCCESS_MESSAGE";
export const EMPTY_ERROR_MASSAGE = "EMPTY_ERROR_MASSAGE";
export const GET_ICONS = "GET_ICONS";
export const UPDATE_CATEGORY = "UPDATE_CATEGORY";
export const UPDATE_CATEGORY_ITEM = "UPDATE_CATEGORY_ITEM";
export const GET_PRODUCTS = "GET_PRODUCTS";
export const PRODUCT_STOCKS = "PRODUCT_STOCKS";
export const PRODUCT_INGREDENT = "PRODUCT_INGREDENT";
export const GET_X_REPORT = "GET_X_REPORT";
export const PROFILE_DETAIL = "PROFILE_DETAIL";
export const PROFILE_SWITCH_BRANCH = "PROFILE_SWITCH_BRANCH";
export const GET_FAKTURA = "GET_FAKTURA";
export const GET_ORDER_COUNT = "GET_ORDER_COUNT";
export const CHANGE_AVAILABILITY = "CHANGE_AVAILABILITY";
export const SET_LOGO = "SET_LOGO";
export const GET_GROUP_REPORT = "GET_GROUP_REPORT";
export const GET_EMPLOYEES_TIP_REPORT = "GET_EMPLOYEES_TIP_REPORT";
export const LEG_TIL_FILER = "LEG_TIL_FILER";
export const SET_INFORMATION = "SET_INFORMATION";
export const GET_INFORMATION = "GET_INFORMATION";
export const UPDATE_DAY_AND_TIME = "UPDATE_DAY";
export const UPDATE_WEEK_AVAILABILITY = "UPDATE_WEEK_AVAILABILITY";
export const GET_POS_REPORT = "GET_POS_REPORT";
export const GET_CASH_REPORT = "GET_CASH_REPORT";
export const GET_LAGER_REPORT = "GET_LAGER_REPORT";
export const Employee_Tips = "Employee_Tips";
export const GET_ONLINE_ORDER = "GET_ONLINE_ORDER";
export const GET_COSTOMERS = "GET_COSTOMERS";
export const GET_COSTOMERS_INVOICE = "GET_COSTOMERS_INVOICE";
export const GET_ANSATTE = "GET_ANSATTE";
export const GET_CATEGORIES = "GET_CATEGORIES";
export const GET_CAT_PRODUCT = "GET_CAT_PRODUCT";
export const STOCKS_HISTORY = "STOCKS_HISTORY";
export const GET_UNPAID_INVOICES="GET_UNPAID_INVOICES";
export const GET_ALL_TABLE = "GET_ALL_TABLE";
export const SAVE_TABLE = "SAVE_TABLE";
export const DELETE_TABLE = "DELETE_TABLE";
export const UPDATE_TABLE = "UPDATE_TABLE";
export const BEGIN_GET_ALL_TABLE = "BEGIN_GET_ALL_TABLE";
export const GET_ALL_TABLE_SUCCESS = "GET_ALL_TABLE_SUCESS";
export const UPDATE_STATUS_kITCHEN = "UPDATE_STATUS_kITCHEN ";
export const UPDATE_ORDER_STATUS_kITCHEN = "UPDATE_ORDER_STATUS_kITCHEN ";
export const GET_TABLES_DATA = "GET_TABLES_DATA";
export const SET_ONLINE_TABLE_BOOKING_COUNT = "SET_ONLINE_TABLE_BOOKING_COUNT";
export const GET_DAILY_REPORT_DINING = "GET_DAILY_REPORT_DINING";
export const GET_COUPAN = "GET_COUPAN";
export const SAVE_COUPAN = "SAVE_COUPAN";
export const GET_COUPAN_INVOICES = "GET_COUPAN_INVOICES";
export const SEARCH_DRIVER="SEARCH_DRIVER";
export const GET_ONLINE_ORDER_TREEDRIVE="GET_ONLINE_ORDER_TREEDRIVE";
export const All_AVAILABLE_DRIVERS="All_AVAILABLE_DRIVERS";
export const GET_ORDER_COUNT_TREEDRIVE="GET_ORDER_COUNT_TREEDRIVE";
export const GET_ORDER_COUNT_VENDOR="GET_ORDER_COUNT_VENDOR";
export const  GET_RESREVE_TABLE_COUNT='GET_RESREVE_TABLE_COUNT';
export const GET_TD_DISCOUNT="GET_TD_DISCOUNT"
