import React, { Component } from "react";
import Home from "../../components/Home";
import { Button, Col, Row, Input, notification } from "antd";
import "./TableView.css";
import { push } from "react-router-redux";
import { connect } from "react-redux";
import table from "../../assets/dining.png";
import history from "../../history";
import appConfig from "../../helpers/appConfig";

// const Search = Input.Search;
// const goBackButton = () => {
//   props.setPage("OpprettKontost");
// };
class TableViewComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: false,
      isLoaded: false,
      orderType: "",
      number: 0
    };
  }
  openNotification = (type, message) => {
    notification[type]({
      message: message
    });
  };

  addTable = (e, tableType) => {
    e.preventDefault();
    var hdr = localStorage.token;
    var data = JSON.stringify({
      number: this.state.number,
      orderType: tableType
    });
    fetch(`${appConfig.endpoint}/api/v1/employee/board/savetable`, {
      method: "POST",
      body: data,
      headers: {
        "x-auth-token": hdr,
        "Content-Type": "application/json"
      }
    })
      .then((res) => res.json())
      .then (
        (result) => {
          console.log("result is save table",result);
          localStorage.setItem("taxID",result.data.taxes.taxId)
          // data.taxes.taxId
          if(result.status==422){
            this.openNotification("success", result.message);


          }else{
            if (result.status !== 200) {
              this.openNotification("error", "Something went wrong");
            } else {
              this.openNotification("success", result.message);
              localStorage.setItem("table", result.data._id);
              history.push("/BillingOK");
            }

          }
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      );
  };

  openNotification = (type, message) => {
    notification[type]({
      message: message
    });
  };

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  render() {
    return (
      <Home>
      <Row className="report-container d-flex justify-content-between">
        <span className="item">
          <span id="less-visible">Opprett konto / </span>
          SPISE/ DRIKKE HER
        </span>
        <span className="item">
                <span id="less-visible">
                  <div
                    // onClick={this.goBackButton}
                    className="back-button-border">
                    <i className="fa fa-arrow-circle-left" aria-hidden="true" />
                    {/* <span>Back</span> */}
                  </div>
                </span>
              </span>
      </Row>
        <div className="content1" style={{ marginTop: "10%", width: "90%" }}>
          <Row className="table-flex" style={{ textAlign: "center" }}>
            <div className="item-1">
              <div className="table-wrapper shadow-dash d-flex justify-content-between align-items-center no-hover" 
                  style={{ cursor: 'none' }}>
                <img
                  src={table}
                  className="dining-logo"
                />
                <span
                  style={{
                    // marginLeft: "14px",
                    color: "black",
                    fontWeight: "bold"
                  }}
                >
                  BORD NR.
                </span>
              </div>
              <form onSubmit={(e) => this.addTable(e, "table")} style={{ display:"flex" }} >
                <div>
                  <input
                    onChange={this.handleChange}
                    name="number"
                    type="number"
                    min="1"
                    max="30"
                    style={{
                      // padding: "10px",
                      // marginLeft: "10px",
                      width: 80,
                      // background: "#ffa733",
                      height: 30,
                      // borderRadius: 4,
                      border: "none",
                      boxShadow: "inset 0 0 3px #000000"
                    }}
                    required
                  />
                </div>
                <Button htmlType="submit" id="add-button" style={{height: 30, border:'none', borderRadius:0}}>
                  <b>+</b>
                </Button>
              </form>
            </div>
            <div className="item">
              <Button
                onClick={(e) => this.addTable(e, "No Table")}
                className="table-wrapper shadow-dash d-flex justify-content-between align-items-center"
              >
                <div className="no-container">
                  <img
                    src={table}
                    className="dining-logo no-dining "
                    // style={{ marginLeft: "15px" }}
                  />
                  <div className="text-block" />
                  <div className="text-block1" />
                </div>
                <span style={{ fontWeight: "bold" }}>
                  INGEN BORD
                </span>
              </Button>
            </div>
          </Row>
        </div>
      </Home>
    );
  }
  navigate = (route) => {
    const { dispatch } = this.props;
    dispatch(push(route));
  };
}
const mapStateToProps = (state) => {
  return {};
};
const TableView = connect(mapStateToProps)(TableViewComponent);
export default TableView;
