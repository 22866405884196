import { combineReducers } from "redux";
import { routerReducer } from "react-router-redux";
import dailyReport from "./reports/dailyReportReducer";
import viewReport from "./viewReportReducer";
import superCategory from "./menu/superCategoryReducer";
import categories from "./menu/categoryReducer";
import errorMassage from "./errorMassageReducer";
import successMassage from "./successMassageReducer";
import icons from "./menu/iconReducer";
import products from "./products/productsReducer";
import stockProduct from "./products/stockProductReducer";
import ingredent from "./products/productIngredentReducer";
import dailyXReport from "./reports/dailyXReportReducer";
import profileDetails from "./profile/profileReducer";
import switchBranchReducer from "./profile/switchBranchReducer";
import faktura from "./arkiv/fakturaReducer";
import groupReport from "./arkiv/groupReportReducer";
import dayAndTimeLog from "./information/updateDayAndTimeReducer";
import information from "./information/getInformationReducer";
import cashReport from "./reports/cashReportReducer";
import lagerReport from "./arkiv/lagerReportReducer";
import employeesTip from "./arkiv/tipReportReducer";
import legTilFiler from "./arkiv/legTilFilerReducer";
import empTips from "./profile/employeeTipReducer";
import onlineOrder from "./reports/onlineOrdersReducer";
import kunder from "./kunder/kunderReducer";
import KunderInvoice from "./kunder/kunderInvoiceReducer";
import ansatte from "./ansatte/ansatteReducer";
import stockCategories from "./lager/categoriesReducer";
import catProduct from "./lager/categoryProductReducer";
import stockHistory from "./lager/stockHistoryReducer";
import UnpaidInvoices from "./unpaidInvoices/unpaidInvoices";
import getAllTables from "./bord/getAllTables";
import allTables from "./table/allTableReducer";
import { updateKitchenStatus } from "../action/kitchenAction";
import orderCount from "./orders/orderCountReducer";
import treeDriveOrderCountReducer from "./orders/treeDriveOrderCountReducer";
import vendorCountReducer from "./orders/vendorCountReducer";
import onlineTableBookingReducer from "./orders/onlineOrderCountReducer";
import orderCountReducer from "./orders/orderCountReducer";
import dailyDining from "./reports/dailyReportDiningReducer";
import coupanReducer from "./coupan/coupan";
import searchDriverReducer from "./orders/searchDriverRed";
import onlineOrdersTreeDriveReducer from "./reports/onlineOrdersTreeDrive";
import allAvailableDriversReducer from "./orders/allDriverRed"
import reserveTableCountReducer from './orders/orderCountReducer'
//import modelLoginReducer from "./loginModal/loginModalReducer";
import loginInfoReducer from "./loginInfo/loginInfoReducer";
export default combineReducers({
  routing: routerReducer,
  dailyReport,
  viewReport,
  superCategory,
  categories,
  errorMassage,
  successMassage,
  icons,
  products,
  stockProduct,
  ingredent,
  dailyXReport,
  profileDetails,
  switchBranch: switchBranchReducer,
  faktura,
  groupReport,
  cashReport,
  lagerReport,
  employeesTip,
  legTilFiler,
  dayAndTimeLog,
  information,
  empTips,
  onlineOrder,
  kunder,
  KunderInvoice,
  ansatte,
  stockCategories,
  catProduct,
  stockHistory,
  UnpaidInvoices,
  getAllTables,
  allTables,
  updateKitchenStatus,
  orderCount:orderCountReducer,
  treeDriveOrderCount:treeDriveOrderCountReducer,
  vendorOrderCount:vendorCountReducer,
  onlineTableBookingCount: onlineTableBookingReducer,
  dailyDining,
  coupanReducer: coupanReducer,
  searchDriverReducer:searchDriverReducer,
  onlineOrdersTreeDrive:onlineOrdersTreeDriveReducer,
  allAvailableDriversReducer:allAvailableDriversReducer,
 // modelLoginReducer:modelLoginReducer,
  loginInfoReducer

});
